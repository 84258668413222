<div class="card rounded-3 mt-5">
  <div class="card-body">
    <div class="d-block d-md-flex">

      <h6>RETURN REQUEST FROM TABLE 007</h6>
      <div class="ms-auto">
        <p class="text-muted">1 min ago</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2">
        <h6>ORDER ID</h6>
        <p>0123485762</p>
      </div>
      <div class="col-md-2">
        <h6>WAITER</h6>
        <p>Flora Ikuigbe</p>
      </div>
      <div class="col-md-2">
        <h6>REASON</h6>
        <p>Expired Item</p>
      </div>
      <div class="col-md-6">
        <h6>ITEM</h6>
        <p>Sprite Lemon 50cl bottle</p>
      </div>
    </div>
    <div class="mt-3">
      <button class="btn btn-primary btn-lg me-3" (click)="acceptReturn()">Accept Return</button>
      <button class="btn btn-secondary btn-lg" (click)="rejectReturn()">Reject Return</button>
    </div>
  </div>
</div>
