import { Component, inject } from '@angular/core';
import { AuthService } from '../../_service/auth.service';
import { Router, RouterModule } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, CommonModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  authService = inject(AuthService);
  router = inject(Router);
  isLoading = false;
  protected forgotForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  })
  message: any;
  onSubmit() {
    if (this.forgotForm.valid) {
      this.isLoading = true;
      this.authService.forgotPassword(this.forgotForm.value).subscribe({
        next: (data: any) => {
          this.isLoading = false;
          Swal.fire('Success', data.message, 'success')
          this.router.navigateByUrl('/reset_password')
        },
        error: (error: any) => {
          this.isLoading = false;
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.error.errors || 'Something went wrong!'
          });
          this.message = error.error.errors;
        }
      })
    }
  }
}
