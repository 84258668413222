// sales.component.ts
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportingService } from '../_service/reporting.service';
import { FormsModule } from '@angular/forms';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration, ChartData, ChartType, registerables } from 'chart.js';

interface SalesSummary {
  total_payments: any;
  pending_payments: any;
  completed_payments: any;
}


export interface PaymentReportHistory {
  order_id: string;
  order_uid: string | null;
  order_amount: any;
  payment_method: any;
  status: string;
  transaction_date: string | null;
}

export interface PaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  links: Array<{
    url: string | null;
    label: string;
    active: boolean;
  }>;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: PaginationMeta;
}
export interface SalesHistory {
  order_id: number;
  order_uid: string;
  order_amount: string;
  payment_method: string[];
  status: string;
  transaction_date: string;
}

export interface SalesHistoryParams {
  page?: number;
  start_date?: string;
  end_date?: string;
  payment_method?: string;
  waiter_id?: string;
}
@Component({
  selector: 'app-financial-report',
  standalone: true,
  imports: [NgbModule, RouterModule, CommonModule, FormsModule, BaseChartDirective],
  templateUrl: './financial-report.component.html',
  styleUrl: './financial-report.component.scss'
})
export class FinancialReportComponent {
  private reportingSrvs = inject(ReportingService);
  private modal = inject(NgbModal);
  active = 1;
  paymentHistory: PaymentReportHistory[] = [];
  page = 1;
  pageSize = 15;
  size = 0;
  salesHistory: SalesHistory[] = [];
  filteredsalesHistory: SalesHistory[] = [];
  historyPage = 1;
  historyPageSize = 15;
  historyTotal = 0;
  searchTerm: string = '';
  dateRange: { from: NgbDate | null; to: NgbDate | null } = {
    from: null,
    to: null
  };
  hoveredDate: NgbDate | null = null;
  selectedPaymentMethod: string = '';
  selectedWaiter: string = '';
  showDatePicker = false;
  // Properties
  totalSales: SalesSummary = {
    total_payments: 0,
    pending_payments: 0,
    completed_payments: 0
  };

  summaryCards = [
    { title: 'Total Payments', value: 0 },
    { title: 'Pending Payments', value: 0 },
    { title: 'Completed Payments', value: 0 }
  ];




  ngOnInit(): void {
    this.getPaymentHistory();
    this.getPaymentSummary();
  }

  getPaymentHistory(page: number = 1) {
    this.reportingSrvs.getPaymentHistory(page).subscribe({
      next: (response) => {
        this.paymentHistory = response.data;
        this.size = response.meta.total;
        this.page = response.meta.current_page;
      },
      error: (error) => {
        console.error('Error fetching waiter performance:', error);
      }
    });
  }

  refresh(page: number) {
    this.getPaymentHistory(page);
  }
  getPaymentSummary() {
    this.reportingSrvs.getPaymentSummary().subscribe({
      next: (data: any) => {
        this.summaryCards = [
          { title: 'Total Payments', value: data.total_payments },
          { title: 'Pending Payments', value: parseInt(data.pending_payments) },
          { title: 'Completed Payments', value: data.completed_payments }
        ];
      },
      error: (error) => {
        console.error('Error fetching sales summary:', error);
      }
    });
  }

  updateSummaryCards() {
    this.summaryCards = [
      { title: 'Total Sales', value: this.totalSales.total_payments },
      { title: 'Total Deductions', value: this.totalSales.pending_payments },
      { title: 'Completed Payments', value: this.totalSales.completed_payments }
    ];
  }



  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.filteredsalesHistory);
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Sales History': worksheet },
      SheetNames: ['Sales History']
    };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(data, `sales_history_${new Date().toISOString().split('T')[0]}.xlsx`);
  }

}
