import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { InventoryService } from '../../_service/inventory.service';

interface InventoryLog {
  id: number;
  receipt: string;
  inventory: {
    id: number;
    name: string;
    logs: any;
    selling_price: any;
    main_image: string | null;
    category?: {
      name: string;
    };
  };
  quantity: number;
  expiry_date: string;
  cost_price: number;
  selling_price: number;
  total_cost: number;
  is_expired: boolean;
  sold_out: boolean;
}

@Component({
  selector: 'app-inventory-auditory-logs',
  standalone: true,
  imports: [RouterModule, CommonModule, NgbModule, FormsModule],
  templateUrl: './inventory-auditory-logs.component.html',
  styleUrl: './inventory-auditory-logs.component.scss'
})
export class InventoryAuditoryLogsComponent implements OnInit {
  private invetorySrvs = inject(InventoryService);
  private modal = inject(NgbModal);
  private route = inject(ActivatedRoute);

  logs: InventoryLog[] = [];
  page = 1;
  size = 0;
  pageSize = 15;
  updatingQuantity = 0;
  selectedLog: InventoryLog | null = null;

  ngOnInit(): void {
    this.listInventoryLogs();
  }

  listInventoryLogs() {
    const inventoryId = this.route.snapshot.paramMap.get('id');
    this.invetorySrvs.listInventoriesLogs(this.page, inventoryId).subscribe({
      next: (res: any) => {
        this.logs = res.data;
        this.size = res.meta.total;
        this.pageSize = res.meta.per_page;
      },
      error: (error) => {
        console.error('Error fetching logs:', error);
      }
    });
  }

  refresh(page: number) {
    this.page = page;
    this.listInventoryLogs();
  }

  toggleStatus(log: InventoryLog) {
    const newStatus = !log.sold_out;
    const statusText = newStatus ? 'sold out' : 'available';

    Swal.fire({
      title: 'Confirm Status Change',
      text: `Are you sure you want to mark this inventory as ${statusText}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.invetorySrvs.toggleInventoryStatus(log.id, { sold_out: newStatus }).subscribe({
          next: (res: any) => {
            Swal.fire('Success', `Inventory marked as ${statusText}`, 'success');
            this.listInventoryLogs();
          },
          error: (err) => {
            Swal.fire('Error', err.error.message || 'Failed to update status', 'error');
          }
        });
      }
    });
  } view(receipt: any) {
    window.open(receipt, '_blank')

  }

  openUpdateModal(content: any, log: InventoryLog) {
    this.selectedLog = log;
    this.updatingQuantity = log.quantity;
    this.modal.open(content, { centered: true }).result.then(
      (result) => {
        if (result === 'save') {
          this.updateQuantity();
        }
      },
      (reason) => {
        // Modal dismissed
      }
    );
  }

  updateQuantity() {
    if (!this.selectedLog) return;

    this.invetorySrvs.updateInventoryQuantity(this.selectedLog.id, this.updatingQuantity).subscribe({
      next: (res: any) => {
        Swal.fire('Success', 'Quantity updated successfully', 'success');
        this.listInventoryLogs();
      },
      error: (err) => {
        Swal.fire('Error', err.error.message || 'Failed to update quantity', 'error');
      }
    });
  }
}
