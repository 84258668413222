import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { EncryptionService } from '../_service/encryption.service';
import { BarcodeService } from '../_service/barcode.service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-price-tags',
  standalone: true,
  imports: [FormsModule, CommonModule, NgbModule, ReactiveFormsModule],
  templateUrl: './price-tags.component.html',
  styleUrl: './price-tags.component.scss'
})
export class PriceTagsComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  modal = inject(NgbModal)
  service = inject(BarcodeService)
  encryptionService = inject(EncryptionService)

  generateForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', Validators.required)
  })

  priceTagList: any;
  encryptedTable: string = '';
  qrdata: string = '';
  tempTable: any;
  isLoading: boolean = false;
  isEditing: boolean = false;

  ngOnInit(): void {
    this.listPriceTags()
  }

  listPriceTags() {
    this.service.getPriceTags().subscribe((res: any) => {
      console.log(res)
      this.priceTagList = res.data
    })
  }

  generateBarcode(content: any) {
    this.isEditing = false;
    this.generateForm.reset();
    this.modal.open(content, { centered: true })
  }

  editTag(item: any, content: any) {
    this.isEditing = true;
    this.generateForm.patchValue({
      id: item.id,
      name: item.name
    });
    this.modal.open(content, { centered: true });
  }

  onsubmit() {
    this.isLoading = true;

    const request = this.isEditing ?
      this.service.updatePriceTag(this.generateForm.value.id, this.generateForm.value) :
      this.service.storePriceTags(this.generateForm.value);

    request.subscribe({
      next: (res: any) => {
        this.toastr.success(
          `Price Tag ${this.isEditing ? 'Updated' : 'Created'}`,
          'Success!',
          { timeOut: 2000 }
        );
      },
      error: (err) => {
        console.log(err)
        Swal.fire('Failed!!', err.error.message, 'error')
      },
      complete: () => {
        this.isLoading = false;
        this.listPriceTags()
        this.modal.dismissAll()
      }
    })
  }

  delete(id: any) {
    this.service.deleteBarcode(id).subscribe({
      next: (res) => {
        Swal.fire('Success', 'Barcode deleted', 'success')
      },
      error: (err) => {
        Swal.fire('Failed', err.error.message, 'error')
      },
      complete: () => {
        this.listPriceTags()
      }
    })
  }
}
