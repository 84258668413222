import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  http = inject(HttpClient);
  auth = inject(AuthService)
  baseUrl = environment.baseApi
  private authLocalStorageToken = `user`;
  user = this.auth.getUser(this.authLocalStorageToken)
  loadingBar = inject(LoadingBarService)

  constructor() { }

  listCategories() {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/inventories/categories/admin`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  createCategory(data: any) {
    this.loadingBar.start();
    return this.http.post(`${this.baseUrl}/inventories/store-category`, data)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  updateCategory(data: any, id: any) {
    this.loadingBar.start();
    return this.http.patch(`${this.baseUrl}/inventories/update-category/${id}`, data)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  disableCategory(id: any) {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/inventories/disable-category/${id}`).pipe(
      finalize(() => { this.loadingBar.stop(); })
    )
  }



  listInventories(page?: any, search?: any, category?: any, status?: any) {
    let queryParams: string[] = [];
    this.loadingBar.start();
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (category) {
      queryParams.push(`category=${category}`);
    }
    if (search) {
      queryParams.push(`search=${search}`);
    }

    if (status) {
      queryParams.push(`status=${status}`);
    }
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
    return this.http.get(`${this.baseUrl}/inventories/admin${queryString}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  listInventoriesLogs(page: any, id: any) {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/inventories/inventory-logs/${id}?page=${page}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  toggleInventoryStatus(id: any, payload: any) {
    this.loadingBar.start();
    return this.http.patch(`${this.baseUrl}/inventories/inventory-logs/${id}/sold-out`, payload)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  updateInventoryQuantity(id: any, payload: any) {
    let pay = {
      quantity: payload
    }
    this.loadingBar.start();
    return this.http.patch(`${this.baseUrl}/inventories/inventory-logs/${id}/quantity`, pay)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  listInventoryByID(id: any) {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/inventories/show/${id}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  // inventories/show

  createInventory(data: any) {
    this.loadingBar.start()
    return this.http.post(`${this.baseUrl}/inventories/store`, data)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  updateInventory(data: any, id: any) {
    this.loadingBar.start()
    return this.http.patch(`${this.baseUrl}/inventories/update/${id}`, data)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  // inventories/update

  enable_disableInv(id: any) {
    this.loadingBar.start()
    return this.http.get(`${this.baseUrl}/inventories/disable/${id}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  inventoryActivityLogs() {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/inventory-activity-logs`).pipe(
      finalize(() => { this.loadingBar.stop(); })
    )
  }
  activityLogs(page: number = 1) {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/activity-logs?page=${page}`).pipe(
      finalize(() => { this.loadingBar.stop(); })
    )
  }

  restock(data: any) {
    this.loadingBar.start();
    return this.http.post(`${this.baseUrl}/inventories/restock-item`, data)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
}

