<div class="row align-items-center mt-5">
  <div class="col-md-4">
    <div class="search-box">
      <input type="text" placeholder="Search" />
      <button>
        <i class="fa fa-light fa-magnifying-glass"></i>
      </button>
    </div>
  </div>
  <div class="col-md-6">
    <div ngbDropdown class="d-inline-block ms-auto me-3" container="body">
      <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
        Filter by Date &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2">Today</button>
        <button ngbDropdownItem class="p-2">This week</button>
        <button ngbDropdownItem class="p-2">This Month</button>
        <button ngbDropdownItem class="p-2">Custom</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block ms-auto btn-lg" container="body">
      <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
        Filter by Status &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2">Accepted</button>
        <button ngbDropdownItem class="p-2">Rejected</button>
      </div>
    </div>
  </div>
</div>


<div class="card mt-5">
  <div class="table-responsive">
    <table class="table align-middle">
      <thead>
        <tr>
          <th class="ps-3 py-3">Order ID</th>
          <th class="ps-3">Waiter</th>
          <th class="ps-3">Reason</th>
          <th class="ps-3">Affected Item</th>
          <th class="ps-3">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="ps-3"> 709357</td>
          <td>Akan Chambers</td>
          <td>Expired Item</td>
          <td>Coca-cola 50cl bottle</td>
          <td>
            <span class="badge text-bg-success rounded-pill px-3 py-2">Accepted</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
