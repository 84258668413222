// operational-report.component.ts
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, inject, OnInit } from '@angular/core';
import { ReportingService } from '../_service/reporting.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

interface KitchenPerformance {
  order_id: string;
  order_items: string;
  preparation_time: string;
}

interface OrderDelays {
  order_id: string;
  order_items: string;
  delay_time: string;
  reason: string;
}
interface WaiterPerformance {
  waiter_name: string;
  waiter_image: string | null;
  total_orders_handled: number;
  total_sales_amount: string | number;
  average_order_value: string | null;
  average_customer_rating: number | null;
}

interface PaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

interface PaginatedResponse<T> {
  data: T[];
  meta: PaginationMeta;
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
}

interface DelaysSummary {
  total_orders: number;
  average_delay_time: number;
  delayed_orders: number;
}
@Component({
  selector: 'app-operational-report',
  standalone: true,
  imports: [NgbModule, CommonModule, FormsModule],
  templateUrl: './waiter-performance.component.html',
  styleUrls: ['./waiter-performance.component.scss']
})
export class OperationalReportComponent implements OnInit {
  private service = inject(ReportingService);

  active = 'waitstaff';
  searchTerm = '';

  // Summary metrics
  summaryMetrics = {
    waitstaff: {
      total_orders: 0,
      average_processing_time: '0 Minutes',
      on_time_orders: 0
    },
    kitchen: {
      total_orders: 0,
      average_preparation_time: '0 Minutes',
      on_time_orders: 0
    },
    delays: {
      total_orders: 0,
      delayed_orders: 0,
      average_delay_time: '0 Minutes'
    }
  };
  page = 1;
  pageSize = 15;
  total = 0;
  // Data lists
  waiterPerformance: WaiterPerformance[] = [];
  kitchenPerformance: KitchenPerformance[] = [];
  orderDelays: OrderDelays[] = [];

  filteredData: any[] = [];

  ngOnInit(): void {
    this.loadWaitstaffData();
    this.getWaiterPerformanceSummary();
    this.getOrderDelaySummary();
  }
  getOrderDelaySummary() {
    this.service.getOrderDelaySummary().subscribe({
      next: (response: any) => {
        this.summaryMetrics.delays = response;
      },
      error: (err) => console.error('Error loading delay summary:', err)
    });
  }
  loadWaitstaffData(page: number = 1) {
    this.service.getWaiterPerformance(page).subscribe({
      next: (response: PaginatedResponse<WaiterPerformance>) => {
        this.waiterPerformance = response.data;
        this.filteredData = response.data;
        this.page = response.meta.current_page;
        this.total = response.meta.total;
        this.pageSize = response.meta.per_page;
      },
      error: (err) => console.error('Error loading waitstaff data:', err)
    });
  }
  refresh(page: number) {
    this.loadWaitstaffData(page);
  }
  loadKitchenData() {
    this.service.fulfilmentSummary().subscribe({
      next: (res: any) => {
        this.kitchenPerformance = res;
        this.filteredData = [...this.kitchenPerformance];
        this.updateKitchenSummary();
      },
      error: (err) => console.error('Error loading kitchen data:', err)
    });
  }

  getWaiterPerformanceSummary() {
    this.service.waiterPerformanceSummary().subscribe({
      next: (res: any) => {
        this.summaryMetrics.waitstaff = res;
        // this.filteredData = [...this.summaryMetrics];
        // this.updateKitchenSummary();
      },
      error: (err) => console.error('Error loading kitchen data:', err)
    });
  }

  loadDelaysData() {
    // Assuming you have an endpoint for delays
    // this.service.getOrderDelays().subscribe({
    //   next: (res: any) => {
    //     this.orderDelays = res;
    //     this.filteredData = [...this.orderDelays];
    //     this.updateDelaysSummary();
    //   },
    //   error: (err) => console.error('Error loading delays data:', err)
    // });
  }

  onTabChange(tab: string) {
    this.active = tab;
    this.searchTerm = '';

    switch(tab) {
      case 'waitstaff':
        this.loadWaitstaffData();
        this.getWaiterPerformanceSummary();
        break;
      case 'kitchen':
        this.loadKitchenData();
        break;
      case 'delays':
        this.loadDelaysData();
        this.getOrderDelaySummary();
        break;
    }
  }


  onSearch() {
    if (!this.searchTerm?.trim()) {
      this.resetFilteredData();
      return;
    }

    const searchTerm = this.searchTerm.toLowerCase();

    switch(this.active) {
      case 'waitstaff':
        this.filteredData = this.waiterPerformance.filter(item =>
          item.waiter_name.toLowerCase().includes(searchTerm)
        );
        break;
      case 'kitchen':
        this.filteredData = this.kitchenPerformance.filter(item =>
          item.order_items.toLowerCase().includes(searchTerm) ||
          item.order_id.toLowerCase().includes(searchTerm)
        );
        break;
      case 'delays':
        this.filteredData = this.orderDelays.filter(item =>
          item.order_items.toLowerCase().includes(searchTerm) ||
          item.order_id.toLowerCase().includes(searchTerm)
        );
        break;
    }
  }

  resetFilteredData() {
    switch(this.active) {
      case 'waitstaff':
        this.filteredData = [...this.waiterPerformance];
        break;
      case 'kitchen':
        this.filteredData = [...this.kitchenPerformance];
        break;
      case 'delays':
        this.filteredData = [...this.orderDelays];
        break;
    }
  }

  // private updateWaitstaffSummary() {
  //   this.summaryMetrics.waitstaff = {
  //     total_orders: this.waiterPerformance.reduce((sum, item) => sum + item.total_orders_handled, 0),
  //     average_processing_time: '3 Minutes', // Calculate based on your data
  //     on_time_orders: 1400 // Calculate based on your data
  //   };
  // }

  private updateKitchenSummary() {
    // Update kitchen metrics based on your data
  }

  private updateDelaysSummary() {
    // Update delays metrics based on your data
  }
}
