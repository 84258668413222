<div class="row align-items-center mt-4">
  <div class="col-md-4 mb-3">
    <div class="input-group mt-3">
      <div class="search-box">
        <input type="text" class="search-input" placeholder="Search" [(ngModel)]="search" (input)="onSearch()" />
      </div>
    </div>
  </div>
  <div class="col-md-2 col-6">
    <div ngbDropdown class="d-inline-block">
      <button type="button" class="btn btn-secondary btn-lg drop-btn" id="dropdownBasic1" ngbDropdownToggle>
        {{ capitalizeFirstLetter(selectedRole) || 'Filter by role' }} &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2" (click)="Filter('admin')">Admin</button>
        <button ngbDropdownItem class="p-2" (click)="Filter('waiter')">Waiter</button>
      </div>
    </div>
  </div>
  <div class="col-md-2 col-6">
    <div ngbDropdown class="d-inline-block">
      <button type="button" class="btn btn-secondary btn-lg drop-btn" id="dropdownBasic1" ngbDropdownToggle>
        {{ capitalizeFirstLetter(selectedStatus) || 'Filter by status' }} &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2" (click)="statuusFilter('active')">Active</button>
        <button ngbDropdownItem class="p-2" (click)="statuusFilter('inactive')">Suspended</button>
      </div>
    </div>
  </div>

  <div class="col-md-4 mt-md-0 mt-3 text-end">
    <button class="btn btn-primary btn-lg spec-btn" (click)="createuser()">Create New User</button>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table align-middle border rounded">
        <thead>
          <tr>
            <th scope="col" class="py-3 ps-3"></th>
            <th scope="col" class="py-3">First Name</th>
            <th scope="col" class="py-3">Last Name</th>
            <th scope="col" class="py-3">Email</th>
            <th scope="col" class="py-3">Phone Number</th>
            <th scope="col" class="py-3">Role</th>
            <th scope="col" class="py-3">Status</th>
            <th scope="col" class="py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          @for(user of users; track user.id){
          <tr>
            <td scope="row" class="ps-3" style="width: 60px;">
              <img [src]=" user?.image || 'profile.png'" width="40" height="40" class="rounded rounded-circle" alt="">
            </td>
            <td> {{ user?.first_name }} </td>
            <td> {{ user?.last_name }} </td>
            <td> {{ user?.email }} </td>
            <td> {{ user?.phone }} </td>
            <td> @for(role of user.roles; track role; let last = $last){
              <span>{{ role }}@if(user.roles.length >1 && !last){,} </span>
              }
            </td>
            <td>
              @if (user?.disabled == false) {
              <span class="badge text-success text-bg-success py-2 px-3 rounded rounded-pill">Active</span>
              }
              @else {
              <span class="badge text-danger text-bg-danger py-2 px-3 rounded rounded-pill">Suspended</span>


              }

            </td>
            <td>
              <div ngbDropdown container="body" display="dynamic" class="d-inline-block">
                <div type="button" class="" id="dropdownBasic1" ngbDropdownToggle>
                  <img class="img-fluid" src="icons/action.svg" width="15" alt="">
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem class="p-3 btn-text" (click)="edit(user?.id)">
                    <img class="img-fluid" src="icons/edit.svg" width="15" alt="">
                    Edit</button>
                  <button ngbDropdownItem class="p-3 btn-text" (click)="updateStatus(user?.id)">
                    @if (user?.disabled == false){
                      <img class="img-fluid" src="icons/suspend.svg" width="15" alt=""> Suspend
                    }
                    @else {
                    <i class="fa fa-regular fa-check me-3"></i> Activate
                    }


                  </button>
                </div>
              </div>
            </td>
          </tr>
          }
          @empty {
          <tr class="text-center">
            <td colspan="8">
              <div class="my-5 text-center">
                <img src="cook.png" alt="">
                <p class="mt-3">There are no users at the moment</p>
              </div>

            </td>
          </tr>
          }



        </tbody>
      </table>
      @if (size > pageSize){
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="size" [(page)]="page" [pageSize]="pageSize"
        [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="refresh($event)" />
      }

    </div>

  </div>
</div>
