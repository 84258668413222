<div class="row mt-5">
  <div class="col-12">
    <h5>Items</h5>
  </div>
  <div class="col-md-5">
    @for (item of disputeDetails?.order.items; track $index) {
    <div class="card my-3 p-3">
      <div class="d-flex">
        <img [src]="item?.inventory?.main_image || 'amala.png'" class="img-fluid rounded-2" width="70" height="70"
          alt="">
        <div class="d-block ms-3">
          <h6 class="text-start"> {{ item?.inventory?.name }} </h6>
          <p class="text-start"> {{ item?.quantity }}
            @if (item.quantity > 1) {units}@else {Unit}
          </p>
        </div>
      </div>
    </div>
    }
    @empty {
    <div class="text-center">
      No data to display
    </div>
    }

    <div class="mt-4">
      <h6>Order Amount</h6>
      <h4> {{ disputeDetails?.order?.amount | currency: "₦" }} </h4>
    </div>
    @if (disputeDetails?.status == 'pending') {
    <div class="mt-3">
      <button class="btn btn-primary btn-lg" (click)="markAsResolved(disputeDetails.id)">Mark as Resolved</button>
    </div>
    }

  </div>
  <div class="col-md-6">
    <div class="my-3">
      <h5>ORDER ID</h5>
      <p> {{ disputeDetails?.order?.uid }} </p>
    </div>
    <div class="my-3">
      <h5>DISPUTE</h5>
      <p> {{ disputeDetails?.reason }} </p>
    </div>
    <div class="my-3">
      <h5>WAITER IN CHARGE</h5>
      <p> {{ disputeDetails?.order?.waiter?.name }} </p>
    </div>
    <div class="my-3">
      <h5>CUSTOMER DETAILS</h5>
      <p class="mb-0"> {{ disputeDetails?.order?.user?.name }} </p>
      <p class="mb-0"> {{ disputeDetails?.order?.user?.phone }} </p>
      <p class="mb-0"> {{ disputeDetails?.order?.user?.email }} </p>
    </div>
  </div>
</div>
