<div class="row mt-5">
  <div class="col-md-6">
    <h5> {{ notification?.title }} </h5>
    <p> {{ notification?.title }}

    </p>

    <!-- <div class="mt-5">
      <button class="btn btn-primary btn-lg">CTA</button>
    </div> -->
  </div>
</div>
