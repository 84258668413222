<p><a [routerLink]="['/admin/user']">Users </a> /
  <span>
    @if(user_id == null){Create new user}@else(){edit user}
  </span>
</p>
<div class="container-fluid">
  <div class="d-flex justify-content-center">
    @if ( successMessage) {
    <ngb-alert #selfClosingAlert [type]="type" (closed)="successMessage = ''"> {{ message }} </ngb-alert>
    }

    <!-- <p>{{ image_url }}</p> -->
  </div>
  <div class="row my-3">
    <div class="col-md-6">
      <div class="form-area">
        <form [formGroup]="createForm" (ngSubmit)="OnSubmit()">
          <div class="form-group">
            <label for="first_name" class="form-label">First Name</label>
            <input type="text" name="first_name" id="first_name" formControlName="first_name" class="form-control"
              placeholder="First Name">
          </div>
          <div class="form-group">
            <label for="last_name" class="form-label">Last Name</label>
            <input type="text" name="last_name" id="last_name" formControlName="last_name" class="form-control"
              placeholder="Last Name">
          </div>
          <div class="form-group">
            <label for="email" class="form-label">Email </label>
            <input type="email" name="email" id="email" formControlName="email" class="form-control"
              placeholder="Email ">
          </div>
          <div class="form-group">
            <label for="phone" class="form-label">Phone Number </label>
            <input type="tel" name="phone" id="phone" formControlName="phone" class="form-control"
              placeholder="Phone Number">
          </div>
          <div class="form-group">
            <label for="role" class="form-label">Role </label>
            <select class="form-select form-control" id="role" name="role" formControlName="role">
              <option value="">--Select role--</option>
              @for(role of roles; track role.id){
              <option [value]="role.name">{{role.name}}</option>
              }

            </select>
          </div>
          <div class="form-group">
            <label for="password" class="form-label">Password </label>
            <input type="password" name="password" id="password" formControlName="password" class="form-control"
              placeholder="Password ">
          </div>
          <div class="card d-block d-md-none">
            <div class="card-body py-4">
              @if(uploadProgress()){
              <ngb-progressbar class="mb-3" [value]="uploadProgress()" type="primary" />

              }

              <div class="d-flex ">
                <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
                  (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
                  @if(imagePreview() ){
                  <img [src]="imagePreview()" alt="Image Preview" width="100" class="image-preview" />
                  }@else{
                  <img src="icons/demo-image.svg" width="100" alt="">
                  }
                  <input type="file" accept=".jpg, .jpeg, .png" (change)="onFileChange($event)" hidden #fileInput />
                </div>
                @if(imageName()){
                <div class="d-block ms-3">
                  <p class="fw-bold image-upload-title">Upload Photo</p>
                  <p class="image-upload-label">
                    <span class="image-name">{{ imageName() }} - ({{fileSize()}} KB)</span>
                  </p>
                  <div class="d-inline-block">
                    <button class="btn btn-outline-secondary d-inline-block btn-lg mr-3 spec-btn" (click)="fileInput.click()">Select Photo</button>
                    @if(selectedFile || imagePreview!== null ){
                    <button class="btn btn-outline-danger d-inline-block btn-lg spec-btn" (click)="removeImage()">Remove Photo</button>
                    }
                  </div>

                </div>
                }
                @else{
                <div class="d-block ms-3">
                  <p class="fw-bold image-upload-title">Upload Photo</p>
                  <p class="image-upload-label">
                    Drag and drop photo or click the “select photo” to choose photo to upload
                  </p>
                  <div class="d-inline-block">
                    <button class="btn btn-outline-secondary btn-lg d-inline-block mr-3 spec-btn" (click)="fileInput.click()">Select Photo</button>
                    @if(selectedFile || imagePreview!== null ){
                    <button class="btn btn-outline-danger btn-lg d-inline-block spec-btn" (click)="removeImage()">Remove Photo</button>
                    }
                  </div>

                </div>
                }

              </div>

              <!-- <div class=" mt-5 file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
                (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
                <input type="file" accept="image/*" (change)="onFileChange($event)" hidden #fileInput />
                <div class="file-dropper" (click)="fileInput.click()">
                  <mat-icon>upload</mat-icon>
                  <p>{{ "ACTIONS.UPLOAD_PLACEHOLDER" }}</p>
                  @if(imageName()){
                  <div>
                    @if(selectedFile || imagePreview!== null ){
                    <div>
                      <img [src]="imagePreview()" alt="Image Preview" class="image-preview" />
                      <p>
                        <span class="image-name">{{ imageName() }}</span> ({{
                        fileSize()
                        }} KB)
                      </p>
                      <mat-icon class="delete-icon" (click)="removeImage()">delete</mat-icon>
                    </div>
                    }

                  </div>
                  }

                </div>
              </div> -->
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-lg btn-primary spec-btn" type="submit" [disabled]="createForm.invalid">
              @if (user_id !== null) {<span>Update User</span>}
              @else {<span>Save New User</span>}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-6 d-none d-md-block">
      <div class="form-area">
      <label for="" class="form-label">Profile Image</label>
      </div>
      <div class="card">
        <div class="card-body py-4">
          @if(uploadProgress()){
          <ngb-progressbar class="mb-3" [value]="uploadProgress()" type="primary" />

          }

          <div class="d-flex ">
            <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
              (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
              @if(imagePreview() ){
              <img [src]="imagePreview()" alt="Image Preview" width="100" class="image-preview" />
              }@else{
              <img src="icons/demo-image.svg" width="100" alt="">
              }
              <input type="file" accept=".jpg, .jpeg, .png" (change)="onFileChange($event)" hidden #fileInput />
            </div>
            @if(imageName()){
            <div class="d-block ms-3">
              <p class="fw-bold image-upload-title">Upload Photo</p>
              <p class="image-upload-label">
                <span class="image-name">{{ imageName() }} - ({{fileSize()}} KB)</span>
              </p>
              <div class="d-inline-block">
                <button class="btn btn-outline-secondary d-inline-block btn-lg mr-3 spec-btn" (click)="fileInput.click()">Select Photo</button>
                @if(selectedFile || imagePreview!== null ){
                <button class="btn btn-outline-danger d-inline-block btn-lg spec-btn" (click)="removeImage()">Remove Photo</button>
                }
              </div>

            </div>
            }
            @else{
            <div class="d-block ms-3">
              <p class="fw-bold image-upload-title">Upload Photo</p>
              <p class="image-upload-label">
                Drag and drop photo or click the “select photo” to choose photo to upload
              </p>
              <div class="d-inline-block">
                <button class="btn btn-outline-secondary btn-lg d-inline-block mr-3 spec-btn" (click)="fileInput.click()">Select Photo</button>
                @if(selectedFile || imagePreview!== null ){
                <button class="btn btn-outline-danger btn-lg d-inline-block spec-btn" (click)="removeImage()">Remove Photo</button>
                }
              </div>

            </div>
            }

          </div>

          <!-- <div class=" mt-5 file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
            (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
            <input type="file" accept="image/*" (change)="onFileChange($event)" hidden #fileInput />
            <div class="file-dropper" (click)="fileInput.click()">
              <mat-icon>upload</mat-icon>
              <p>{{ "ACTIONS.UPLOAD_PLACEHOLDER" }}</p>
              @if(imageName()){
              <div>
                @if(selectedFile || imagePreview!== null ){
                <div>
                  <img [src]="imagePreview()" alt="Image Preview" class="image-preview" />
                  <p>
                    <span class="image-name">{{ imageName() }}</span> ({{
                    fileSize()
                    }} KB)
                  </p>
                  <mat-icon class="delete-icon" (click)="removeImage()">delete</mat-icon>
                </div>
                }

              </div>
              }

            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
