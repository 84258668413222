// sales.component.ts
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportingService } from '../_service/reporting.service';
import { FormsModule } from '@angular/forms';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration, ChartData, ChartType, registerables } from 'chart.js';

interface SalesSummary {
  total_payments: any;
  pending_payments: any;
  completed_payments: any;
}


export interface PaymentReportHistory {
  order_id: string;
  order_uid: string | null;
  order_amount: any;
  payment_method: any;
  status: string;
  transaction_date: string | null;
}

export interface PaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  links: Array<{
    url: string | null;
    label: string;
    active: boolean;
  }>;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: PaginationMeta;
}
export interface SalesHistory {
  order_id: number;
  order_uid: string;
  order_amount: string;
  payment_method: string[];
  status: string;
  transaction_date: string;
}

export interface SalesHistoryParams {
  page?: number;
  start_date?: string;
  end_date?: string;
  payment_method?: string;
  waiter_id?: string;
}

// Add new interface for waiter payment report
interface WaiterPaymentReport {
  waiter_name: string;
  cash: string | number;
  pos: string | number;
  transfer: string | number;
  total_amount: number;
}

@Component({
  selector: 'app-payment-report',
  standalone: true,
  imports: [NgbModule, RouterModule, CommonModule, FormsModule, BaseChartDirective],
  templateUrl: './payment-report.component.html',
  styleUrl: './payment-report.component.scss'
})
export class PaymentReportComponent {
  private reportingSrvs = inject(ReportingService);
  private modal = inject(NgbModal);
  active = 1;
  paymentHistory: PaymentReportHistory[] = [];
  page = 1;
  pageSize = 15;
  size = 0;
  salesHistory: SalesHistory[] = [];
  filteredsalesHistory: SalesHistory[] = [];
  historyPage = 1;
  historyPageSize = 15;
  historyTotal = 0;
  searchTerm: string = '';
  dateRange: { from: NgbDate | null; to: NgbDate | null } = {
    from: null,
    to: null
  };
  hoveredDate: NgbDate | null = null;
  selectedPaymentMethod: string = '';
  selectedWaiter: string = '';
  showDatePicker = false;
  // Properties
  totalSales: SalesSummary = {
    total_payments: 0,
    pending_payments: 0,
    completed_payments: 0
  };

  summaryCards = [
    { title: 'Total Cash', value: 0 },
    { title: 'Total Transfer', value: 0 },
    { title: 'Total POS', value: 0 }
  ];

  waiterPayments: WaiterPaymentReport[] = [];

  selectedDateFilter: 'all' | 'today' | 'yesterday' | 'custom' = 'today';
  customDateRange: { startDate: string; endDate: string } | null = null;

  ngOnInit(): void {
    this.loadReportData();
  }

  loadReportData() {
    let params: { start_date?: string; end_date?: string } = {};

    if (this.selectedDateFilter !== 'all') {
      if (this.selectedDateFilter === 'today') {
        const today = new Date().toISOString().split('T')[0];
        params = { start_date: today, end_date: today };
      } else if (this.selectedDateFilter === 'yesterday') {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayStr = yesterday.toISOString().split('T')[0];
        params = { start_date: yesterdayStr, end_date: yesterdayStr };
      } else if (this.selectedDateFilter === 'custom' && this.customDateRange) {
        params = {
          start_date: this.customDateRange.startDate,
          end_date: this.customDateRange.endDate
        };
      }
    }

    this.getPaymentSummary(params);
    this.getWaiterPaymentReport(params);
  }

  getPaymentSummary(params: any = {}) {
    this.reportingSrvs.getPaymentSummarys(params).subscribe({
      next: (data: any) => {
        this.summaryCards = [
          { title: 'Total Cash', value: parseInt(data.cash.amount) },
          { title: 'Total Transfer', value: parseInt(data.transfer.amount) },
          { title: 'Total POS', value: parseInt(data.pos.amount) }
        ];
      },
      error: (error) => {
        console.error('Error fetching payment summary:', error);
      }
    });
  }

  getWaiterPaymentReport(params: any = {}) {
    this.reportingSrvs.getWaiterPaymentReport(params).subscribe({
      next: (data: any) => {
        this.waiterPayments = data;
      },
      error: (error) => {
        console.error('Error fetching waiter payment report:', error);
      }
    });
  }

  onDateFilterChange(filter: 'all' | 'today' | 'yesterday' | 'custom') {
    this.selectedDateFilter = filter;
    if (filter !== 'custom') {
      this.showDatePicker = false;
      this.customDateRange = null;
      this.loadReportData();
    } else {
      this.showDatePicker = true;
    }
  }

  onCustomDateSelect(startDate: string, endDate: string) {
    this.customDateRange = { startDate, endDate };
    this.loadReportData();
    this.showDatePicker = false;
  }

  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.filteredsalesHistory);
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Sales History': worksheet },
      SheetNames: ['Sales History']
    };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(data, `sales_history_${new Date().toISOString().split('T')[0]}.xlsx`);
  }

}
