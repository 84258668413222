import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpClient = inject(HttpClient);
  baseUrl = environment.baseApi
  private authLocalStorageToken = `user`;
  private authdetailsLocalStorageToken = `userdetails`;


  login(data: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/auth/login`, data)
      .pipe(
        map((user) => {
          // console.log(user);
          localStorage.setItem(this.authLocalStorageToken, JSON.stringify(user))
          console.log('Array saved successfully');
          // this.currentUserSubject.next(user);
          // this.getUserByToken();
          const result = this.setAuthFromLocalStorage(user);

          return user;
        }),
        catchError((err) => {
          return of(err);
        }),
      );
  }
  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
  }

  isLoggedIn() {
    return localStorage.getItem(this.authLocalStorageToken) !== null;
  }
  forgotPassword(data: any) {
    return this.httpClient.post(`${this.baseUrl}/auth/forgot-password`, data)
      .pipe()
  }
  resetPassword(data: any) {
    return this.httpClient.post(`${this.baseUrl}/auth/forgot-password`, data)
      .pipe()
  }


  public setAuthFromLocalStorage(auth: any): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))
      return true;
    }
    return false;
  }

  // getAuthToken(): Promise<any> {
  //   const key = this.authLocalStorageToken;
  //   let a = localStorage.getItem(key);
  //   return a
  // }
  getAuthToken(): Promise<any> {
    const key = this.authLocalStorageToken;
    return new Promise((resolve, reject) => {
      try {
        const item = localStorage.getItem(key);
        if (item !== null) {
          resolve(JSON.parse(item));
        } else {
          reject('Item not found');
        }
      } catch (error) {
        reject('Error retrieving item');
      }
    });
  }

  getUser(key: string) {
    let a = localStorage.getItem(key)
    if (a !== null) {
      const userData = JSON.parse(a);
      return userData.user;
    }
    return ''
  }
  getToken() {
    let a = localStorage.getItem(this.authLocalStorageToken)
    if (a !== null) {
      const userData = JSON.parse(a);
      return userData.token;
    }
    return ''
  }




}
