import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-mgt',
  standalone: true,
  imports: [NgbModule, RouterModule],
  templateUrl: './customer-mgt.component.html',
  styleUrl: './customer-mgt.component.scss'
})
export class CustomerMgtComponent {
  router = inject(Router)
  orderHistory(id: any) {
    this.router.navigateByUrl(`/admin/customer_management/order_history/${id}`)
  }
}
