<div class="my-5">
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table align-middle border rounded">
          <thead>
            <tr>
              <th scope="col" class="py-4 ps-3"></th>
              <th scope="col" class="py-4">User</th>
              <th scope="col" class="py-4">Log</th>
              <th scope="col" class="py-4">Date</th>
            </tr>
          </thead>
          <tbody>
            @if (loading) {
              <tr>
                <td colspan="4" class="text-center py-4">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            }
            @for (item of activityLogs; track item.created_at) {
              <tr>
                <td class="ps-3">
                  <img [src]="item.performed_by?.image || 'assets/images/vector.png'"
                       width="50"
                       height="50"
                       class="rounded-circle"
                       [alt]="item.performed_by?.name || 'User'">
                </td>
                <td>
                  {{ item.performed_by?.name || 'System' }}
                </td>
                <td>
                  {{ item.log }}
                </td>
                <td>
                  <span [title]="item.created_at | date:'medium'">
                    {{ item.human_readable_date }}
                  </span>
                </td>
              </tr>
            } @empty {
              <tr>
                <td colspan="4">
                  <div class="text-center my-5">
                    No data to display
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>

        @if (totalItems > pageSize) {
          <div class="d-flex justify-content-center mt-4">
            <ngb-pagination
              [collectionSize]="totalItems"
              [(page)]="currentPage"
              [pageSize]="pageSize"
              [maxSize]="5"
              [rotate]="true"
              [boundaryLinks]="true"
              (pageChange)="onPageChange($event)">
            </ngb-pagination>
          </div>
        }
      </div>
    </div>
  </div>
</div>
