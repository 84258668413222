<!-- operational-report.component.html -->
<div class="mt-4">
  <!-- Tabs -->
  <ul ngbNav [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="'waitstaff'">
      <a ngbNavLink (click)="onTabChange('waitstaff')">Waitstaff Performance</a>
    </li>
    <!-- <li [ngbNavItem]="'kitchen'">
      <a ngbNavLink (click)="onTabChange('kitchen')">Kitchen Performance</a>
    </li> -->
    <li [ngbNavItem]="'delays'">
      <a ngbNavLink (click)="onTabChange('delays')">Order Delays</a>
    </li>
  </ul>

  <!-- Content -->
  <div class="mt-4">
    <!-- Summary Cards -->
    <div class="row mb-4" *ngIf="active === 'waitstaff'">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">Total Orders</h6>
            <h3 class="card-title">{{summaryMetrics.waitstaff.total_orders}}</h3>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">Average Processing Time</h6>
            <h3 class="card-title">{{summaryMetrics.waitstaff.average_processing_time}}</h3>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">On-Time Orders</h6>
            <h3 class="card-title">{{summaryMetrics.waitstaff.on_time_orders}}</h3>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row mb-4" *ngIf="active === 'delays'">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">Total Orders</h6>
            <h3 class="card-title">{{summaryMetrics.delays.total_orders | number}}</h3>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">Delayed Orders</h6>
            <h3 class="card-title">{{summaryMetrics.delays.delayed_orders | number}}</h3>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">Average Delay Time</h6>
            <h3 class="card-title">{{summaryMetrics.delays.average_delay_time}} Minutes</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- Similar summary cards for kitchen and delays tabs -->

    <!-- Search -->
    <div class="row mb-4">
      <div class="col-md-4">
        <div class="search-boxe">
          <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchTerm" (input)="onSearch()">
        </div>
      </div>
    </div>

    <!-- Tables -->
    <!-- Waitstaff Performance Table -->
    <!-- Update the waitstaff performance table section -->
    <div class="table-responsive" *ngIf="active === 'waitstaff'">
      <table class="table align-middle border">
        <thead>
          <tr>
            <th class="ps-3">Waiter</th>
            <th>Total Orders Handled</th>
            <th>Total Sales Amount (₦)</th>
            <th>Average Order Value</th>
            <th>Average Customer Rating</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredData">
            <td class="ps-3">
              <div class="d-flex align-items-center">
                <div class="avatar me-2"
                  [style.backgroundImage]="item.waiter_image ? 'url(' + item.waiter_image + ')' : ''"
                  [style.backgroundColor]="!item.waiter_image ? '#f2f4f7' : ''">
                  @if (!item.waiter_image) {
                  <span class="avatar-initials">{{item.waiter_name.charAt(0)}}</span>
                  }
                </div>
                {{item.waiter_name}}
              </div>
            </td>
            <td>{{item.total_orders_handled}}</td>
            <td>₦{{item.total_sales_amount | number}}</td>
            <td>₦{{item.average_order_value || 0 | number:'1.0-0'}}</td>
            <td>
              @if (item.average_customer_rating) {
              <i class="fa fa-solid fa-star text-warning"></i>
              <span class="ms-1">{{item.average_customer_rating}}</span>
              } @else {
              <span class="text-muted">No ratings</span>
              }
            </td>
          </tr>
          <tr *ngIf="filteredData.length === 0">
            <td colspan="5" class="text-center py-4">No data to display</td>
          </tr>
        </tbody>
      </table>

      @if (total > pageSize) {
      <div class="mt-3">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total" [(page)]="page"
          [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
          (pageChange)="refresh($event)">
        </ngb-pagination>
      </div>
      }
    </div>

    <!-- Kitchen Performance Table -->
    <div class="table-responsive" *ngIf="active === 'kitchen'">
      <table class="table align-middle border">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Order Items</th>
            <th>Preparation Time</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredData">
            <td>{{item.order_id}}</td>
            <td>{{item.order_items}}</td>
            <td>{{item.preparation_time}}</td>
          </tr>
          <tr *ngIf="filteredData.length === 0">
            <td colspan="3" class="text-center py-4">No data to display</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Order Delays Table -->
    <div class="table-responsive" *ngIf="active === 'delays'">
      <table class="table align-middle border">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Order Items</th>
            <th>Delay Time</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredData">
            <td>{{item.order_id}}</td>
            <td>{{item.order_items}}</td>
            <td>{{item.delay_time}}</td>
            <td>{{item.reason}}</td>
          </tr>
          <tr *ngIf="filteredData.length === 0">
            <td colspan="4" class="text-center py-4">No data to display</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
