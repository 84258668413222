import { Component, inject, OnInit } from '@angular/core';
import { NgbModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ReportingService } from '../_service/reporting.service';
import { CommonModule } from '@angular/common';
import { SumPipe } from '../pipes/sum.pipe';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface WaiterSale {
  waiter_id: number;
  waiter_name: string;
  item_name: string;
  quantity_sold: string;
  total_amount: string;
  last_sale_time: string;
}

interface WaiterReport {
  waiter_id: number;
  waiter_name: string;
  total_quantity: number;
  total_amount: number;
  items: WaiterSale[];
}

interface WaiterSalesResponse {
  status: string;
  data: WaiterReport[];
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}

interface InventoryItem {
  id: number;
  name: string;
  category: string;
  current_stock: number;
  quantity_sold: string;
  cost_price: string;
  base_selling_price: string;
  base_unrealized_profit: number;
  total_investment: number;
  price_tag_statistics: {
    details: PriceTagDetail[];
    average_selling_price: number;
    average_unrealized_profit: number;
  };
  formatted: {
    cost_price: string;
    base_selling_price: string;
    base_unrealized_profit: string;
    avg_price_tag_price: string;
    avg_price_tag_unrealized_profit: string;
    total_investment: string;
  };
}

interface PriceTagDetail {
  price_tag_name: string;
  selling_price: string;
  unrealized_profit: number;
}

interface InventoryResponse {
  data: {
    current_page: number;
    data: InventoryItem[];
    // Add other pagination fields if needed
  };
}

interface LowStockItem {
  item: string;
  category: string;
  current_stock: number;
  quantity_sold: number;
  reorder_level: number;
  restock_needed: number;
  status?: 'Red' | 'Yellow';
}

@Component({
  selector: 'app-inventory-report',
  standalone: true,
  imports: [NgbModule, CommonModule, SumPipe, FormsModule],
  templateUrl: './inventory-report.component.html',
  styleUrl: './inventory-report.component.scss'
})
export class InventoryReportComponent implements OnInit {
  active = 1
  service = inject(ReportingService)
  summaryReport: any
  expirationReport: any;
  lowStockAlert: any;
  turnoverReport: any;

  selectedDateFilter: 'today' | 'yesterday' | 'custom' = 'today';
  isLoading = false;
  error = '';
  waiterSalesReport: WaiterReport[] = [];

  showDateRangePicker = false;
  dateRange = {
    start: null as NgbDateStruct | null,
    end: null as NgbDateStruct | null
  };

  inventoryItems: InventoryItem[] = [];
  filteredInventoryItems: InventoryItem[] = [];
  inventorySearch = '';
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;

  lowStockItems: LowStockItem[] = [];
  filteredLowStockItems: LowStockItem[] = [];
  lowStockSearch = '';
  lowStockCurrentPage = 1;
  lowStockPageSize = 10;
  lowStockTotalItems = 0;

  ngOnInit(): void {
    this.inventorySummaryReport()
    this.inventoryExpirationDate()
    this.inventoryLowStockAlertReport()
    this.inventoryTurnoverReport()
    this.fetchWaiterSalesReport();
  }
  inventorySummaryReport() {
    this.service.inventorySummaryReport().subscribe({
      next: (res: any) => {
        if (res.data && Array.isArray(res.data.data)) {
          this.inventoryItems = res.data.data;
          this.totalItems = this.inventoryItems.length;
          this.filterInventoryItems();
        } else {
          console.error('Invalid data structure received:', res);
          this.inventoryItems = [];
          this.filteredInventoryItems = [];
        }
      },
      error: (err) => {
        console.error('Error fetching inventory summary:', err);
        this.inventoryItems = [];
        this.filteredInventoryItems = [];
      }
    });
  }
  inventoryExpirationDate() {
    this.service.inventoryExpirationDate().subscribe({
      next: (res) => {
        // console.log('expirTION DATW', res)
        this.expirationReport = res
      }, error: (err) => {
        console.log(err)
      }
    })
  }
  inventoryLowStockAlertReport() {
    this.service.inventoryLowStockAlertReport().subscribe({
      next: (res: any) => {
        this.lowStockItems = res.data.map((item: any) => ({
          item: item.name,
          category: item.category,
          current_stock: item.current_stock,
          quantity_sold: item.quantity_sold,
          reorder_level: item.reorder_level,
          restock_needed: Math.max(0, item.reorder_level - item.current_stock),
          status: item.current_stock <= item.reorder_level / 2 ? 'Red' : 'Yellow'
        }));
        this.lowStockTotalItems = this.lowStockItems.length;
        this.filterLowStockItems();
      },
      error: (err) => {
        console.error('Error fetching low stock alert:', err);
        // Consider adding error handling UI feedback
      }
    });
  }
  inventoryTurnoverReport() {
    this.service.inventoryTurnoverReport().subscribe({
      next: (res) => {
        console.log('turover', res)
        this.turnoverReport = res
      }, error: (err) => {
        console.log(err)
      }
    })
  }

  onDateFilterChange(filter: string) {
    this.selectedDateFilter = filter as 'today' | 'yesterday' | 'custom';

    if (filter === 'custom') {
      this.showDateRangePicker = true;
      return;
    }

    this.showDateRangePicker = false;
    this.fetchWaiterSalesReport();
  }

  onDateRangeSelected() {
    if (this.dateRange.start && this.dateRange.end) {
      this.fetchWaiterSalesReport();
      this.showDateRangePicker = false;
    }
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private getDateParams(): { start_date: string, end_date?: string } {
    const today = new Date();

    switch (this.selectedDateFilter) {
      case 'today':
        return { start_date: this.formatDate(today) };

      case 'yesterday':
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        return { start_date: this.formatDate(yesterday) };

      case 'custom':
        if (this.dateRange.start && this.dateRange.end) {
          const startDate = new Date(
            this.dateRange.start.year,
            this.dateRange.start.month - 1,
            this.dateRange.start.day
          );
          const endDate = new Date(
            this.dateRange.end.year,
            this.dateRange.end.month - 1,
            this.dateRange.end.day
          );
          return {
            start_date: this.formatDate(startDate),
            end_date: this.formatDate(endDate)
          };
        }
        return { start_date: this.formatDate(today) };

      default:
        return { start_date: this.formatDate(today) };
    }
  }

  fetchWaiterSalesReport() {
    this.isLoading = true;
    this.error = '';

    const dateParams = this.getDateParams();

    this.service.getWaiterSalesReport(dateParams).subscribe({
      next: (response: any) => {
        this.waiterSalesReport = response.data;
        this.isLoading = false;
      },
      error: (err) => {
        this.error = 'Failed to load waiter sales report';
        this.isLoading = false;
        console.error(err);
      }
    });
  }

  onInventorySearch(query: string) {
    this.currentPage = 1;
    this.filterInventoryItems();
  }

  filterInventoryItems() {
    if (!Array.isArray(this.inventoryItems)) {
      console.error('inventoryItems is not an array');
      this.filteredInventoryItems = [];
      return;
    }

    const filtered = this.inventoryItems.filter(item =>
      item.name.toLowerCase().includes(this.inventorySearch.toLowerCase()) ||
      item.category.toLowerCase().includes(this.inventorySearch.toLowerCase())
    );

    this.totalItems = filtered.length;
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.filteredInventoryItems = filtered.slice(startIndex, startIndex + this.pageSize);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.filterInventoryItems();
  }

  onPageSizeChange() {
    this.currentPage = 1;
    this.filterInventoryItems();
  }

  onLowStockSearch(query: string) {
    this.lowStockCurrentPage = 1;
    this.filterLowStockItems();
  }

  filterLowStockItems() {
    const filtered = this.lowStockItems.filter(item =>
      item.item.toLowerCase().includes(this.lowStockSearch.toLowerCase()) ||
      item.category.toLowerCase().includes(this.lowStockSearch.toLowerCase())
    );

    this.lowStockTotalItems = filtered.length;
    const startIndex = (this.lowStockCurrentPage - 1) * this.lowStockPageSize;
    this.filteredLowStockItems = filtered.slice(startIndex, startIndex + this.lowStockPageSize);
  }

  onLowStockPageChange(page: number) {
    this.lowStockCurrentPage = page;
    this.filterLowStockItems();
  }

  onLowStockPageSizeChange() {
    this.lowStockCurrentPage = 1;
    this.filterLowStockItems();
  }
}
