import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Component, signal, ViewChild, ElementRef, inject, OnInit } from '@angular/core';
import { debounceTime, tap } from 'rxjs/operators';
import { NgbAlert, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbProgressbarConfig, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { InventoryService } from '../_service/inventory.service';
import { Subject, Subscription } from 'rxjs';
import { UserService } from '../_service/user.service';
import { HttpEventType } from '@angular/common/http';
import { SettingsService } from '../_service/settings.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-stock',
  standalone: true,
  imports: [RouterModule, NgbAlertModule, NgbProgressbarModule, CommonModule, ReactiveFormsModule],
  templateUrl: './stock.component.html',
  styleUrl: './stock.component.scss'
})
export class StockComponent implements OnInit {
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert | undefined;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private _message$ = new Subject<string>();

  type = ''
  uploadSub: Subscription = new Subscription;
  imageName = signal('');
  fileName = signal('')
  fileSize = signal(0);
  fileSize2 = signal(0);
  uploadProgress = signal(0);
  uploadProgress2 = signal(0);
  imagePreview = signal('');
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  selectedFile: File | null = null;
  uploadSuccess: boolean = false;
  uploadError: boolean = false;
  // private snackBar = inject(MatSnackBar)
  private user = inject(UserService)
  private invetorySrvs = inject(InventoryService)
  settings = inject(SettingsService)
  router = inject(Router)
  staticAlertClosed = false;
  message!: string;
  successMessage: string = '';
  roles: any;
  main_image: any = '';
  receipt = signal('');
  showFile = signal(false);
  isLoading = false;

  clearForm() {
    this.restockForm.reset();
    this.removeFile();
    this.receipt.set('');
    this.showFile.set(false);
    this.fileName.set('');
    this.fileSize.set(0);
    this.uploadProgress2.set(0);
    this.selectedFile = null;
    this.uploadSuccess = false;
    this.uploadError = false;
  }

  removeFile(): void {
    this.selectedFile = null;
    this.fileName.set('');
    this.fileSize.set(0);
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
    this.uploadProgress2.set(0);
    this.receipt.set('');
    this.showFile.set(false);
  }
  stocklimitForm = new FormGroup({
    name: new FormControl('Stock Limit'),
    type: new FormControl('fixed'),
    value: new FormControl('')
  })

  sexpirationlimitForm = new FormGroup({
    name: new FormControl('Expiration Date Limit'),
    type: new FormControl('fixed'),
    value: new FormControl('')
  })
  restockForm = new FormGroup({
    inventory_id: new FormControl('', [Validators.required]),
    expiry_date: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    receipt_number: new FormControl('', [Validators.required]),
    cost_price: new FormControl('', [Validators.required]),
    selling_price: new FormControl('', [Validators.required])
  } as { [key: string]: FormControl })
  inventories: any;

  constructor(config: NgbProgressbarConfig, route: ActivatedRoute) {
    // const id: string = route.snapshot.params.id;
    // const id = route.snapshot.paramMap.get('id')
    // this.user_id = id
    // alert(this.user_id)

    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    config.height = '10px';
    this._message$
      .pipe(
        takeUntilDestroyed(),
        tap((message) => (this.successMessage = message)),
        debounceTime(5000),
      )
      .subscribe(() => this.selfClosingAlert?.close());
  }

  ngOnInit(): void {
    this.listInventory()
  }
  listInventory() {
    this.invetorySrvs.listInventories().subscribe({
      next: (res: any) => {
        console.log(res)
        this.inventories = res.data
        // const meta = res.meta
        // this.size = meta.total
        // this.pageSize = meta.per_page
      }
    })
  }
  onFileChange2(event: any): void {
    const file = event.target.files[0] as File | null;
    this.uploadFile2(file);
  }

  onFileDrop2(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this.uploadFile2(file);
  }

  // Prevent default dragover behavior
  onDragOver2(event: DragEvent): void {
    event.preventDefault();
  }
  uploadFile2(file: File | null): void {
    if (file) {
      this.selectedFile = file;
      this.fileSize2.set(Math.round(file.size / 1024)); // Set file size in KB

      const reader = new FileReader();
      // reader.onload = (e) => {

      // };
      reader.readAsDataURL(file);

      this.uploadSuccess = true;
      this.uploadError = false;
      this.fileName.set(file.name); // Set image name
      console.log(this.selectedFile)

      this.user.uploadFile(this.selectedFile).subscribe({
        next: (event: any) => {
          // console.log(event)
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress2.set(Math.round(100 * (event.loaded / event.total)));
          }
          if (event.body) {
            this.receipt.set(event.body.image_url)
            console.log(this.receipt)
            this.showFile.set(true)
          }
        },
        error: (error: any) => {
          this.reset()
          this.error(error)
        },
        complete: () => {
          this.type = 'success'
          this.reset()
          this.message = 'File uploaded successfully'
          this.showErrorMessage(this.message)
        }
      })

    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.type = 'danger'
      this.message = 'Only image files are supported!'
      this.showErrorMessage(this.message)
    }
  }

  // removeFile(): void {
  //   this.selectedFile = null;
  //   this.fileName.set('');
  //   this.fileSize.set(0);
  //   // this.imagePreview.set('');
  //   this.uploadSuccess = false;
  //   this.uploadError = false;
  //   this.uploadProgress.set(0);
  //   this.uploadProgress2.set(0)
  // }

  reset() {
    this.uploadProgress.set(0);
    this.uploadProgress2.set(0)
    // this.uploadSub = null;
  }

  showErrorMessage(message: any) {
    this._message$.next(`${message}`);
  }

  error(err: any) {
    this.message = err.error.message
    this.type = 'danger'
    this.showErrorMessage(this.message)
    console.log('error', err)
  }

  setLimit(data: any) {
    this.settings.storeSettings(data).subscribe({
      next: (res) => {
        Swal.fire('Success', 'Limit set successfully', 'success')
      },
      error: (err) => {
        Swal.fire('Failed', err.error.message, 'error')
      },
    })
  }

  onSubmit() {
    if (this.restockForm.valid) {
      this.isLoading = true;

      if (this.receipt()) {
        this.restockForm.addControl('receipt', new FormControl(''));
        this.restockForm.controls['receipt'].setValue(this.receipt());
      }

      const formValue = this.restockForm.value;
      if (!formValue['receipt']) {
        delete formValue['receipt'];
      }

      this.invetorySrvs.restock(formValue).subscribe({
        next: (res) => {
          Swal.fire({
            title: 'Success!',
            text: 'Restock completed successfully',
            icon: 'success'
          }).then(() => {
            this.clearForm();
          });
        },
        error: (err) => {
          Swal.fire('Failed!', err.error.message, 'error');
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
  }
}
