<div class="container-fluid">
  <!-- Header Section -->
  <div class="row align-items-center mt-5">
    <div class="col-md-4">
      <div class="search-box">
        <input
          type="text"
          placeholder="Search roles..."
          (input)="searchRoles($event)"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-8 text-end">
      <button class="btn btn-primary btn-lg" (click)="createRole()">Create Role</button>
    </div>
  </div>

  <!-- Table Section -->
  <div class="mt-3">
    <div class="table-responsive">
      <table class="table align-middle border rounded">
        <thead>
          <tr>
            <th class="ps-5">Name</th>
            <th>Description</th>
            <th class="text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredRoles">
            <td class="ps-5">{{ item?.name }}</td>
            <td>{{ item?.description || 'No description' }}</td>
            <td class="text-end">
              <button class="btn btn-outline-secondary me-2" (click)="edit(content, item)">
                Permissions
              </button>
              <button class="btn btn-outline-danger" (click)="delete(item.id)">
                Delete Role
              </button>
            </td>
          </tr>
          <tr *ngIf="filteredRoles?.length === 0">
            <td colspan="3" class="text-center py-3">
              No roles found matching your search.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Modal Template (keep this part) -->
<ng-template #content let-modal>
  <div class="modal-body p-4">
    <div class="d-flex mb-4 align-items-center">
      <button class="btn btn-clear" (click)="modal.close('close click')">
        <i class="fa fa-solid fa-arrow-left fa-2xl me-3"></i>
      </button>
      <div class="ms-auto me-auto">
        <h4 class="mb-0">
          <h4 class="mb-0"> {{ name }} </h4>
        </h4>
      </div>

    </div>
    <div class="form-group">
      <label for="" class="form-label">Name</label>
      <input type="text" class="form-control" name="name" [(ngModel)]="name">
    </div>
    <div class="form-group">
      <label for="" class="form-label">Description</label>
      <input type="text" class="form-control" name="description" [(ngModel)]="description">
    </div>
    <div class="form-group">
      <label for="" class="form-label">Permissions</label>
      <div class="card">
        <div class="card-body px-5 py-4">
          @for (perm of permissions; track perm.id) {
          <div class="d-flex align-items-center mb-3">
            <p class="mb-0 me-3"> {{formatPermission( perm?.name) }} </p>
            <div class="form-check form-switch ms-auto">
              <input class="form-check-input" type="checkbox" role="switch" [id]="perm?.name"
                [checked]="isChecked(perm?.id)" (change)="onCheckboxChange($event, perm?.name)">
            </div>
          </div>
          }


        </div>
      </div>
    </div>

    <div class="text-center">
      <button class="btn btn-primary btn-lg" type="button" (click)="update()">Update Permission</button>
    </div>
  </div>
</ng-template>
<!-- created_at: "2024-08-27T18:36:22.000000Z"
description: null
guard_name: "api"
id: 6
name: "tenant.update_user"
updated_at: "2024-08-27T18:36:22.000000Z" -->
