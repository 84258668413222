import { Component, inject } from '@angular/core';
import { AuthService } from '../../_service/auth.service';
import { Router, RouterModule } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
  authService = inject(AuthService);
  router = inject(Router);

  protected resetForm = new FormGroup({
    code: new FormControl('', [Validators.required]),
  })

  message: any;
  onSubmit() {
    if (this.resetForm.valid) {
      this.authService.forgotPassword(this.resetForm.value).subscribe({
        next: (data: any) => {
          Swal.fire('Success', data.message, 'success')
        },
        error: (error: any) => {
          this.message = error.error.errors
        }
      })
    }
  }

}
