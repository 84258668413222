export const environment = {
  production: true,
  pusher: {
    key: 'tes-exchange',
    port: 443,
    host: 'talk.socialconnector.io',
    cluster: 'mt1',
  },
  baseApi: getApiUrl(),
  apiUrl: getApiUrl(),
};

function getApiUrl() {
  const hostname = window.location.hostname;

  // Split the hostname into subdomains
  const hostParts = hostname.split('.');

  // Check if it's under the qrserve.ng domain
  if (hostname.endsWith('qrserve.ng') && hostParts.length > 2) {
    const subdomain = hostParts[0]; // Get the subdomain (e.g., "demi")
    return `https://${subdomain}.backend-dev.qrserve.ng/api`; // Construct the backend API URL dynamically
  }

  return 'https://demofoods.backend-dev.qrserve.ng/api';
}
