<div class="card mt-5">
  <div class="card-body p-5">
    <div class="d-block d-sm-flex">
      <img src="profile.png" width="150" height="150" class="rounded-circle mb-3" alt="">
      <div class="d-block ms-5">
        <div class="d-flex">
          <div class="d-block me-5">
            <h6 class="text-muted">First Name</h6>
            <h6>Jared</h6>
          </div>
          <div class="d-block">
            <h6 class="text-muted">Last Name</h6>
            <h6>Jared</h6>
          </div>
        </div>
        <div class="mt-3">
          <h6 class="text-muted">Email</h6>
          <h6>{{'j.evans@email.com'}}</h6>
        </div>
        <div class="mt-3">
          <h6 class="text-muted">Phone Number</h6>
          <h6>{{'+234805365147'}}</h6>
        </div>
        <div class="mt-3">
          <h6 class="text-muted">Phone Number </h6>
          <h6>{{'Moderator'}}</h6>
        </div>
      </div>
    </div>
  </div>
</div>
