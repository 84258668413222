import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  http = inject(HttpClient);
  auth = inject(AuthService)
  baseUrl = environment.baseApi
  private authLocalStorageToken = `user`;
  user = this.auth.getUser(this.authLocalStorageToken)
  loadingBar = inject(LoadingBarService)

  constructor() { }

  storeSettings(data: any) {
    this.loadingBar.start();
    return this.http.post(`${this.baseUrl}/sales/store-settings`, data)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  notifications() {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/notifications`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  getSettings() {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/orders/deductions`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  viewNotification(id: any) {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/notifications/read/${id}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
}
