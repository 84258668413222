import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from '../_service/inventory.service';
import Swal from 'sweetalert2';
import { FormsModule } from '@angular/forms';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-inventory',
  standalone: true,
  imports: [RouterModule, CommonModule, NgbModule, FormsModule],
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.scss'
})
export class InventoryComponent implements OnInit {
  invetorySrvs = inject(InventoryService)
  router = inject(Router)
  inventories: any = signal('');
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  page = 1
  size = 0
  pageSize = 1
  page2 = 10
  categories: any;
  status: any;
  selectedCategory: string = '';
  selectedStatus: string = '';
  search: any;
  category: any;
  listcategories: any;
  data: any[] = [];
  isLoading = signal(false);

  ngOnInit(): void {
    this.listInventory()
    this.listCategory()
  }

  listInventory() {
    this.invetorySrvs.listInventories(this.page).subscribe({
      next: (res: any) => {
        console.log(res)
        this.inventories.set(res.data)
        for (let inv of this.inventories()) {
          const data = { 'name': inv.name, 'category': inv.category.name, 'quantity': inv.quantity, 'cost price': inv.cost_price, 'selling price': inv?.selling_price }
          this.data.push(data)
        }
        console.log('data', this.data)
        const meta = res.meta
        this.size = meta.total
        this.pageSize = meta.per_page
      }
    })
  }
  capitalizeFirstLetter(sentence: string) {
    if (!sentence) return sentence; // Check if the sentence is empty or undefined
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }
  onSearch() {
    this.isLoading.set(true);
    this.invetorySrvs.listInventories(this.page, this.search, this.category, this.status).subscribe({
      next: (res: any) => {
        this.inventories.set(res.data);
        const meta = res.meta;
        this.size = meta.total;
        this.pageSize = meta.per_page;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.isLoading.set(false);
      }
    });
  }
  CatFilter(name:any,filter: any) {
    this.category = filter
    this.selectedCategory = name;
    this.invetorySrvs.listInventories(this.page, this.search, this.category, this.status).subscribe({
      next: (res: any) => {
        this.inventories.set(res.data)
        const meta = res.meta
        this.size = meta.total
        this.pageSize = meta.per_page
        console.log('Users', res)
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  listCategory() {
    this.invetorySrvs.listCategories().subscribe({
      next: (res: any) => {
        console.log(res)
        this.listcategories = res
      }
    })
  }
  statuusFilter(status: any) {
    this.selectedStatus = status;
    this.status = status
    this.invetorySrvs.listInventories(this.page, this.search, this.category, this.status).subscribe({
      next: (res: any) => {
        this.inventories.set(res.data)
        const meta = res.meta
        this.size = meta.total
        this.pageSize = meta.per_page
        // console.log('Users', res)
      },
      error: (err: any) => {
        console.log(err)
        // Swal.fire('Error', err.error.error, 'error')
      }
    })
  }

  clearFilter() {
    this.page = 1
    this.search = null
    this.status = null
    this.category = null
    this.listInventory()
  }


  refresh(event: any) {
    this.page = event
    this.listInventory()
  }

  edit(id: any) {
    this.router.navigateByUrl(`/admin/inventory/edit_inventory/${id}`)
  }



  create() {
    this.router.navigateByUrl('/admin/inventory/create_inventory')
  }
  enable(data: any) {
    this.invetorySrvs.enable_disableInv(data.id).subscribe({
      next: (res: any) => {
        Swal.fire('Success', res.message, 'success')
      },
      error: (err: any) => {
        Swal.fire('Failed', err.error.error, 'error')
      },
      complete: () => {
        this.listInventory()
      }
    })
  }

  exportToExcel(): void {
    // Convert the data to a worksheet
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);

    // Create a new workbook and append the worksheet
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };

    // Generate the Excel file and save it
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'inventories');
  }

  // Helper function to save the file
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
    saveAs(data, `${fileName}.xlsx`);
  }

  view(inv: any) {
    // if (inv.length > 1) {
    //   for (let logs of inv){
    //     window.open(inv[0].receipt, '_blank')
    //   }
    // } else{
    //   window.open(inv[0].receipt, '_blank')
    // }

    for (let logs of inv) {
      window.open(logs.receipt, '_blank')
    }

  }
  viewLogs(inv: any) {
    console.log(inv);

    this.router.navigateByUrl(`/admin/inventory/inventory_stock/${inv.id}`)
  }
}


