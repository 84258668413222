<div class="row align-items-center mt-5">
  <div class="col-md-4">
    <div class="search-box">
      <input type="text" placeholder="Search" />
      <button>
        <i class="fa fa-light fa-magnifying-glass"></i>
      </button>
    </div>
  </div>
  <div class="col-md-6">
    <div ngbDropdown class="d-inline-block ms-auto me-3" container="body">
      <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
        Filter by Date &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2">Today</button>
        <button ngbDropdownItem class="p-2">This week</button>
        <button ngbDropdownItem class="p-2">This Month</button>
        <button ngbDropdownItem class="p-2">Custom</button>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col-md-6">
    <div class="card rounded-4">
      <div class="card-body p-4">
        <div class="d-flex">
          <div class="d-block me-3">
            <h6>Order ID</h6>
            <p>0123485762</p>
          </div>
          <div class="d-block">
            <h6>Waiter</h6>
            <p>Flora Ikuigbe</p>
          </div>
          <div class="ms-auto">
            <p class="text-muted">25 -04-2024 02:00PM</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6"></div>
</div>
