<div class="row align-items-center mt-5">

  <div class="col-md-6">
    <div ngbDropdown class="d-inline-block ms-auto me-3" container="body">
      <button type="button" class="btn btn-outline-secondary btn-lg" id="dropdownBasic1" ngbDropdownToggle>
        Filter by Date &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2">Today</button>
        <button ngbDropdownItem class="p-2">This week</button>
        <button ngbDropdownItem class="p-2">This Month</button>
        <button ngbDropdownItem class="p-2">Custom</button>
      </div>
    </div>

    <div ngbDropdown class="d-inline-block ms-auto me-3" container="body">
      <button type="button" class="btn btn-outline-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
        Filter by Status &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2">Today</button>
        <button ngbDropdownItem class="p-2">This week</button>
        <button ngbDropdownItem class="p-2">This Month</button>
        <button ngbDropdownItem class="p-2">Custom</button>
      </div>
    </div>
  </div>

</div>

<div class="card mt-5">
  <div class="table-responsive">
    <table class="table align-middle">
      <thead>
        <tr>
          <th class="ps-5 py-3">Order ID</th>
          <th class="py-3">Waiter in charge</th>
          <th class="py-3">Reason</th>
          <th class="py-3">Status</th>
          <th class="py-3">Action</th>
        </tr>
      </thead>
      <tbody>
        @for (item of disputes; track $index) {
        <tr>
          <td class="ps-5"> {{ item.order?.uid }} </td>
          <td> {{ item?.order?.waiter?.name }} </td>
          <td> {{ item.reason }} </td>
          <td>
            @if (item.status == 'pending') {
            <span class="badge text-bg-danger text-danger py-2 px-3 rounded-pill">Unresolved</span>
            }@else {
            <span class="badge text-bg-success text-success py-2 px-3 rounded-pill">Resolved</span>
            }

          </td>
          <td>
            <button class="btn btn-sm btn-outline-secondary px-2 py-2" (click)="orderDetails(item.id)">Order
              Details</button>
          </td>
        </tr>
        }
        @empty {
        <tr>
          <td colspan="5" class="text-center">No data to display</td>
        </tr>
        }

      </tbody>
    </table>
  </div>
</div>
