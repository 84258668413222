// import { HttpEvent, HttpEventType, HttpHandler, HttpHandlerFn, HttpRequest } from "@angular/common/http";
// import { inject } from "@angular/core";
// import { Observable, tap } from "rxjs";
// import { AuthService } from '../_service/auth.service';

// export function serverErrorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
//   let authRequest = req;
//   if (req.url.includes('/auth')) {
//     return next(req);
//   }

//   const authToken = inject(AuthService).getToken();
//   let a = authToken.token
//   const newReq = req.clone({ headers: req.headers.append('X-Authentication-Token', authToken), }); return next(newReq);
//   // Clone the request to add the authentication header.  const newReq = req.clone({    headers: req.headers.append('X-Authentication-Token', authToken),  });  return next(newReq);


//   // return next(req).pipe(tap(event => {
//   //   if (event.type === HttpEventType.Response) {
//   //     console.log(req.url, 'returned a response with status', event.status);
//   //   }
//   // }));
// }

import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, from, switchMap } from 'rxjs';
import { AuthService } from '../_service/auth.service';
import { environment } from '../../environments/environment';
const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()

// export const serverErrorInterceptor: HttpInterceptorFn = (req, next) => {
//   return next(req);
// };
export class serverErrorInterceptor implements HttpInterceptor {
  authService = inject(AuthService)
  baseApi = environment.baseApi
  // constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authRequest = request;
    if (request.url.includes('/auth')) {
      return next.handle(request);
    }
    return from(this.authService.getAuthToken()).pipe(
      switchMap((authToken) => {

        // console.log(JSON.parse(authToken));
        let token = authToken.token
        console.log('token', token);
        // Clone the request and add the authentication token to the headers
        // const authRequest = request.clone({
        //   headers: request.headers.set('Authorization', `Bearer ${token}`)
        // });

        if (token != null) {
          authRequest = request.clone({
            headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token),
          });
        }

        // Pass the modified request to the next interceptor or the HTTP handler
        return next.handle(authRequest);
      })
    );
  }
}
// if (token != null && request.url.includes(this.baseApi)) {

// if (token != null && request.url.includes('alhayatrelieffoundation.com'))
