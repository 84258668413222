<div class="mt-5">

  <div class="row">
    <div class="col-md-4 mb-3">
      <h4 class="earning-title">Total sales</h4>
      <div class="balance-card py-3 pl-3">
        <p class="balance-text">
          Balance
        </p>
        <h3 class="balance-amount mt-auto">
          {{totalSales.total_sales || 0 | currency: '₦'}}
        </h3>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <h4 class="earning-title">Waiter Earnings </h4>
      <div class="balance-card py-3 pl-3">
        <p class="balance-text">
          Balance
        </p>
        <h3 class="balance-amount mt-auto">
          {{totalSales.total_waiter_earnings || 0 | currency: '₦'}}
        </h3>
      </div>
    </div>

  </div>
</div>

<!-- <div class="my-5">
  <div class="row g-5">
    <div class="col-md-6">
      <h4>Inventory</h4>
      <div class="card rounded-3 invcard">
        <div class="table-responsive">
          <table class="table align-middle border rounded">

            <tbody>
              @for(inv of inventories(); track inv.id){
              <tr>
                <td scope="row" class="ps-3" style="width: 60px !important;">
                  <img [src]=" inv?.main_image || 'profile.png'" width="50" height="50" class="rounded " alt="">
                </td>
                <td> {{ inv?.name }} </td>
                <td class="text-end pe-5"> {{ inv?.quantity }} </td>
              </tr>
              }
              @empty {
              <tr class="text-center">
                <td colspan="8">
                  <div class="my-5 text-center">
                    <img src="cook.png" alt="">
                    <p class="mt-3">There are no users at the moment</p>
                    <button class="btn btn-primary" (click)="addInv()">Add Inventory</button>
                  </div>

                </td>
              </tr>
              }



            </tbody>
          </table>


        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4>Pending Orders</h4>
      <div class="card rounded-3 invcard">
        <div class="table-responsive">
          <table class="table table-borderless align-middle">
            <tbody>
              <tr>
                <td class="py-3 ps-3">Order from Table 007</td>
                <td class="py-3 text-end pe-5">1 min ago</td>
              </tr>
              <tr>
                <td class="py-3 ps-3">Order from Table 007</td>
                <td class="py-3 text-end pe-5">2 min ago</td>
              </tr>
              <tr>
                <td class="py-3 ps-3">Order from Table 007</td>
                <td class="py-3 text-end pe-5">3 min ago</td>
              </tr>
              <tr>
                <td class="py-3 ps-3">Order from Table 007</td>
                <td class="py-3 text-end pe-5">4 min ago</td>
              </tr>
              <tr>
                <td class="py-3 ps-3">Order from Table 007</td>
                <td class="py-3 text-end pe-5">5 min ago</td>
              </tr>
              <tr>
                <td class="py-3 ps-3">Order from Table 007</td>
                <td class="py-3 text-end pe-5">6 min ago</td>
              </tr>
              <tr>
                <td class="py-3 ps-3">Order from Table 007</td>
                <td class="py-3 text-end pe-5">7 min ago</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5 g-5">
    <div class="col-md-6">
      <h4>Active Waiters</h4>
      <div class="card rounded-3 invcard">
        <div class="table-responsive">
          <table class="table table-borderless align-middle border rounded">
            <tr>
              <td scope="row" class="ps-3" style="width: 60px !important;">
                <img [src]=" 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
              </td>
              <td>Alexis Herwitz</td>
              <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
            </tr>
            <tr>
              <td scope="row" class="ps-3" style="width: 60px !important;">
                <img [src]=" 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
              </td>
              <td>Alexis Herwitz</td>
              <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
            </tr>
            <tr>
              <td scope="row" class="ps-3" style="width: 60px !important;">
                <img [src]=" 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
              </td>
              <td>Alexis Herwitz</td>
              <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
            </tr>
            <tr>
              <td scope="row" class="ps-3" style="width: 60px !important;">
                <img [src]=" 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
              </td>
              <td>Alexis Herwitz</td>
              <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
            </tr>
            <tr>
              <td scope="row" class="ps-3" style="width: 60px !important;">
                <img [src]=" 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
              </td>
              <td>Alexis Herwitz</td>
              <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
            </tr>
            <tr>
              <td scope="row" class="ps-3" style="width: 60px !important;">
                <img [src]=" 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
              </td>
              <td>Alexis Herwitz</td>
              <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
            </tr>
            <tr>
              <td scope="row" class="ps-3" style="width: 60px !important;">
                <img [src]=" 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
              </td>
              <td>Alexis Herwitz</td>
              <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
            </tr>
            <tr>
              <td scope="row" class="ps-3" style="width: 60px !important;">
                <img [src]=" 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
              </td>
              <td>Alexis Herwitz</td>
              <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
            </tr>

          </table>


        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4>Activity Logs </h4>
      <div class="card rounded-3 invcard">
        <div class="table-responsive">
          <table class="table table-borderless align-middle">
            <tbody>
              @for (item of activityLogs; track item.id) {
              <tr>
                <td class="py-3 ps-3">{{ item?.log }}</td>
                <td class="py-3 text-end pe-3">{{ item?.performed_by?.updated_at |date }}</td>
              </tr>
              }
              @empty {
              <tr>
                <td colspan="4">
                  <div class="text-center my-5">
                    No data to display
                  </div>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="my-4">
  <div class="balance-card py-4 pl-5">
      <div class="container">
        <div class="row mb-3">
          <div class="colmd-6">
            <p class="balance-text">Sales {{getCurrentYear()}}</p>
            <h3 class="balance-amount">{{salesChart && salesChart.total | currency : '₦'}}</h3>
          </div>
          <div class="colmd-6"></div>
        </div>
        <div id="chart1" echarts [options]="options" theme="macarons" class="demo-chart"></div>
      </div>
  </div>

</div>
