import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Component, signal, ViewChild, ElementRef, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Subscription, forkJoin } from 'rxjs';
import { UserService } from '../../_service/user.service';
import { HttpEventType } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { NgbAlert, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbProgressbarConfig, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { InventoryService } from '../../_service/inventory.service';
import { BarcodeService } from '../../_service/barcode.service';
import { CommonModule } from '@angular/common';

interface PriceTagPrice {
  price_tag_id: number;
  selling_price: number;
}

@Component({
  selector: 'app-create-inventory',
  standalone: true,
  imports: [RouterModule, NgbAlertModule, NgbProgressbarModule, CommonModule, ReactiveFormsModule],
  templateUrl: './create-inventory.component.html',
  styleUrl: './create-inventory.component.scss'
})
export class CreateInventoryComponent implements OnInit {
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert | undefined;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  private _message$ = new Subject<string>();
  type = ''
  today: string = '';
  uploadSub: Subscription = new Subscription;
  imageName = signal('');
  fileName = signal('')
  fileSize = signal(0);
  fileSize2 = signal(0);
  uploadProgress = signal(0);
  uploadProgress2 = signal(0);
  imagePreview = signal('');
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  selectedFile: File | null = null;
  uploadSuccess: boolean = false;
  uploadError: boolean = false;
  private user = inject(UserService)
  private invetorySrvs = inject(InventoryService)
  private service = inject(BarcodeService)
  router = inject(Router)
  staticAlertClosed = false;
  message!: string;
  successMessage: string = '';
  roles: any;
  main_image: any = '';
  inventory_id: string | null;
  isLoading: boolean = false;
  priceTags: any[] = [];
  priceTagPrices: PriceTagPrice[] = [];
  categories: any;
  receipt = signal('');
  showFile = signal(false);
  inventory_details: any;

  inventoryForm: FormGroup;

  constructor(config: NgbProgressbarConfig, route: ActivatedRoute) {
    const id = route.snapshot.paramMap.get('id')
    this.inventory_id = id;

    // Initialize form with conditional controls
    const baseControls = {
      name: new FormControl('', [Validators.required]),
      selling_price: new FormControl('', [Validators.required]),
      cost_price: new FormControl('', [Validators.required]),
      category_id: new FormControl('', [Validators.required]),
      low_stock: new FormControl('', [Validators.required]),
      expiry_date_limit: new FormControl('', [Validators.required]),
      price_tag_prices: new FormControl<PriceTagPrice[]>([], [Validators.required])
    };

    // Add quantity and receipt controls only for new inventory
    if (!this.inventory_id) {
      Object.assign(baseControls, {
        quantity: new FormControl('', [Validators.required]),
        receipt_number: new FormControl('', [Validators.required]),
        expiry_date: new FormControl('', [Validators.required])
      });
    }

    this.inventoryForm = new FormGroup(baseControls as { [key: string]: FormControl });

    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    config.height = '10px';
    this._message$
      .pipe(
        takeUntilDestroyed(),
        tap((message) => (this.successMessage = message)),
        debounceTime(5000),
      )
      .subscribe(() => this.selfClosingAlert?.close());
  }

  ngOnInit(): void {
    this.initializeData();

    const todayDate = new Date();
    const year = todayDate.getFullYear();
    const month = ('0' + (todayDate.getMonth() + 1)).slice(-2);
    const day = ('0' + todayDate.getDate()).slice(-2);
    this.today = `${year}-${month}-${day}`;
  }

  private initializeData() {
    // Create an array of observables for parallel execution
    const requests = [
      this.invetorySrvs.listCategories(),
      this.service.getPriceTags()
    ];

    // If we have an inventory_id, add inventory details request
    if (this.inventory_id) {
      requests.push(this.invetorySrvs.listInventoryByID(this.inventory_id));
    }

    // Execute all requests in parallel
    forkJoin(requests).subscribe({
      next: (results: any[]) => {
        // Handle categories
        this.categories = results[0];

        // Handle price tags
        const priceTags = results[1].data;
        this.priceTags = priceTags;

        // Initialize price tag prices with zero values
        this.priceTagPrices = priceTags.map((tag: { id: any; }) => ({
          price_tag_id: tag.id,
          selling_price: 0
        }));

        // If we have inventory details, update the form
        if (this.inventory_id && results[2]) {
          this.inventory_details = results[2];
          this.updateInventoryDetails();
        }

        // Update form with initial price tag prices
        this.inventoryForm.patchValue({ price_tag_prices: this.priceTagPrices });
      },
      error: (err) => {
        this.error(err);
      }
    });
  }

  private updateInventoryDetails() {
    if (!this.inventory_details) return;

    const updateData = {
      name: this.inventory_details.name,
      selling_price: this.inventory_details.selling_price,
      cost_price: this.inventory_details.cost_price,
      category_id: this.inventory_details.category_id,
      expiry_date: this.inventory_details.expiry_date,
      low_stock: this.inventory_details.low_stock,
      expiry_date_limit: this.inventory_details.expiry_date_limit
    };

    this.inventoryForm.patchValue(updateData);

    // Update price tag prices if they exist
    if (this.inventory_details.price_tag_prices) {
      // Merge existing price tags with saved values
      this.priceTagPrices = this.priceTags.map(tag => {
        const savedPrice = this.inventory_details.price_tag_prices.find(
          (p: PriceTagPrice) => p.price_tag_id === tag.id
        );
        return {
          price_tag_id: tag.id,
          selling_price: savedPrice ? savedPrice.selling_price : 0
        };
      });

      this.inventoryForm.patchValue({ price_tag_prices: this.priceTagPrices });
    }

    if (this.inventory_details.main_image) {
      this.main_image = this.inventory_details.main_image;
    }
  }

  updatePriceTagPrice(index: number, value: number) {
    if (index >= 0 && index < this.priceTagPrices.length) {
      this.priceTagPrices[index].selling_price = value;
      this.inventoryForm.patchValue({ price_tag_prices: [...this.priceTagPrices] }); // Create new array reference
    }
  }

  onSubmit() {
    if (this.inventoryForm.valid) {
      this.isLoading = true;

      if (this.main_image != '') {
        this.inventoryForm.addControl('main_image', new FormControl(''));
        this.inventoryForm.controls['main_image'].setValue(this.main_image);
      }

      // Only add receipt in create mode
      if (!this.inventory_id && this.receipt()) {
        this.inventoryForm.addControl('receipt', new FormControl(''));
        this.inventoryForm.controls['receipt'].setValue(this.receipt());
      }

      const formValue = this.inventoryForm.value;

      // Clean up receipt field if it's empty
      if (!formValue['receipt']) {
        delete formValue['receipt'];
      }

      const request = this.inventory_id
        ? this.invetorySrvs.updateInventory(formValue, this.inventory_id)
        : this.invetorySrvs.createInventory(formValue);

      request.subscribe({
        next: (res: any) => {
          Swal.fire({
            title: 'Success',
            text: res.message,
            icon: 'success',
            confirmButtonText: 'done'
          }).then(() => {
            this.router.navigateByUrl('/admin/inventory');
          });
        },
        error: (err: any) => {
          this.type = 'danger'
          this.message = err.error.message || err.error.error
          this.isLoading = false;
          Swal.fire('Error!', err.error.message, 'error')
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
  }

  onFileChange(event: any): void {
    const file = event.target.files[0] as File | null;
    this.uploadFile(file);
  }

  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this.uploadFile(file);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onFileChange2(event: any): void {
    const file = event.target.files[0] as File | null;
    this.uploadFile2(file);
  }

  onFileDrop2(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this.uploadFile2(file);
  }

  onDragOver2(event: DragEvent): void {
    event.preventDefault();
  }

  uploadFile(file: File | null): void {
    if (file && file.type.startsWith('image/')) {
      this.selectedFile = file;
      this.fileSize.set(Math.round(file.size / 1024));

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview.set(e.target?.result as string);
      };
      reader.readAsDataURL(file);

      this.uploadSuccess = true;
      this.uploadError = false;
      this.imageName.set(file.name);
      console.log(this.selectedFile)

      this.user.uploadFile(this.selectedFile).subscribe({
        next: (event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress.set(Math.round(100 * (event.loaded / event.total)));
          }
          if (event.body) {
            this.main_image = event.body.image_url
            console.log(this.main_image)
          }
        },
        error: (error: any) => {
          this.reset()
          this.error(error)
        },
        complete: () => {
          this.type = 'success'
          this.reset()
          this.message = 'Image uploaded successfully'
          this.showErrorMessage(this.message)
        }
      })
    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.type = 'danger'
      this.message = 'Only image files are supported!'
      this.showErrorMessage(this.message)
    }
  }

  uploadFile2(file: File | null): void {
    if (file) {
      this.selectedFile = file;
      this.fileSize2.set(Math.round(file.size / 1024));

      const reader = new FileReader();
      reader.readAsDataURL(file);

      this.uploadSuccess = true;
      this.uploadError = false;
      this.fileName.set(file.name);
      console.log(this.selectedFile)

      this.user.uploadFile(this.selectedFile).subscribe({
        next: (event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress2.set(Math.round(100 * (event.loaded / event.total)));
          }
          if (event.body) {
            this.receipt.set(event.body.image_url)
            console.log(this.receipt)
            this.showFile.set(true)
          }
        },
        error: (error: any) => {
          this.reset()
          this.error(error)
        },
        complete: () => {
          this.type = 'success'
          this.reset()
          this.message = 'File uploaded successfully'
          this.showErrorMessage(this.message)
        }
      })
    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.type = 'danger'
      this.message = 'Only image files are supported!'
      this.showErrorMessage(this.message)
    }
  }

  removeImage(): void {
    this.selectedFile = null;
    this.imageName.set('');
    this.fileSize.set(0);
    this.imagePreview.set('');
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
    this.uploadProgress2.set(0)
  }

  removeFile(): void {
    this.selectedFile = null;
    this.fileName.set('');
    this.fileSize.set(0);
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
    this.uploadProgress2.set(0)
  }

  reset() {
    this.uploadProgress.set(0);
    this.uploadProgress2.set(0)
  }

  showErrorMessage(message: any) {
    this._message$.next(`${message}`);
  }

  error(err: any) {
    this.message = err.error.message
    this.type = 'danger'
    this.showErrorMessage(this.message)
    console.log('error', err)
  }
}
