import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BarcodeService } from '../_service/barcode.service';
import { EncryptionService } from '../_service/encryption.service';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-barcode-mgt',
  templateUrl: './barcode-mgt.component.html',
  styleUrls: ['./barcode-mgt.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule, QRCodeModule, FormsModule, ReactiveFormsModule]
})
export class BarcodeMgtComponent implements OnInit {
  generateForm = new FormGroup({
    name: new FormControl('', Validators.required),
    price_tag_id: new FormControl('', Validators.required)
  })
  barcodesList: any;
  selectedQRCodes: any[] = [];
  priceTags: any[] = [];
  encryptedTable: string = '';
  isLoading: boolean = false;
  searchTerm: string = '';
  filteredBarcodesList: any[] = [];

  constructor(
    private modal: NgbModal,
    private service: BarcodeService,
    private encryptionService: EncryptionService
  ) { }

  ngOnInit(): void {
    this.listBarcodes();
    this.loadPriceTags();
  }
  loadPriceTags() {
    this.service.getPriceTags().subscribe((res: any) => {
      this.priceTags = res.data;
    });
  }
  listBarcodes() {
    this.service.getBarcodes().subscribe((res: any) => {
      this.barcodesList = res;
      this.filteredBarcodesList = res;
    });
  }

  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.barcodesList.forEach((item: any) => (item.selected = checked));
  }

  generateBarcode(content: any) {
    this.generateForm.reset();
    this.modal.open(content, { centered: true });
  }

  generateMultiBarcodePrint(content: any) {
    this.selectedQRCodes = this.barcodesList
      .filter((item: any) => item.selected)
      .map((item: any) => ({
        name: item.name,
        qrdata: `${this.getApiUrl()}?table=${encodeURIComponent(this.encryptionService.encrypt(item.uid))}`
      }));

    this.modal.open(content, { centered: true, size: 'lg' });
  }

  printMultiQRSection() {
    // First, convert QR codes to images
    const qrElements = document.querySelectorAll('qrcode');
    const promises = Array.from(qrElements).map((qrElement) => {
      const canvas = qrElement.querySelector('canvas');
      return new Promise<string>((resolve) => {
        if (canvas) {
          resolve(canvas.toDataURL('image/png'));
        } else {
          resolve('');
        }
      });
    });

    // Convert logo to base64
    const logoImg = new Image();
    logoImg.src = 'icons/QR.png';

    logoImg.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = logoImg.width;
      canvas.height = logoImg.height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(logoImg, 0, 0);
      const logoDataUrl = canvas.toDataURL('image/png');

      Promise.all(promises).then((qrDataUrls) => {
        const container = document.querySelector('.barcode-grid');
        if (!container) return;

        const items = container.querySelectorAll('.barcode-item');
        let printHTML = '';

        items.forEach((item, index) => {
          const name = item.querySelector('.table-name')?.textContent || '';
          const qrDataUrl = qrDataUrls[index];

          printHTML += `
            <div class="barcode-item">
              <h3 class="table-name">${name}</h3>
              <div class="qr-wrapper">
                <img src="${qrDataUrl}" class="qr-code" alt="QR Code">
                <img src="${logoDataUrl}" class="qr-logo" width="60" height="60" alt="Logo">
              </div>
              <p class="scan-text">Scan to place order</p>
              <div class="instructions-list">
                <div class="instruction-item">
                  <i class="fas fa-qrcode"></i>
                  <span>1. Scan the QR Code: Use your phone to scan the QR code on your table.</span>
                </div>
                <div class="instruction-item">
                  <i class="fas fa-list"></i>
                  <span>2. Select Your Items: Browse, select, and customize your cart.</span>
                </div>
                <div class="instruction-item">
                  <i class="fas fa-shopping-cart"></i>
                  <span>3. Place Your Order: Confirm your choices and submit.</span>
                </div>
                <div class="instruction-item">
                  <i class="fas fa-clock"></i>
                  <span>4. Track Progress: Track the order status.</span>
                </div>
                <div class="instruction-item">
                  <i class="fas fa-bell"></i>
                  <span>5. Buzz a Waiter: For additional assistance.</span>
                </div>
              </div>
              <h3 class="powered-by">Powered By QRserve</h3>
            </div>
          `;
        });

        const popupWin = window.open('', '_blank', 'width=800,height=600');
        popupWin?.document.open();
        popupWin?.document.write(`
          <html>
            <head>
              <title>Print QR Codes</title>
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
              <style>
                @page {
                  size: A4;
                  margin: 10mm;
                }
                body {
                  margin: 0;
                  padding: 0;
                  font-family: Arial, sans-serif;
                }
                .barcode-grid {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  gap: 10mm;
                  padding: 5mm;
                }
                .barcode-item {
                  break-inside: avoid;
                  text-align: center;
                  padding: 10px;
                  border: 1px solid #ddd;
                  background: white;
                }
                .table-name {
                  font-size: 14px;
                  font-weight: bold;
                  margin-bottom: 5px;
                }
                .qr-wrapper {
                  position: relative;
                  display: inline-block;
                  margin: 10px auto;
                }
                .qr-code {
                  width: 150px;
                  height: 150px;
                  display: block;
                }
                .powered-by {
                  font-size: 12px;
                  font-weight: 600;
                  margin: 5px 0;
                }
                .qr-logo {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 40px;
                  height: 40px;
                  background: white;
                  padding: 3px;
                  border-radius: 4px;
                  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                  z-index: 10;
                }
                .scan-text {
                  font-size: 14px;
                  font-weight: 600;
                  margin: 5px 0;
                }
                .instructions-list {
                  text-align: left;
                  padding: 0 10px;
                }
                .instruction-item {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  margin-bottom: 3px;
                  font-size: 12px;
                  font-weight: 600;
                }
                .instruction-item i {
                  color: #0d6efd;
                  font-size: 12px;
                  min-width: 12px;
                }
                @media print {
                  body { margin: 0; }
                  .barcode-grid {
                    page-break-inside: avoid;
                  }
                  img {
                    -webkit-print-color-adjust: exact !important;
                    color-adjust: exact !important;
                    print-color-adjust: exact !important;
                  }
                  .qr-logo {
                    display: block !important;
                    visibility: visible !important;
                  }
                }
              </style>
            </head>
            <body onload="window.print(); window.close();">
              <div class="barcode-grid">
                ${printHTML}
              </div>
            </body>
          </html>
        `);
        popupWin?.document.close();
      });
    };
  }

  onsubmit() {
    if (this.generateForm.valid) {
      this.isLoading = true;
      this.service.storeBarcode(this.generateForm.value).subscribe({
        next: (res: any) => {
          this.encryptedTable = this.encryptionService.encrypt(res.uid);
          console.log(res);
        },
        error: (err) => {
          console.log(err);
          Swal.fire('Failed!!', err.error.message, 'error');
          this.isLoading = false;
        },
        complete: () => {
          this.listBarcodes();
          this.modal.dismissAll();
          this.isLoading = false;
        }
      });
    }
  }


  generateSingleBarcodePrint(item: any, content: any) {
    const qrData = `${this.getApiUrl()}?table=${encodeURIComponent(this.encryptionService.encrypt(item.uid))}`;
    const modalRef = this.modal.open(content, { centered: true });
    modalRef.componentInstance.selectedQRCodes = [{ name: item.name, qrdata: qrData }];
  }

  deleteBarcode(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deleteBarcode(id).subscribe({
          next: () => {
            Swal.fire('Deleted!', 'Barcode has been deleted.', 'success');
            this.listBarcodes();
          },
          error: (err) => {
            Swal.fire('Failed', err.error.message, 'error');
          }
        });
      }
    });
  }

  getApiUrl() {
    const hostname = window.location.hostname;
    const hostParts = hostname.split('.');
    if (hostname.endsWith('qrserve.ng') && hostParts.length > 2) {
      const subdomain = hostParts[0];
      return `https://${subdomain}.app.qrserve.ng`;
    }
    return 'https://qafoods.app.qrserve.ng';
  }

  public closeModal(): void {
    this.modal.dismissAll();
  }

  searchBarcodes(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    this.filteredBarcodesList = this.barcodesList.filter((barcode: any) =>
      barcode.name.toLowerCase().includes(searchTerm) ||
      barcode.uid.toLowerCase().includes(searchTerm) ||
      (barcode.price_tag?.name || '').toLowerCase().includes(searchTerm)
    );
  }
}
