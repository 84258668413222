import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SettingsService } from '../_service/settings.service';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit {
  router = inject(Router)
  service = inject(SettingsService)
  notifications: any;
  open(id: any) {
    this.router.navigateByUrl(`/admin/notification/${id}`)
  }

  ngOnInit(): void {
    this.getNotifications()
  }

  getNotifications() {
    this.service.notifications().subscribe({
      next: (res: any) => {
        console.log(res)
        this.notifications = res.data
      },
      error: (err) => {
        console.log(err)
      }
    })
  }
}
