import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Component, signal, ViewChild, ElementRef, inject, OnInit } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Subscription } from 'rxjs';
import { UserService } from '../../_service/user.service';
import { HttpEventType } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { NgbAlert, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbProgressbarConfig, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-user',
  standalone: true,
  imports: [RouterModule, NgbAlertModule, NgbProgressbarModule, ReactiveFormsModule],
  templateUrl: './create-user.component.html',
  styleUrl: './create-user.component.scss'
})
export class CreateUserComponent implements OnInit {
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert | undefined;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  // createForm = new FormGroup({
  //   first_name: new FormControl('', [Validators.required]),
  //   last_name: new FormControl('', [Validators.required]),
  //   email: new FormControl('', [Validators.required, Validators.email]),
  //   phone: new FormControl('', [Validators.required]),
  //   role: new FormControl('', [Validators.required]),
  //   password: new FormControl('', [Validators.required, Validators.min(6)]),
  // })

  createForm = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required]),
    role: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  } as { [key: string]: FormControl }); // Loose typing to allow dynamic controls

  // Conditionally add the image control


  // uploadProgress: number;
  private _message$ = new Subject<string>();
  type = ''
  uploadSub: Subscription = new Subscription;
  imageName = signal('');
  fileSize = signal(0);
  uploadProgress = signal(0);
  imagePreview = signal('');
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  selectedFile: File | null = null;
  uploadSuccess: boolean = false;
  uploadError: boolean = false;
  // private snackBar = inject(MatSnackBar)
  private user = inject(UserService)
  router = inject(Router)
  staticAlertClosed = false;
  message!: string;
  successMessage: string = '';
  roles: any;
  image: any = '';
  user_id: string | null;
  user_details: any;
  constructor(config: NgbProgressbarConfig, route: ActivatedRoute) {
    // const id: string = route.snapshot.params.id;
    const id = route.snapshot.paramMap.get('id')
    this.user_id = id
    // alert(this.user_id)

    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    config.height = '10px';
    this._message$
      .pipe(
        takeUntilDestroyed(),
        tap((message) => (this.successMessage = message)),
        debounceTime(5000),
      )
      .subscribe(() => this.selfClosingAlert?.close());
  }

  ngOnInit(): void {
    if (this.user_id != null) {
      this.user.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          console.log('user_details ', res)
          this.user_details = res
          this.createForm.patchValue({
            first_name: this.user_details.first_name,
            last_name: this.user_details.last_name,
            email: this.user_details.email,
            phone: this.user_details.phone,
            role: this.user_details.roles[0],
            // password: new FormControl('', [Validators.required, Validators.minLength(6)]),
          });
        },
        error: (err: any) => {
          this.error(err)
        },
        complete: () => {
          // this.updateProfile();
        }
      })
    }
    this.getroles()

  }

  updateProfile() {
    this.createForm.patchValue({
      first_name: this.user_details.first_name,
      last_name: this.user_details.last_name,
      email: this.user_details.email,
      phone: this.user_details.phone,
      role: this.user_details.roles[0],
      // password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  // Handler for file input change
  onFileChange(event: any): void {
    const file = event.target.files[0] as File | null;
    this.uploadFile(file);
  }

  // Handler for file drop
  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this.uploadFile(file);
  }

  // Prevent default dragover behavior
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  // Method to handle file upload
  uploadFile(file: File | null): void {
    if (file && file.type.startsWith('image/')) {
      this.selectedFile = file;
      this.fileSize.set(Math.round(file.size / 1024)); // Set file size in KB

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview.set(e.target?.result as string); // Set image preview URL
      };
      reader.readAsDataURL(file);

      this.uploadSuccess = true;
      this.uploadError = false;
      this.imageName.set(file.name); // Set image name
      console.log(this.selectedFile)

      this.user.uploadFile(this.selectedFile).subscribe({
        next: (event: any) => {
          // console.log(event)
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress.set(Math.round(100 * (event.loaded / event.total)));
          }
          if (event.body) {
            this.image = event.body.image_url
            console.log(this.image)
          }
        },
        error: (error: any) => {
          this.reset()
          this.error(error)
        },
        complete: () => {
          this.type = 'success'
          this.reset()
          this.message = 'Image uploaded successfully'
          this.showErrorMessage(this.message)
        }
      })

    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.type = 'danger'
      this.message = 'Only image files are supported!'
      this.showErrorMessage(this.message)
    }
  }

  // Method to remove the uploaded image
  removeImage(): void {
    this.selectedFile = null;
    this.imageName.set('');
    this.fileSize.set(0);
    this.imagePreview.set('');
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
  }
  reset() {
    this.uploadProgress.set(0);
    // this.uploadSub = null;
  }

  showErrorMessage(message: any) {
    this._message$.next(`${message}`);
  }
  getroles() {
    this.user.getRoles().subscribe({
      next: (res: any) => {
        // console.log('success', res)
        this.roles = res
      },
      error: (err: any) => {
        this.error(err)
      }
    })
  }

  OnSubmit() {
    if (this.createForm.valid) {
      console.log('image url', this.image)
      if (this.image != '') {
        this.createForm.addControl('image', new FormControl(''));
        this.createForm.controls['image'].setValue(this.image);
      }
      // this.createForm.setControl('image', new FormControl(this.image))
      console.log(this.createForm.value)
      const formValue = this.createForm.value;
      if (!formValue['image']) {
        delete formValue['image'];
        // this.createForm.removeControl('image')
      }
      if (this.user_id != null) {
        this.type = 'info'
        this.message = 'Updating user'
        this.showErrorMessage(this.message)
        this.user.updateUser(this.createForm.value, this.user_id).subscribe({
          next: (res: any) => {
            Swal.fire({
              title: 'Success',
              text: `${formValue['role']} account updated successfully`,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then((_res) => {
              if (_res.isConfirmed) {
                this.router.navigateByUrl('/admin/user')
              }
              this.router.navigateByUrl('/admin/user')
            })
          },
          error: (err: any) => {
            this.error(err)
          }
        })
      } else {
        this.type = 'info'
        this.message = 'Creating user'
        this.showErrorMessage(this.message)
        this.user.createUSer(this.createForm.value).subscribe({
          next: (res: any) => {
            Swal.fire({
              title: 'Success',
              text: `${formValue['role']} account updated successfully`,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then((_res) => {
              if (_res.isConfirmed) {
                this.router.navigateByUrl('/admin/user')
              }
              this.router.navigateByUrl('/admin/user')
            })
          },
          error: (err: any) => {
            this.error(err)
          }
        })
      }
    }
  }

  error(err: any) {
    this.message = err.error.message
    this.type = 'danger'
    this.showErrorMessage(this.message)
    console.log('error', err)
  }

}
