<!-- sales.component.html -->
<div class="mt-4">
  <!-- Add this before the summary cards section -->
  <div class="payment-report-header mb-4">
    <div class="filter-container d-flex gap-3 align-items-center pl-3">
      <div class="date-filter-buttons">
        <button class="btn"
                [class.active]="selectedDateFilter === 'all'"
                (click)="onDateFilterChange('all')">
          All
        </button>
        <button class="btn"
                [class.active]="selectedDateFilter === 'today'"
                (click)="onDateFilterChange('today')">
          Today
        </button>
        <button class="btn"
                [class.active]="selectedDateFilter === 'yesterday'"
                (click)="onDateFilterChange('yesterday')">
          Yesterday
        </button>
        <button class="btn"
                [class.active]="selectedDateFilter === 'custom'"
                (click)="onDateFilterChange('custom')">
          Custom Date
        </button>
      </div>

      @if (showDatePicker) {
        <div class="custom-date-inputs d-flex gap-3 align-items-center">
          <input type="date"
                 class="form-control"
                 #startDate
                 placeholder="dd/mm/yyyy">
          <span>to</span>
          <input type="date"
                 class="form-control"
                 #endDate
                 placeholder="dd/mm/yyyy">
          <button class="btn btn-success"
                  (click)="onCustomDateSelect(startDate.value, endDate.value)">
            Apply
          </button>
        </div>
      }
    </div>
  </div>
  <!-- Sales Summary Section -->
  <div class="row g-4 mb-4">
    <div class="col-md-4" *ngFor="let card of summaryCards">
      <div class="card h-100">
        <div class="card-body">
          <h6 class="card-subtitle mb-2 text-muted">{{ card.title }}</h6>
          <h3 class="card-title mb-0">{{ card.value | currency:'₦ ' }}</h3>
        </div>
      </div>
    </div>
  </div>
  <!-- Employee Sales Table -->
  <div class="card mt-4">
    <div class="card-header bg-white">
      <h6 class="mb-0">Waiter Payment Report</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Waiter Name</th>
              <th>Cash (₦)</th>
              <th>POS (₦)</th>
              <th>Transfer (₦)</th>
              <th>Total Amount (₦)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payment of waiterPayments">
              <td>{{ payment.waiter_name }}</td>
              <td>{{ payment.cash | currency:'₦ ' }}</td>
              <td>{{ payment.pos | currency:'₦ ' }}</td>
              <td>{{ payment.transfer | currency:'₦ ' }}</td>
              <td>{{ payment.total_amount | currency:'₦ ' }}</td>
            </tr>
            @if (waiterPayments.length === 0) {
            <tr>
              <td colspan="5" class="text-center py-4">
                No waiter payment data available
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

