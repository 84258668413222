import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';  // Import CryptoJS

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private secretKey = 'dortaenc640';  // Replace with your own secret key

  constructor() {}

  // Method to encrypt a string
  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey).toString();
  }

  // Method to decrypt a string
  decrypt(textToDecrypt: string): string {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey).toString(CryptoJS.enc.Utf8);
  }
}
