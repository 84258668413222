import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../_service/auth.service';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, CommonModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  authService = inject(AuthService);
  router = inject(Router);
  constructor(private toastr: ToastrService) {}
  protected loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  })
  message: any;
  isLoading = false;

  onSubmit() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.authService.login(this.loginForm.value)
        .subscribe({
          next: (user) => {
            console.log(user)
            if (user?.error) {
              this.message = user?.error?.message
              Swal.fire('Error', user?.error?.message, 'error')
            } else {
              // this.toastr.success('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> Welcome to <b>Black Dashboard Angular</b> - a beautiful freebie for every web developer.', '', {
              //   disableTimeOut: true,
              //   closeButton: true,
              //   enableHtml: true,
              //   toastClass: "alert alert-success alert-with-icon",
              //   // positionClass: 'toast-' + from + '-' +  align
              // });
              this.router.navigate(['/admin/dashboard']);
            }
          },
          error: (error) => {
            Swal.fire('Error', 'An error occurred', 'error')
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    }
  }

}
