<div class="container py-5">
  <div class="row">
    <div class="col-lg-8 mx-auto">
      <div class="card mb-4">
        <div class="card-body p-3">
          @if ( successMessage) {
          <ngb-alert #selfClosingAlert [type]="type" (closed)="successMessage = ''"> {{ message }} </ngb-alert>
          }
          <h4>Branding</h4>
          <div class="form-area">
            <div class="form-group mt-3">
              <label for="" class="form-label">Business Name</label>
              <input type="text" class="form-control" placeholder="Business Name" [(ngModel)]="name">
            </div>
            <div class="form-group mt-3">
              <label for="" class="form-label">Secret Key</label>
              <input type="text" class="form-control" placeholder="Secret Key" [(ngModel)]="secret_key">
            </div>
            <div class="form-group mt-3">
              <label for="" class="form-label">Public Key</label>
              <input type="text" class="form-control" placeholder="Public Key" [(ngModel)]="public_key">
            </div>
            <div class="form-group mt-3">
              <label for="" class="form-label">Revenue Head</label>
              <input type="text" class="form-control" placeholder="Revenue Head" [(ngModel)]="revenue_head">
            </div>
            <div class="mt-3">
              <label for="" class="form-label">Business Logo</label>
              <div class="card">
                <div class="card-body py-4">

                  @if(uploadProgress()){
                  <ngb-progressbar class="mb-3" [value]="uploadProgress()" type="primary" />

                  }

                  <div class="d-block d-sm-flex ">
                    <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
                      (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
                      @if(imagePreview() || main_image !== null){
                      <img [src]="imagePreview() || main_image" alt="Image Preview" width="100" class="image-preview" />
                      }@else{
                      <img src="icons/demo-image.svg" width="100" alt="">
                      }


                      <input type="file" accept=".jpg, .jpeg, .png" (change)="onFileChange($event)" hidden #fileInput />
                    </div>
                    @if(imageName()){
                    <div class="d-block ms-3">
                      <p class="fw-bold image-upload-title">Upload Logo</p>
                      <p class="image-upload-label">
                        <span class="image-name">{{ imageName() }} - ({{fileSize()}} KB)</span>
                      </p>
                      <div>
                        <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                          (click)="fileInput.click()">Select
                          Logo</button>
                        @if(selectedFile || imagePreview!== null ){
                        <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeImage()">Remove</button>
                        }
                      </div>

                    </div>
                    }
                    @else{
                    <div class="d-block ms-3">
                      <p class="fw-bold image-upload-title">Upload Logo</p>
                      <p class="image-upload-label">
                        Drag and drop Logo or click the “select Logo” to choose photo to upload
                      </p>
                      <div>
                        <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                          (click)="fileInput.click()">Select
                          Logo</button>
                        @if(selectedFile || imagePreview!== null ){
                        <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeImage()">Remove</button>
                        }
                      </div>

                    </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <button class="btn btn-primary btn-lg" (click)="saveBusinessDetails()">Save</button>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h4 class="mb-4 fw-bold">Deductions</h4>
          <!-- Value Added Tax -->
          <form [formGroup]="vatForm" class="mb-4">
            <label class="form-label">Value Added Tax</label>
            <div class="d-flex gap-3 align-items-center">
              <div class="flex-grow-1">
                <input
                  type="number"
                  class="form-control"
                  formControlName="value"
                  placeholder="Enter VAT amount">
              </div>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn"
                  [class.btn-success]="vatForm.get('type')?.value === 'percent'"
                  [class.btn-light]="vatForm.get('type')?.value !== 'percent'"
                  (click)="vatForm.patchValue({type: 'percent'})">
                  Percentage
                </button>
                <button
                  type="button"
                  class="btn"
                  [class.btn-success]="vatForm.get('type')?.value === 'fixed'"
                  [class.btn-light]="vatForm.get('type')?.value !== 'fixed'"
                  (click)="vatForm.patchValue({type: 'fixed'})">
                  Fixed Amount
                </button>
              </div>
            </div>
          </form>

          <!-- Withholding Tax -->
          <form [formGroup]="taxForm" class="mb-4">
            <label class="form-label">Withholding Tax</label>
            <div class="d-flex gap-3 align-items-center">
              <div class="flex-grow-1">
                <input
                  type="number"
                  class="form-control"
                  formControlName="value"
                  placeholder="Enter withholding tax amount">
              </div>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn"
                  [class.btn-success]="taxForm.get('type')?.value === 'percent'"
                  [class.btn-light]="taxForm.get('type')?.value !== 'percent'"
                  (click)="taxForm.patchValue({type: 'percent'})">
                  Percentage
                </button>
                <button
                  type="button"
                  class="btn"
                  [class.btn-success]="taxForm.get('type')?.value === 'fixed'"
                  [class.btn-light]="taxForm.get('type')?.value !== 'fixed'"
                  (click)="taxForm.patchValue({type: 'fixed'})">
                  Fixed Amount
                </button>
              </div>
            </div>
          </form>

          <!-- Service Charge -->
          <form [formGroup]="servicechargeForm" class="mb-4">
            <label class="form-label">Service Charge</label>
            <div class="d-flex gap-3 align-items-center">
              <div class="flex-grow-1">
                <input
                  type="number"
                  class="form-control"
                  formControlName="value"
                  placeholder="Enter service charge amount">
              </div>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn"
                  [class.btn-success]="servicechargeForm.get('type')?.value === 'percent'"
                  [class.btn-light]="servicechargeForm.get('type')?.value !== 'percent'"
                  (click)="servicechargeForm.patchValue({type: 'percent'})">
                  Percentage
                </button>
                <button
                  type="button"
                  class="btn"
                  [class.btn-success]="servicechargeForm.get('type')?.value === 'fixed'"
                  [class.btn-light]="servicechargeForm.get('type')?.value !== 'fixed'"
                  (click)="servicechargeForm.patchValue({type: 'fixed'})">
                  Fixed Amount
                </button>
              </div>
            </div>
          </form>

          <!-- Save Button -->
          <div class="mt-4">
            <button
              class="btn btn-success"
              (click)="submit()"
              [disabled]="isSubmitting || vatForm.invalid || taxForm.invalid || servicechargeForm.invalid">
              <span class="spinner-border spinner-border-sm me-2" *ngIf="isSubmitting"></span>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
