import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbAlert, NgbAlertModule, NgbProgressbarConfig, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Component, signal, ViewChild, ElementRef, inject, OnInit } from '@angular/core';

import { Subscription, firstValueFrom } from 'rxjs';
import { UserService } from '../_service/user.service';
import { HttpEventType } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import Swal from 'sweetalert2';
import { SettingsService } from '../_service/settings.service';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { CommonModule } from '@angular/common';
interface DeductionsResponse {
  vat: string;
  vat_type: 'percent' | 'fixed';
  withholding_tax: string;
  withholding_tax_type: 'percent' | 'fixed';
  service_charge: string;
  service_charge_type: 'percent' | 'fixed';
}
@Component({
  selector: 'app-system-configuration',
  standalone: true,
  imports: [RouterModule, NgbAlertModule, NgbProgressbarModule, ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './system-configuration.component.html',
  styleUrl: './system-configuration.component.scss'
})
export class SystemConfigurationComponent {
  name: any
  secret_key: any
  revenue_head: any
  public_key: any
  businessDetails: any
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert | undefined;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private _message$ = new Subject<string>();
  type = ''
  uploadSub: Subscription = new Subscription;
  imageName = signal('');
  fileName = signal('')
  fileSize = signal(0);
  fileSize2 = signal(0);
  uploadProgress = signal(0);
  uploadProgress2 = signal(0);
  imagePreview = signal('');
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  selectedFile: File | null = null;
  uploadSuccess: boolean = false;
  uploadError: boolean = false;
  staticAlertClosed = false;
  message!: string;
  successMessage: string = '';
  roles: any;
  main_image: any = '';
  vatForm = new FormGroup({
    name: new FormControl("vat"),
    type: new FormControl('percent', Validators.required),
    value: new FormControl('', [Validators.required, Validators.min(0)])
  });

  taxForm = new FormGroup({
    name: new FormControl("withholding_tax"),
    type: new FormControl('percent', Validators.required),
    value: new FormControl('', [Validators.required, Validators.min(0)])
  });

  servicechargeForm = new FormGroup({
    name: new FormControl("service_charge"),
    type: new FormControl('percent', Validators.required),
    value: new FormControl('', [Validators.required, Validators.min(0)])
  });
  loading = false;
  isSubmitting = false;

  private user = inject(UserService)
  settings = inject(SettingsService)
  router = inject(Router)
  constructor(config: NgbProgressbarConfig, route: ActivatedRoute) {
    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    config.height = '10px';
    this._message$
      .pipe(
        takeUntilDestroyed(),
        tap((message) => (this.successMessage = message)),
        debounceTime(5000),
      )
      .subscribe(() => this.selfClosingAlert?.close());
  }
  ngOnInit(): void {
    this.getBusinessDetails();
    this.getDeductions();
  }
  getDeductions() {
    this.loading = true;
    this.settings.getSettings().subscribe({
      next: (res: any) => {
        // Update VAT form
        this.vatForm.patchValue({
          value: res.vat,
          type: res.vat_type
        });

        // Update Withholding Tax form
        this.taxForm.patchValue({
          value: res.withholding_tax,
          type: res.withholding_tax_type
        });

        // Update Service Charge form
        this.servicechargeForm.patchValue({
          value: res.service_charge,
          type: res.service_charge_type
        });
      },
      error: (err) => {
        Swal.fire('Error', 'Failed to load deductions settings', 'error');
        console.error('Error loading deductions:', err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  getBusinessDetails() {
    this.user.getBusinessDetails().subscribe({
      next: (res) => {
        this.businessDetails = res
        this.name = this.businessDetails.business_name
        this.secret_key = this.businessDetails.private_key
        this.public_key = this.businessDetails.public_key
        this.revenue_head = this.businessDetails.revenue_head
        this.main_image = this.businessDetails.business_logo
        console.log(this.businessDetails)
      },
      error: (err) => {
        console.log(err)
      }
    })
  }
  saveBusinessDetails() {

    this.user.storeSettings({ business_name: this.name, private_key: this.secret_key, public_key: this.public_key, revenue_head: this.revenue_head, business_logo: this.main_image }).subscribe({
      next: (res) => {
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {
        Swal.fire('Success', 'Business details updated successfully', 'success')
      }
    })
  }
  // Handler for file input change
  onFileChange(event: any): void {
    const file = event.target.files[0] as File | null;
    this.uploadFile(file);
  }

  // Handler for file drop
  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this.uploadFile(file);
  }

  // Prevent default dragover behavior
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }


  // Method to handle file upload
  uploadFile(file: File | null): void {
    if (file && file.type.startsWith('image/')) {
      this.selectedFile = file;
      this.fileSize.set(Math.round(file.size / 1024)); // Set file size in KB

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview.set(e.target?.result as string); // Set image preview URL
      };
      reader.readAsDataURL(file);

      this.uploadSuccess = true;
      this.uploadError = false;
      this.imageName.set(file.name); // Set image name
      console.log(this.selectedFile)

      this.user.uploadFile(this.selectedFile).subscribe({
        next: (event: any) => {
          // console.log(event)
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress.set(Math.round(100 * (event.loaded / event.total)));
          }
          if (event.body) {
            this.main_image = event.body.image_url
            console.log(this.main_image)
          }
        },
        error: (error: any) => {
          this.reset()
          this.error(error)
        },
        complete: () => {
          this.type = 'success'
          this.reset()
          this.message = 'Image uploaded successfully'
          this.showErrorMessage(this.message)
        }
      })

    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.type = 'danger'
      this.message = 'Only image files are supported!'
      this.showErrorMessage(this.message)
    }
  }

  removeImage(): void {
    this.selectedFile = null;
    this.imageName.set('');
    this.fileSize.set(0);
    this.imagePreview.set('');
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
    this.uploadProgress2.set(0)
  }
  removeFile(): void {
    this.selectedFile = null;
    this.fileName.set('');
    this.fileSize.set(0);
    // this.imagePreview.set('');
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
    this.uploadProgress2.set(0)
  }
  reset() {
    this.uploadProgress.set(0);
    this.uploadProgress2.set(0)
    // this.uploadSub = null;
  }

  showErrorMessage(message: any) {
    this._message$.next(`${message}`);
  }

  error(err: any) {
    this.message = err.error.message
    this.type = 'danger'
    this.showErrorMessage(this.message)
    console.log('error', err)
  }

  async submit() {
    if (!this.vatForm.valid || !this.taxForm.valid || !this.servicechargeForm.valid) {
      Swal.fire('Error', 'Please fill all required fields correctly', 'error');
      return;
    }

    this.isSubmitting = true;

    try {
      // Submit VAT
      await firstValueFrom(this.settings.storeSettings(this.vatForm.value));

      // Submit Withholding Tax
      await firstValueFrom(this.settings.storeSettings(this.taxForm.value));

      // Submit Service Charge
      await firstValueFrom(this.settings.storeSettings(this.servicechargeForm.value));

      Swal.fire('Success', 'All deductions updated successfully', 'success');
      this.getDeductions(); // Refresh the data
    } catch (error: any) {
      Swal.fire('Error', error.error?.message || 'Failed to update deductions', 'error');
    } finally {
      this.isSubmitting = false;
    }
  }
}
