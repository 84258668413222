import { Component } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-return-history',
  standalone: true,
  imports: [NgbModule],
  templateUrl: './return-history.component.html',
  styleUrl: './return-history.component.scss'
})
export class ReturnHistoryComponent {

}
