import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { RolesService } from '../_service/roles.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [RouterModule, NgbModule, ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss'
})
export class RolesComponent implements OnInit {
  router = inject(Router)
  modal = inject(NgbModal)
  service = inject(RolesService)
  roles: any[] = [];
  role: any;
  permissions: any;
  selectedPermissions: any[] = [];
  name: any
  description: any
  permId: any;
  searchTerm: string = '';
  filteredRoles: any[] = [];

  ngOnInit(): void {
    this.getRoles()
    this.getPermissions()
  }

  getRoles() {
    this.service.getRoles().subscribe({
      next: (res: any) => {
        this.roles = res;
        this.filteredRoles = res;
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  getPermissions() {
    this.service.getPermissions().subscribe({
      next: (res) => {
        console.log(res)
        this.permissions = res
      }, error: (err) => {
        console.log(err)
      }
    })
  }

  createRole() {
    this.router.navigateByUrl('admin/roles_and_permissions/create_role')
  }

  edit(content: any, item: any) {
    this.role = item
    this.name = item.name
    this.description = item.description
    this.permId = item.id
    console.log(item.permissions)
    this.selectedPermissions = item.permissions
    this.modal.open(content, { centered: true }).result
  }

  isChecked(permId: number): boolean {
    return this.selectedPermissions.some(item => item.id === permId);
  }

  delete(id: any) {
    Swal.fire({
      title: 'Delete Moderator',
      text: 'Are you sure you want delete the Moderator Role',
      confirmButtonText: 'Delete Role',
      cancelButtonText: 'Cancel',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deleteRole(id).subscribe({
          next: (res) => {
            Swal.fire('Deleted', 'Role deleted', 'success')
          },
          error: (err) => {
            Swal.fire('Failed!!!', err.error.message, 'error')
          },
          complete: () => {
            this.getRoles()
          }
        })

      } else { }
    })

  }

  onCheckboxChange(event: Event, permissionId: any): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      // Add permission.id to the array
      this.selectedPermissions.push(permissionId);
    } else {
      // Remove permission.id from the array if unchecked
      this.selectedPermissions = this.selectedPermissions.filter(id => id !== permissionId);
    }
    // console.log(this.selectedPermissions); // For debugging
  }

  update() {
    const data = {
      name: this.name,
      description: this.description,
      permissions: this.selectedPermissions

    }

    console.log(data)

    this.service.updateRole(data, this.permId).subscribe({
      next: (res) => {
        Swal.fire('Success', 'Role updated successfully')
      }, error: (err) => {
        Swal.fire('Failed', err.error.message, 'error')
      },
      complete: () => {
        this.getRoles()
        this.modal.dismissAll()
      }
    })
  }

  formatPermission(permission: string): string {
    // Split the permission string by the dot and underscore
    const [context, action] = permission.split('.');

    // Replace underscores with spaces and capitalize the words
    const formattedAction = action
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    // Return the formatted string with the example suffix
    return `${formattedAction}`;
  }

  searchRoles(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    this.filteredRoles = this.roles.filter((role: any) =>
      role.name.toLowerCase().includes(searchTerm) ||
      (role.description || '').toLowerCase().includes(searchTerm)
    );
  }

}
