import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DisputesService } from '../_service/disputes.service';

@Component({
  selector: 'app-dispute-resolution',
  standalone: true,
  imports: [NgbModule, RouterModule],
  templateUrl: './dispute-resolution.component.html',
  styleUrl: './dispute-resolution.component.scss'
})
export class DisputeResolutionComponent implements OnInit {
  router = inject(Router)
  service = inject(DisputesService)
  disputes: any;
  orderDetails(id: any) {
    this.router.navigateByUrl(`/admin/dispute_resolution/order_details/${id}`)
  }
  ngOnInit(): void {
    this.listDisputes()
  }

  listDisputes() {
    this.service.listDisputes().subscribe({
      next: (res: any) => {
        console.log(res)
        this.disputes = res.data
      },
      error: (err) => {
        console.log(err)
      }
    })
  }
}
