import { Component, signal, ViewChild, ElementRef, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { InventoryService } from '../_service/inventory.service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserService } from '../_service/user.service';
import { HttpEventType } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { NgbAlert, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbProgressbarConfig, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [NgbModule, ReactiveFormsModule, FormsModule, RouterModule, NgbProgressbarModule, LoadingBarHttpClientModule, LoadingBarModule],
  templateUrl: './category.component.html',
  styleUrl: './category.component.scss'
})
export class CategoryComponent implements OnInit {
  loadingBar = inject(LoadingBarService)
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert | undefined;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  router = inject(Router)
  invetorySrvs = inject(InventoryService)
  modal = inject(NgbModal)
  private _message$ = new Subject<string>();
  type = ''
  uploadSub: Subscription = new Subscription;
  imageName = signal('');
  fileSize = signal(0);
  uploadProgress = signal(0);
  imagePreview = signal('');
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  selectedFile: File | null = null;
  uploadSuccess: boolean = false;
  uploadError: boolean = false;
  // private snackBar = inject(MatSnackBar)
  private user = inject(UserService)
  staticAlertClosed = false;
  message!: string;
  successMessage: string = '';
  roles: any;
  image_url: any = '';
  user_id!: string;
  user_details: any;
  categories: any;
  createCatForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    type: new FormControl('restaurant'),
  } as { [key: string]: FormControl })
  updateCatForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    type: new FormControl('restaurant'),
  } as { [key: string]: FormControl })
  cat_id: any;
  data: any;
  swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-2",
      cancelButton: "btn btn-secondary"
    },
    buttonsStyling: false
  });
  filteredCategories: any[] = [];  // Array to display the filtered items
  searchTerm: string = '';
  constructor(config: NgbProgressbarConfig) {
    this._message$
      .pipe(
        takeUntilDestroyed(),
        tap((message) => (this.successMessage = message)),
        debounceTime(5000),
      )
      .subscribe(() => this.selfClosingAlert?.close());

    config.striped = true;
    config.animated = true;
  }

  ngOnInit(): void {
    this.listCategory()
  }
  listCategory() {
    this.invetorySrvs.listCategories().subscribe({
      next: (res: any) => {
        console.log(res)
        this.categories = res
        this.filteredCategories = [...this.categories];
      }
    })
  }
  create(content: any) {
    this.modal.open(content, { centered: true, size: 'md' }).result
  }
  edit(content: any, data: any) {
    this.data = data
    this.cat_id = data.id

    this.updateCatForm.patchValue({
      name: data.name,
      type: data.type,
      image: data.image
    })
    this.modal.open(content, { centered: true, size: 'md' }).result
  }
  disable(data: any) {
    this.cat_id = data.id
    let cat_data = data

    if (cat_data.disabled == 1) {
      this.swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `Are you sure you want to enable ${cat_data.name}?`,
        showCancelButton: true,
        icon: "question",
        confirmButtonText: "Yes, enable",
        cancelButtonText: 'No',
       
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.invetorySrvs.disableCategory(this.cat_id).subscribe({
            next: (res: any) => {
              Swal.fire('Success', 'Category updated', 'success')
            },
            error: (err: any) => {
              Swal.fire('Failed', err.error.error, 'error')
            },
            complete: () => {
              this.listCategory()
            }
          })
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    } else {
      this.swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `Are you sure you want to disable ${cat_data.name}?`,
        showCancelButton: true,
        icon: "question",
        confirmButtonText: "Yes",
        cancelButtonText: 'No',
        cancelButtonColor: 'btn-secondary'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.invetorySrvs.disableCategory(this.cat_id).subscribe({
            next: (res: any) => {
              console.log(res)
              Swal.fire('Success', 'Category updated', 'success')
            },
            error: (err: any) => {
              console.log(err)
              Swal.fire('Failed', err.error.error, 'error')
            },
            complete: () => {
              this.listCategory()
            }
          })
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
    // Swal.fire({
    //   title: `Are you sure you want to disable ${cat_data.name}?`,
    //   showCancelButton: true,
    //   confirmButtonText: "Disable",
    // }).then((result) => {
    //   /* Read more about isConfirmed, isDenied below */
    //   if (result.isConfirmed) {
    //     Swal.fire("Saved!", "", "success");
    //   } else if (result.isDenied) {
    //     Swal.fire("Changes are not saved", "", "info");
    //   }
    // });
  }


  onFileChange(event: any): void {
    const file = event.target.files[0] as File | null;
    this.uploadFile(file);
  }

  // Handler for file drop
  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this.uploadFile(file);
  }

  // Prevent default dragover behavior
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  // Method to handle file upload
  uploadFile(file: File | null): void {
    if (file && file.type.startsWith('image/')) {
      this.selectedFile = file;
      this.fileSize.set(Math.round(file.size / 1024)); // Set file size in KB

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview.set(e.target?.result as string); // Set image preview URL
      };
      reader.readAsDataURL(file);

      this.uploadSuccess = true;
      this.uploadError = false;
      this.imageName.set(file.name); // Set image name
      console.log(this.selectedFile)

      this.user.uploadFile(this.selectedFile).subscribe({
        next: (event: any) => {
          // console.log(event)
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress.set(Math.round(100 * (event.loaded / event.total)));
          }
          if (event.body) {
            this.image_url = event.body.image_url
            console.log(this.image_url)
          }
        },
        error: (error: any) => {
          this.reset()
          this.error(error)
        },
        complete: () => {
          this.type = 'success'
          this.reset()
          this.message = 'Image uploaded successfully'
          this.showErrorMessage(this.message)
        }
      })

    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.type = 'danger'
      this.message = 'Only image files are supported!'
      this.showErrorMessage(this.message)
    }
  }

  // Method to remove the uploaded image
  removeImage(): void {
    this.selectedFile = null;
    this.imageName.set('');
    this.fileSize.set(0);
    this.imagePreview.set('');
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
    this.image_url = null
  }
  reset() {
    this.uploadProgress.set(0);
    // this.uploadSub = null;
  }

  showErrorMessage(message: any) {
    this._message$.next(`${message}`);
  }
  error(err: any) {
    this.message = err.error.message
    this.type = 'danger'
    this.showErrorMessage(this.message)
    console.log('error', err)
  }

  OnSubmit() {
    if (this.createCatForm.valid) {
      console.log('image url', this.image_url)
      if (this.image_url != '') {
        this.createCatForm.addControl('image', new FormControl(''));
        this.createCatForm.controls['image'].setValue(this.image_url);
      }
      console.log(this.createCatForm.value)
      const formValue = this.createCatForm.value;
      if (!formValue['image']) {
        delete formValue['image'];
      }

      this.invetorySrvs.createCategory(this.createCatForm.value).subscribe({
        next: (res: any) => {
          console.log(res)
        },
        error: (err: any) => {
          this.type = 'danger'
          this.message = err.error.message
          this.showErrorMessage(this.message)
        },
        complete: () => {
          this.modal.dismissAll()
          this.type = 'info'
          this.message = 'Category created successfully'
          this.showErrorMessage(this.message)
          this.listCategory()
        }
      })
      // this.type = 'info'
      // this.message = 'Updating user'
      // this.showErrorMessage(this.message)

    }
  }



  OnEdit(info:any) {
    if (this.updateCatForm.valid) {
      console.log('image url', this.image_url)
      if (this.image_url != '') {
        this.updateCatForm.addControl('image', new FormControl(''));
        this.updateCatForm.controls['image'].setValue(this.image_url);
      } else {
        this.updateCatForm.addControl('image', new FormControl(''));
        this.updateCatForm.controls['image'].setValue(info.image);
      }
      console.log(this.updateCatForm.value)
      const formValue = this.updateCatForm.value;
      if (!formValue['image']) {
        delete formValue['image'];
      }

      this.invetorySrvs.updateCategory(this.updateCatForm.value, this.cat_id).subscribe({
        next: (res: any) => {
          console.log(res)
        },
        error: (err: any) => {
          this.type = 'danger'
          this.message = err.error.error
          this.showErrorMessage(this.message)
        },
        complete: () => {
          this.modal.dismissAll()
          this.type = 'info'
          this.message = 'Category edited successfully'
          this.showErrorMessage(this.message)
          this.listCategory()
        }
      })
    }
  }

  onSearch() {
    if (!this.searchTerm || this.searchTerm.trim() === '') {
      // If the search field is empty, display the full array
      this.filteredCategories = [...this.categories];
    } else {
      // Otherwise, filter the array based on the search term
      const searchTermLower = this.searchTerm.toLowerCase();
      this.filteredCategories = this.categories.filter((category: any) =>
        category.name.toLowerCase().includes(searchTermLower)
      );
    }
  }


}
