<div class="card mt-5">
  <div class="table-responsive">
    <table class="table align-middle">
      <thead>
        <tr>
          <th class="ps-3 py-3">Order ID</th>
          <th class="py-3">Inventory Items</th>
          <th class="py-3">Order Amount</th>
          <th class="py-3">Payment Method</th>
          <th class="py-3">Order Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="ps-3">28281</td>
          <td>Amala X2, Gbegiri Soup, Ogufe X2, Coca-Cola 50cl, Bottled Water</td>
          <td>3,569.16</td>
          <td>Cash</td>
          <td>25-09-2024</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
