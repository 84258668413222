<div class="d-flex justify-content-center mt-3">
  @if ( successMessage) {
  <ngb-alert #selfClosingAlert [type]="type" (closed)="successMessage = ''"> {{ message }} </ngb-alert>
  }
</div>
<div class="row my-3">
  <div class="col-md-6">
    <div class="form-area">
      <form [formGroup]="inventoryForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="name" class="form-label">Item Name</label>
          <input type="text" class="form-control" formControlName="name">
        </div>
        <div class="form-group">
          <label for="name" class="form-label">Category </label>
          <select class="form-select" name="category_id" formControlName="category_id">
            @for (item of categories; track item.id) {
            <option [value]="item.id">{{item.name}}</option>
            }
          </select>
        </div>

        @if (!inventory_id) {
          <div class="form-group">
            <label for="name" class="form-label">Quantity </label>
            <input type="number" class="form-control" name="quantity" min="0" id="" formControlName="quantity">
          </div>

          <div class="form-group">
            <label for="name" class="form-label">Receipt Number </label>
            <input type="text" class="form-control" name="receipt_number" id="" formControlName="receipt_number">
          </div>

        <div class="form-group">
          <label for="expiry_date" class="form-label">Expiry Date</label>
          <input type="date" class="form-control" name="expiry_date" id="" formControlName="expiry_date" [min]="today">
        </div>
      }
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <label for="cost_price" class="form-label">Cost Price</label>
              <input type="number" class="form-control" min="1" name="cost_price" id="" formControlName="cost_price">
            </div>
            <div class="col-md-6">
              <label for="selling_price" class="form-label">Selling Price</label>
              <input type="number" class="form-control" min="1" name="selling_price" id=""
                formControlName="selling_price">
            </div>
          </div>
        </div>
        <div class="form-group mt-4">
          <label class="form-label">Price Tag Selling Prices</label>
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Tag Name</th>
                      <th>Selling Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (tag of priceTags; track tag.id; let i = $index) {
                    <tr>
                      <td>{{ tag.name }}</td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          [value]="priceTagPrices[i].selling_price"
                          (input)="updatePriceTagPrice(i, $any($event.target).value)"
                          min="0"
                          [placeholder]="'Enter price for ' + tag.name"
                        >
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <label for="low_stock" class="form-label">Low Stock Limit</label>
              <input type="number" class="form-control" min="1" name="low_stock" id="" formControlName="low_stock">
            </div>
            <div class="col-md-6">
              <label for="expiry_date_limit" class="form-label">Expiry Date Limit</label>
              <input type="number" class="form-control" min="1" name="expiry_date_limit" id=""
                formControlName="expiry_date_limit">
            </div>
          </div>
        </div>
        <div class="receipt-area d-block d-md-none">
          <div>
            <div class="form-area">
              <label for="" class="form-label">Inventory Image</label>
            </div>
            <div class="card">
              <div class="card-body py-4">

                @if(uploadProgress()){
                <ngb-progressbar class="mb-3" [value]="uploadProgress()" type="primary" />

                }

                <div class="d-block d-sm-flex ">
                  <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
                    (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
                    @if(imagePreview() || inventory_details?.main_image ){
                    <img [src]="imagePreview() || inventory_details?.main_image" alt="Image Preview" width="100"
                      class="image-preview" />
                    }@else{
                    <img src="icons/demo-image.svg" width="100" alt="">
                    }


                    <input type="file" accept=".jpg, .jpeg, .png" (change)="onFileChange($event)" hidden #fileInput />
                  </div>
                  @if(imageName()){
                  <div class="d-block ms-3">
                    <p class="fw-bold image-upload-title">Upload Photo</p>
                    <p class="image-upload-label">
                      <span class="image-name">{{ imageName() }} - ({{fileSize()}} KB)</span>
                    </p>
                    <div>
                      <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                        (click)="fileInput.click()">Select
                        Photo</button>
                      @if(selectedFile || imagePreview!== null ){
                      <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeImage()">Remove
                        Photo</button>
                      }
                    </div>

                  </div>
                  }
                  @else{
                  <div class="d-block ms-3">
                    <p class="fw-bold image-upload-title">Upload Photo</p>
                    <p class="image-upload-label">
                      Drag and drop photo or click the “select photo” to choose photo to upload
                    </p>
                    <div>
                      <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                        (click)="fileInput.click()">Select
                        Photo</button>
                      @if(selectedFile || imagePreview!== null ){
                      <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeImage()">Remove
                        Photo</button>
                      }
                    </div>

                  </div>
                  }

                </div>
              </div>
            </div>
          </div>
          <!--
          @if(receipt()){
          <p> {{ receipt() }} </p>
          } -->
          @if (!inventory_id) {
          <div class="mt-3">
            <div class="form-area">
              <label for="" class="form-label">Upload Receipt</label>
            </div>
            <div class="card">
              <div class="card-body py-4">

                @if(uploadProgress2()){
                <ngb-progressbar class="mb-3" [value]="uploadProgress2()" type="primary" />

                }

                <div class="d-block d-sm-flex ">
                  <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
                    (drop)="onFileDrop2($event)" (dragover)="onDragOver2($event)">
                    @if(showFile() || inventory_details?.other_images){
                    <img src="file_filled.png" alt="Image Preview" width="100" class="image-preview" />
                    }@else{
                    <img src="icons/demo-image.svg" width="100" alt="">
                    }
                    <input type="file" accept=".doc, .docx, .pdf, .txt, .png,.jpg,.jpeg"
                      (change)="onFileChange2($event)" hidden #fileInput2 />
                  </div>
                  @if(fileName()){
                  <div class="d-block ms-3">
                    <p class="fw-bold image-upload-title">Upload Receipt</p>
                    <p class="image-upload-label">
                      <span class="image-name">{{ fileName() }} - ({{fileSize()}} KB)</span>
                    </p>
                    <div>
                      <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                        (click)="fileInput2.click()">Select
                        Receipt</button>
                      @if(selectedFile || imagePreview!== null ){
                      <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeFile()">Remove</button>
                      }
                    </div>

                  </div>
                  }
                  @else{
                  <div class="d-block ms-3">
                    <p class="fw-bold image-upload-title">Upload Receipt</p>
                    <p class="image-upload-label">
                      Drag and drop file or click the “select Receipt" to choose receipt to upload
                    </p>
                    <div>
                      <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                        (click)="fileInput2.click()">Select
                        Receipt</button>
                      @if(selectedFile || imagePreview!== null ){
                      <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeFile()">Remove</button>
                      }
                    </div>

                  </div>
                  }

                </div>
              </div>
            </div>
          </div>
        }
        </div>
        <div class="mt-3">
          <button
            class="btn btn-primary btn-lg spec-btn"
            type="submit"
            [disabled]="inventoryForm.invalid || isLoading"
          >
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            @if (inventory_id !== null) {
              <span>{{ isLoading ? 'Updating...' : 'Update Inventory' }}</span>
            } @else {
              <span>{{ isLoading ? 'Adding...' : 'Add Inventory' }}</span>
            }
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-6 d-none d-md-block">
    <div>
      <div class="form-area">
        <label for="" class="form-label">Inventory Image</label>
      </div>
      <div class="card">
        <div class="card-body py-4">

          @if(uploadProgress()){
          <ngb-progressbar class="mb-3" [value]="uploadProgress()" type="primary" />

          }

          <div class="d-block d-sm-flex ">
            <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
              (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
              @if(imagePreview() || inventory_details?.main_image ){
              <img [src]="imagePreview() || inventory_details?.main_image" alt="Image Preview" width="100"
                class="image-preview" />
              }@else{
              <img src="icons/demo-image.svg" width="100" alt="">
              }


              <input type="file" accept=".jpg, .jpeg, .png" (change)="onFileChange($event)" hidden #fileInput />
            </div>
            @if(imageName()){
            <div class="d-block ms-3">
              <p class="fw-bold image-upload-title">Upload Photo</p>
              <p class="image-upload-label">
                <span class="image-name">{{ imageName() }} - ({{fileSize()}} KB)</span>
              </p>
              <div>
                <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                  (click)="fileInput.click()">Select
                  Photo</button>
                @if(selectedFile || imagePreview!== null ){
                <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeImage()">Remove Photo</button>
                }
              </div>

            </div>
            }
            @else{
            <div class="d-block ms-3">
              <p class="fw-bold image-upload-title">Upload Photo</p>
              <p class="image-upload-label">
                Drag and drop photo or click the “select photo” to choose photo to upload
              </p>
              <div>
                <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                  (click)="fileInput.click()">Select
                  Photo</button>
                @if(selectedFile || imagePreview!== null ){
                <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeImage()">Remove Photo</button>
                }
              </div>

            </div>
            }

          </div>
        </div>
      </div>
    </div>
    <!--
    @if(receipt()){
    <p> {{ receipt() }} </p>
    } -->
    @if (!inventory_id) {
    <div class="mt-3">
      <div class="form-area">
        <label for="" class="form-label">Upload Receipt</label>
      </div>
      <div class="card">
        <div class="card-body py-4">

          @if(uploadProgress2()){
          <ngb-progressbar class="mb-3" [value]="uploadProgress2()" type="primary" />

          }

          <div class="d-block d-sm-flex ">
            <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
              (drop)="onFileDrop2($event)" (dragover)="onDragOver2($event)">
              @if(showFile() ){
              <img src="file_filled.png" alt="Image Preview" width="100" class="image-preview" />
              }@else{
              <img src="icons/demo-image.svg" width="100" alt="">
              }
              <input type="file" accept=".doc, .docx, .pdf, .txt, .png,.jpg,.jpeg" (change)="onFileChange2($event)"
                hidden #fileInput2 />
            </div>
            @if(fileName()){
            <div class="d-block ms-3">
              <p class="fw-bold image-upload-title">Upload Receipt</p>
              <p class="image-upload-label">
                <span class="image-name">{{ fileName() }} - ({{fileSize()}} KB)</span>
              </p>
              <div>
                <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                  (click)="fileInput2.click()">Select
                  Receipt</button>
                @if(selectedFile || imagePreview!== null ){
                <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeFile()">Remove</button>
                }
              </div>

            </div>
            }
            @else{
            <div class="d-block ms-3">
              <p class="fw-bold image-upload-title">Upload Receipt</p>
              <p class="image-upload-label">
                Drag and drop file or click the “select Receipt" to choose receipt to upload
              </p>
              <div>
                <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                  (click)="fileInput2.click()">Select
                  Receipt</button>
                @if(selectedFile || imagePreview!== null ){
                <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeFile()">Remove</button>
                }
              </div>

            </div>
            }

          </div>
        </div>
      </div>
    </div>
  }
  </div>
</div>
