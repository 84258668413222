import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_service/auth.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterModule, NgbDropdownModule, CommonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  authService = inject(AuthService);
  router = inject(Router);
  route = inject(ActivatedRoute)
  id!: string | null;
  hasstrings = false;

  status: boolean = false;
  isCollapse = false;
  isCollapse2 = false;
  isCollapse3 = false;

  // New property to track mobile sidebar expansion state
  isMobileExpanded = false;

  public currentSegment: string = '';

  constructor(private location: Location) { }

  ngOnInit(): void {
    // Initialize the current segment on first load
    this.updateCurrentSegment(this.router.url);

    // Subscribe to router events to detect route changes
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateCurrentSegment(event.urlAfterRedirects);
      }
    });
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  // Helper function to extract the last segment of the URL
  private updateCurrentSegment(url: string): void {
    const urlSegments = url.split('/');
    const searchStrings = ['edit_user', 'edit_inventory', 'inventory_stock', 'order_history', 'order_details', 'notification', 'waiter_details'];

    if (searchStrings.some(searchString => urlSegments.includes(searchString))) {
      this.hasstrings = true;
      this.currentSegment = urlSegments[urlSegments.length - 2];
      this.currentSegment = this.currentSegment.replace(/_/g, ' ');
    } else {
      this.hasstrings = false;
      this.currentSegment = urlSegments[urlSegments.length - 1];
      this.currentSegment = this.currentSegment.replace(/_/g, ' ');
    }
  }

  toggle() {
    this.status = !this.status;
    if (this.isCollapse == true) {
      this.isCollapse = false;
    }
  }

  // New method to toggle mobile sidebar expansion
  toggleMobileSidebar() {
    this.isMobileExpanded = !this.isMobileExpanded;
  }

  collapse() {
    if (this.status == true) {
      this.status = false;
    }
    this.isCollapse2 = false;
    this.isCollapse3 = false;
    this.isCollapse = !this.isCollapse;
  }

  collapse2() {
    if (this.status == true) {
      this.status = false;
    }
    this.isCollapse = false;
    this.isCollapse3 = false;
    this.isCollapse2 = !this.isCollapse2;
  }

  collapse3() {
    if (this.status == true) {
      this.status = false;
    }
    this.isCollapse = false;
    this.isCollapse2 = false;
    this.isCollapse3 = !this.isCollapse3;
  }

  collapseAll() {
    if (this.status == true) {
      this.status = false;
    }
    this.isCollapse = false;
    this.isCollapse2 = false;
    this.isCollapse3 = false;
  }

  back() {
    this.location.back();
  }
}
