<div class="row align-items-center mt-5">
  <div class="col-md-4">
    <div class="search-box">
      <input type="text" placeholder="Search" />
      <button>
        <i class="fa fa-light fa-magnifying-glass"></i>
      </button>
    </div>
  </div>
  <div class="col-md-6">
    <div ngbDropdown class="d-inline-block ms-auto me-3" container="body">
      <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
        Filter by Date &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2">Today</button>
        <button ngbDropdownItem class="p-2">This week</button>
        <button ngbDropdownItem class="p-2">This Month</button>
        <button ngbDropdownItem class="p-2">Custom</button>
      </div>
    </div>

    <div ngbDropdown class="d-inline-block ms-auto me-3" container="body">
      <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
        Filter by Waiter &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="p-2">Today</button>
        <button ngbDropdownItem class="p-2">This week</button>
        <button ngbDropdownItem class="p-2">This Month</button>
        <button ngbDropdownItem class="p-2">Custom</button>
      </div>
    </div>
  </div>

</div>

<div class="card mt-5">
  <div class="table-responsive">
    <table class="table align-middle">
      <thead>
        <tr>
          <td class="py-3 ps-3">Name</td>
          <td>Phone Number</td>
          <td>Email</td>
          <td>Action</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="ps-3">Jedd Celedon</td>
          <td>1 58973765</td>
          <td>{{ 'Josese.S@email.com' }} </td>
          <td><button class="btn btn-secondary" (click)="orderHistory(1)">Order History</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
