<div class="mt-5">
  <h3 class="waiter-name">Waiter Name: <i>{{summary?.waiter_name}}</i></h3>
  <div class="row mt-3">
    <div class="col-md-3 mb-3">
      <div class="balance-card py-3 pl-3">
        <p class="balance-text">
          Total Amount
        </p>
        <h3 class="balance-amount mt-auto">
          {{summary?.total || 0 | currency: ' ₦'}}
        </h3>
      </div>

    </div>
    <div class="col-md-3 mb-3">
      <div class="balance-card py-3 pl-3">
        <p class="balance-text">
          Cash Balance
        </p>
        <h3 class="balance-amount mt-auto">
          {{summary?.cash_transactions || 0 | currency: ' ₦'}}
        </h3>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="balance-card py-3 pl-3">
        <p class="balance-text">
          Wallet Transfer Balance
        </p>
        <h3 class="balance-amount mt-auto">
          {{summary?.transfer_transactions || 0 | currency: ' ₦'}}
        </h3>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="balance-card py-3 pl-3">
        <p class="balance-text">
          Card (POS) Balance
        </p>
        <h3 class="balance-amount mt-auto">
          {{summary?.pos_transactions || 0 | currency: ' ₦'}}
        </h3>
      </div>
    </div>
  </div>
</div>


<div class="mt-5 transaction-history">
  <h3>Transaction History</h3>
  <div class="table-responsive">
    <table class="table align-middle border rounded">
      <thead>
       <tr>
        <th class="py-3 ps-5">Order ID</th>
        <th class="py-3">Table</th>
        <th class="py-3">Amount ₦</th>
        <th class="py-3">Payment Method</th>
        <th class="py-3">Date</th>
       </tr>
      </thead>
      <tbody>
        @for (item of tnxHistory; track $index) {
        <tr>
          <td class="ps-5">{{ item?.order?.uid }}</td>
          <td>{{ item?.order?.barcode_details.name }}</td>
          <td> {{ item?.amount| currency: ' ₦' }} </td>
          <td> {{ item?.payment_method }} </td>
          <td> {{ item?.order?.transaction_date | date:'medium' }} </td>
        </tr>
        }
      </tbody>
    </table>
    @if (size > pageSize){
    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="size" [(page)]="page" [pageSize]="pageSize"
      [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="refresh($event)" />
    }
  </div>
</div>
