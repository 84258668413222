<div class="row align-items-center mt-5">
  <div class="col-md-4">
    <div class="search-box">
      <input
        type="text"
        placeholder="Search tables..."
        (input)="searchBarcodes($event)"
        class="form-control"
      />
    </div>
  </div>
  <div class="col-md-8 text-md-end">
    <button class="btn btn-lg btn-secondary spec-btn" (click)="generateMultiBarcodePrint(content2)">
      Print Selected QR Codes
    </button>
    <button class="btn btn-lg btn-primary spec-btn ms-2" (click)="generateBarcode(barcodeModal)">Generate Barcode</button>
  </div>
</div>

<div class="mt-3">
  <div class="table-responsive">
    <table class="table align-middle border rounded">
      <thead>
        <tr>
          <th class="py-3"><input type="checkbox" (click)="toggleSelectAll($event)" /> Select All</th>
          <th class="py-3">Barcode Name</th>
          <th class="py-3">Barcode UID</th>
          <th class="py-3">Price Tag</th>
          <th class="py-3">Active Order</th>
          <th class="py-3">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredBarcodesList">
          <td>
            <input type="checkbox" [(ngModel)]="item.selected" />
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.uid }}</td>
          <td>{{ item.price_tag?.name || 'No tag assigned' }}</td>
          <td>{{ item.active_orders_count }}</td>
          <td>
            <button class="btn btn-danger" (click)="deleteBarcode(item.id)">
              <i class="fa fa-regular fa-ban"></i> &nbsp; Delete Barcode
            </button>
          </td>
        </tr>
        <tr *ngIf="filteredBarcodesList.length === 0">
          <td colspan="6" class="text-center py-3">
            No tables found matching your search.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Multi QR Print Template -->
<ng-template #content2>
  <div class="modal-body py-4">
    <div class="barcode-grid">
      <div class="barcode-item" *ngFor="let qr of selectedQRCodes">
        <h3 class="table-name">{{qr.name}}</h3>
        <div class="qr-wrapper">
          <qrcode [qrdata]="qr.qrdata"
                  [width]="150"
                  [errorCorrectionLevel]="'M'"
                  [margin]="0"
                  [elementType]="'canvas'"></qrcode>
          <img src="icons/QR.png" class="qr-logo" alt="Logo">
        </div>
        <p class="scan-text">Scan to place order</p>

        <div class="instructions-list">
          <div class="instruction-item">
            <i class="fas fa-qrcode"></i>
            <span>1. Scan the QR Code: Use your phone to scan the QR code on your table.</span>
          </div>
          <div class="instruction-item">
            <i class="fas fa-list"></i>
            <span>2. Select Your Items: Browse, select, and customize your cart.</span>
          </div>
          <div class="instruction-item">
            <i class="fas fa-shopping-cart"></i>
            <span>3. Place Your Order: Confirm your choices and submit.</span>
          </div>
          <div class="instruction-item">
            <i class="fas fa-clock"></i>
            <span>4. Track Progress: Track the order status.</span>
          </div>
          <div class="instruction-item">
            <i class="fas fa-bell"></i>
            <span>5. Buzz a Waiter: For additional assistance.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-3">
      <button class="btn btn-primary btn-half_block spec-btn" (click)="printMultiQRSection()">Print QR Codes</button>
    </div>
  </div>
</ng-template>

<!-- Add this template for barcode generation -->
<ng-template #barcodeModal>
  <div class="modal-body">
    <div class="d-flex">
      <button class="btn btn-clear" (click)="closeModal()">
        <i class=" fa fa-regular fa-arrow-left fa-2xl"></i>
      </button>
      <div class="ms-auto me-auto">
        <h3 class="mb-0">Generate Barcode</h3>
      </div>
    </div>
    <div class="form-area">
      <form [formGroup]="generateForm" (ngSubmit)="onsubmit()" class="mt-4">
        <div class="form-group">
          <label for="" class="form-label">Barcode Name</label>
          <input type="text" class="form-control" name="name" formControlName="name">
        </div>

        <div class="form-group mt-3">
          <label for="" class="form-label">Price Tag</label>
          <select class="form-select" formControlName="price_tag_id">
            <option value="">Select a price tag</option>
            @for (tag of priceTags; track tag.id) {
              <option [value]="tag.id">{{ tag.name }}</option>
            }
          </select>
        </div>

        <div class="mt-4 text-center">
          <button class="btn btn-primary btn-half_block btn-lg spec-btn"
                  type="submit"
                  [disabled]="isLoading || !generateForm.valid">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            {{ isLoading ? 'Generating...' : 'Generate Barcode' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
