import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DisputesService } from '../_service/disputes.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [NgbModule, RouterModule, CommonModule],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss'
})
export class OrderDetailsComponent implements OnInit {
  router = inject(Router)
  service = inject(DisputesService)
  id: any;
  disputeDetails: any;
  // orderDetails: any;
  constructor(
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id')
  }

  ngOnInit(): void {
    this.getDispute()
  }

  getDispute() {
    this.service.listSingleDisputes(this.id).subscribe({
      next: (res) => {
        console.log(res)
        this.disputeDetails = res
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {
        // this.getOrder(this.disputeDetails?.order_id)
      }
    })
  }

  // getOrder(id: any) {
  //   this.service.showOrders(id).subscribe({
  //     next: (res) => {
  //       console.log('orderdetails', this.orderDetails)
  //       this.orderDetails = res
  //     }, error: (err) => {
  //       console.log(err)
  //     }
  //   })
  // }

  markAsResolved(id: any) {
    this.service.resolveDispute(id).subscribe({
      next: (res) => {
        console.log(res)
        Swal.fire('Resolved', 'Dispute has been resolved', 'success')
      },
      error: (err) => {
        Swal.fire('Failed', err.error.message, 'error')
      },
      complete: () => {
        this.router.navigateByUrl('/admin/dispute_resolution')
      }
    })
  }
}
