<div class="row mt-5">
  <div class="col-md-4 mb-3">
    <div class="input-group">
      <div class="search-box">
        <input type="text" class="search-input" placeholder="Search" [(ngModel)]="search" (input)="onSearch()" />

      </div>
    </div>
  </div>

  <div class="col-md-8">
    <div class="filters-wrapper">
      <div class="filter-group">
        <div ngbDropdown class="d-inline-block w-100">
          <button type="button" class="drop-btn" id="categoryDropdown" ngbDropdownToggle>
            <span>{{ capitalizeFirstLetter(selectedCategory) || 'Filter by Category' }}</span>
            <i class="fa fa-solid fa-bars-filter"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="categoryDropdown" class="p-2">
            @for (item of listcategories; track $index) {
              <button ngbDropdownItem class="p-2" (click)="CatFilter(item.name, item.id)">{{ item.name }}</button>
            }
          </div>
        </div>
        <button class="drop-btn2">
          Bulk inventory upload
          <i class="fa fa-regular fa-file-import ms-2"></i>
        </button>
      </div>

      <div class="filter-group">
        <div ngbDropdown class="d-inline-block w-100">
          <button type="button" class="drop-btn" id="statusDropdown" ngbDropdownToggle>
            <span>{{ capitalizeFirstLetter(selectedStatus) || 'Filter by status' }}</span>
            <i class="fa fa-solid fa-bars-filter"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="statusDropdown" class="p-2">
            <button ngbDropdownItem class="p-2" (click)="statuusFilter('active')">Active</button>
            <button ngbDropdownItem class="p-2" (click)="statuusFilter('inactive')">Suspended</button>
          </div>
        </div>
        <button class="drop-btn2" (click)="exportToExcel()">
          Export inventory
          <i class="fa fa-regular fa-file-export ms-2"></i>
        </button>
      </div>

      @if (search || selectedCategory || selectedStatus) {
        <button class="clear-filter" (click)="clearFilter()">
          <i class="fa fa-regular fa-times"></i>
          Clear Filters
        </button>
      }

      <div class="ms-auto">
        <button class="btn btn-primary btn-lg spec-btn" (click)="create()">Add Inventory</button>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-12">
    <div class="table-responsive">
      @if (isLoading()) {
        <div class="text-center my-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="mt-2">Searching inventories...</p>
        </div>
      } @else {
        <table class="table align-middle border rounded">
          <thead>
            <tr>
              <th scope="col" class="py-3 ps-3"></th>
              <th scope="col" class="py-3">Name</th>
              <th scope="col" class="py-3">Category</th>
              <th scope="col" class="py-3">Quantity</th>
              <th scope="col" class="py-3">Cost Price</th>
              <th scope="col" class="py-3">Selling Price</th>
              <th scope="col" class="py-3">Status</th>
              <th scope="col" class="py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            @for(inv of inventories(); track inv.id){
            <tr>
              <td scope="row" class="ps-3" style="width: 60px;">
                <img [src]=" inv?.main_image || 'profile.png'" width="50" height="50" class="rounded " alt="">
              </td>
              <td> {{ inv?.name }} </td>
              <td> {{ inv?.category?.name}} </td>
              <td> {{ inv?.quantity }} </td>
              <td> {{ inv?.cost_price | currency: '₦'}} </td>
              <td> {{ inv?.selling_price | currency: '₦'}} </td>
              <td>
                @if (inv.disabled == false) {
                <span class="badge text-success text-bg-success py-2 px-4 rounded rounded-pill ">Active</span>
                }@else {
                <span class="badge text-secondary text-bg-secondary py-2 px-4 rounded rounded-pill ">Disabled</span>
                }

              </td>
              <td>
                <div ngbDropdown container="body" display="dynamic" class="d-inline-block">
                  <div class="cursor-pointer" id="dropdownBasic1" ngbDropdownToggle>
                   <img class="img-fluid" src="icons/action.svg" width="15" alt="">
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem class="p-3" (click)="edit(inv?.id)">
                      <i class="fa fa-light fa-pen me-3"></i>
                      Edit</button>
                    @if (inv.disabled == true) {
                    <button ngbDropdownItem class="p-3" (click)="enable(inv)"><i
                        class="fa fa-regular fa-check-circle me-3"></i>
                      enable</button>
                    }@else {
                    <button ngbDropdownItem class="p-3" (click)="enable(inv)"><i class="fa fa-regular fa-ban me-3"></i>
                      Disable</button>
                    }
                    <!-- <button ngbDropdownItem class="p-3" (click)="view(inv.logs)">
                      <i class="fa fa-regular fa-eye me-3"></i>
                      View Receipt</button> -->
                      <button ngbDropdownItem class="p-3" (click)="viewLogs(inv)">
                        <i class="fa fa-regular fa-eye me-3"></i>
                        View Stock</button>
                  </div>
                </div>
              </td>
            </tr>
            }
            @empty {
            <tr class="text-center">
              <td colspan="8">
                <div class="my-5 text-center">
                  <img src="cook.png" alt="">
                  <p class="mt-3">There are no inventories at the moment</p>
                </div>

              </td>
            </tr>
            }



          </tbody>
        </table>
      }
      @if (size > pageSize){
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="size" [(page)]="page" [pageSize]="pageSize"
        [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="refresh($event)" />
      }

    </div>

  </div>
</div>
