import { Component, inject, OnInit } from '@angular/core';
import { RolesService } from '../../_service/roles.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-roles',
  standalone: true,
  imports: [RouterModule, NgbModule, ReactiveFormsModule, FormsModule],
  templateUrl: './create-roles.component.html',
  styleUrl: './create-roles.component.scss'
})
export class CreateRolesComponent implements OnInit {
  service = inject(RolesService)
  router = inject(Router)
  permissions: any;
  name: any
  description: any
  selectedPermissions: any[] = [];


  ngOnInit(): void {
    this.getPermissions()
  }

  getPermissions() {
    this.service.getPermissions().subscribe({
      next: (res) => {
        console.log(res)
        this.permissions = res
      }, error: (err) => {
        console.log(err)
      }
    })
  }

  isChecked(permId: number): boolean {
    return this.selectedPermissions.some(item => item.id === permId);
  }

  onCheckboxChange(event: Event, permissionId: any): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      // Add permission.id to the array
      this.selectedPermissions.push(permissionId);
    } else {
      // Remove permission.id from the array if unchecked
      this.selectedPermissions = this.selectedPermissions.filter(id => id !== permissionId);
    }
    // console.log(this.selectedPermissions); // For debugging
  }

  formatPermission(permission: string): string {
    // Split the permission string by the dot and underscore
    const [context, action] = permission.split('.');

    // Replace underscores with spaces and capitalize the words
    const formattedAction = action
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    // Return the formatted string with the example suffix
    return `${formattedAction}`;
  }

  update() {
    const data = {
      name: this.name,
      description: this.description,
      permissions: this.selectedPermissions

    }

    console.log(data)

    this.service.storeRoles(data).subscribe({
      next: (res) => {
        Swal.fire('Success', 'Role Created successfully')
      }, error: (err) => {
        Swal.fire('Failed', err.error.message, 'error')
      },
      complete: () => {
        this.router.navigateByUrl('/admin/roles_and_permissions')
        // this.getRoles()
        // this.modal.dismissAll()
      }
    })
  }

}
