import { Component, inject, OnInit } from '@angular/core';
import { UserService } from '../../_service/user.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-waiter-details',
  standalone: true,
  imports: [RouterModule,NgbModule, CommonModule],
  templateUrl: './waiter-details.component.html',
  styleUrl: './waiter-details.component.scss'
})
export class WaiterDetailsComponent implements OnInit {
  service = inject(UserService)
  id: any
  tnxHistory: any
  summary: any;
  page = 1
  size = 0
  pageSize = 1
  constructor(route: ActivatedRoute) {
    this.id = route.snapshot.paramMap.get('id')
  }
  ngOnInit(): void {
    this.waiterSummary()
    this.getTransactionHistory()
  }
  refresh(event: any) {
    this.page = event
    this.getTransactionHistory()
  }
  getTransactionHistory() {
    this.service.transactionHistory(this.id, this.page).subscribe({
      next: (res: any) => {
        this.tnxHistory = res.data
        const meta = res.meta
        this.size = meta.total
        this.pageSize = meta.per_page
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  waiterSummary() {
    this.service.waiterSummary(this.id).subscribe({
      next: (res: any) => {
        this.summary = res
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

}
