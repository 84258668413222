import { Component, inject } from '@angular/core';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from '../_service/inventory.service';
import { CommonModule } from '@angular/common';

interface User {
  id: number;
  name: string;
  email: string;
  image: string | null;
  phone: string | null;
  created_at: string;
  updated_at: string;
  first_name: string | null;
  last_name: string | null;
  disabled: boolean;
  status: string;
}

interface ActivityLog {
  log: string;
  performed_by: User | null;
  created_at: string;
  human_readable_date: string;
}

interface PaginatedResponse {
  data: ActivityLog[];
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}

@Component({
  selector: 'app-audit-log',
  standalone: true,
  imports: [NgbModule, CommonModule, NgbPaginationModule],
  templateUrl: './audit-log.component.html',
  styleUrl: './audit-log.component.scss'
})
export class AuditLogComponent {
  private inventoryService = inject(InventoryService);

  activityLogs: ActivityLog[] = [];
  currentPage = 1;
  totalItems = 0;
  pageSize = 20;
  loading = false;

  ngOnInit(): void {
    this.viewActivityLogs();
  }

  viewActivityLogs(page: number = 1) {
    this.loading = true;
    this.inventoryService.activityLogs(page).subscribe({
      next: (response: any) => {
        this.activityLogs = response.data;
        this.totalItems = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = response.meta.per_page;
      },
      error: (err) => {
        console.error('Error fetching activity logs:', err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  onPageChange(page: number): void {
    this.viewActivityLogs(page);
  }
}
