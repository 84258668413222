<div class="mt-5">
  <ul class="list-group list-group-flush">
    @for (item of notifications; track $index) {
    <li class="list-group-item" (click)="open(item.id)">
      <div class="d-flex">
        <div class="d-block">
          <h6> {{ item?.data?.title }} </h6>
          <p class="mb-0">{{ item?.data?.details }}.</p>
        </div>
        <!-- <div class="ms-auto">
          <p class="text-muted mb-0">1 min ago</p>
        </div> -->
      </div>
    </li>
    }@empty {
    <p>No data to display</p>
    }

    <!-- <li class="list-group-item">
      <div class="d-flex">
        <div class="d-block">
          <h6>Notification</h6>
          <p class="mb-0">Lorem ipsum dolor sit amet consectetur. Risus mauris faucibus aliquet tempor mi scelerisque
            est duis molestie.</p>
        </div>
        <div class="ms-auto">
          <p class="text-muted mb-0">2 mins ago</p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="d-flex">
        <div class="d-block">
          <h6>Notification</h6>
          <p class="mb-0">Lorem ipsum dolor sit amet consectetur. Risus mauris faucibus aliquet tempor mi scelerisque
            est duis molestie.</p>
        </div>
        <div class="ms-auto">
          <p class="text-muted mb-0">1 min ago</p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="d-flex">
        <div class="d-block">
          <h6>Notification</h6>
          <p class="mb-0">Lorem ipsum dolor sit amet consectetur. Risus mauris faucibus aliquet tempor mi scelerisque
            est duis molestie.</p>
        </div>
        <div class="ms-auto">
          <p class="text-muted mb-0">1 min ago</p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="d-flex">
        <div class="d-block">
          <h6>Notification</h6>
          <p class="mb-0">Lorem ipsum dolor sit amet consectetur. Risus mauris faucibus aliquet tempor mi scelerisque
            est duis molestie.</p>
        </div>
        <div class="ms-auto">
          <p class="text-muted mb-0">1 min ago</p>
        </div>
      </div>
    </li> -->
  </ul>
</div>
