import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable({
  providedIn: 'root'
})
export class DisputesService {
  http = inject(HttpClient);
  baseUrl = environment.baseApi
  loadingBar = inject(LoadingBarService)

  constructor() { }

  listDisputes() {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/disputes`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  listSingleDisputes(id: any) {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/disputes/show/${id}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  showOrders(id: any) {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/orders/show/${id}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  resolveDispute(id: any) {
    this.loadingBar.start();
    return this.http.get(`${this.baseUrl}/disputes/resolve/${id}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
}
