import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { finalize } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  http = inject(HttpClient);
  auth = inject(AuthService)
  baseUrl = environment.baseApi
  private authLocalStorageToken = `user`;
  loadingBar = inject(LoadingBarService)
  user = this.auth.getUser(this.authLocalStorageToken)
  // getUsers(page?: any, search?: any, filter?: any) {
  //   return this.http.get(`${this.baseUrl}/users?page=${page}&search=${search}&filter=${filter}`).pipe()
  // }
  getUsers(page?: any, search?: any, filter?: any, status?: any) {
    // Initialize query parameters as an array
    let queryParams: string[] = [];

    // Conditionally add parameters only if they are provided (not null or undefined)
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (search) {
      queryParams.push(`search=${search}`);
    }
    if (filter) {
      queryParams.push(`role=${filter}`);
    }
    if (status) {
      queryParams.push(`status=${status}`);
    }

    // Join the parameters with '&' to form the final query string
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    // Make the HTTP GET request with the query string
    return this.http.get(`${this.baseUrl}/users${queryString}`).pipe();
  }

  updateStatus(id: any,) {
    return this.http.get(`${this.baseUrl}/user/update-status/${id}`)
  }

  // updateStatus(id: any,) {
  //   return this.http.get(`${this.baseUrl}/user/update-status/${id}`)
  // }

  getWaiters(page?: any, search?: any, filter?: any, status?: any) {
    // Initialize query parameters as an array
    let queryParams: string[] = [];

    // Conditionally add parameters only if they are provided (not null or undefined)
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (search) {
      queryParams.push(`search=${search}`);
    }
    if (filter) {
      queryParams.push(`filter=${filter}`);
    }
    if (status) {
      queryParams.push(`status=${status}`);
    }

    // Join the parameters with '&' to form the final query string
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    // Make the HTTP GET request with the query string
    return this.http.get(`${this.baseUrl}/waiter-management/all-waiters${queryString}`).pipe();
  }

  // getWaiters(page?: any) {
  //   return this.http.get(`${this.baseUrl}/waiter-management/all-waiters?page=${page}`).pipe()
  // }

  getUserById(id: any) {
    return this.http.get(`${this.baseUrl}/users/show/${id}`).pipe()
  }
  getBusinessDetails() {
    return this.http.get(`${this.baseUrl}/settings`).pipe()
  }
  uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(`${this.baseUrl}/upload-image`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      // finalize(() => this.reset())
    );
  }

  getRoles() {
    return this.http.get(`${this.baseUrl}/rbac/roles`).pipe()
  }
  createUSer(data: any) {
    return this.http.post(`${this.baseUrl}/users/store`, data).pipe()
  }
  updateUser(data: any, id: any) {
    return this.http.patch(`${this.baseUrl}/users/update/${id}`, data)
  }
  storeSettings(data: any) {
    return this.http.patch(`${this.baseUrl}/settings/update`, data)
  }

  transactionHistory(id: any, page: number) {
    this.loadingBar.start()
    return this.http.get(`${this.baseUrl}/waiter-management/history/${id}?page=${page}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  waiterSummary(id: any) {
    this.loadingBar.start()
    return this.http.get(`${this.baseUrl}/waiter-management/summary/${id}`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

}
