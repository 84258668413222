<div class="row mt-5">
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label">Name</label>
      <input type="text" class="form-control" placeholder="Role Name" [(ngModel)]="name" name="name">
    </div>
    <div class="form-group">
      <label for="" class="form-label">Description</label>
      <input type="text" class="form-control" name="description" [(ngModel)]="description">
    </div>
    <div class="form-group">
      <label for="" class="form-label">Select Permissions</label>
      <div class="card">
        <div class="card-body px-5 py-4">
          @for (perm of permissions; track perm.id) {
          <div class="d-flex align-items-center mb-3">
            <p class="mb-0 me-3"> {{formatPermission( perm?.name) }} </p>
            <div class="form-check form-switch ms-auto">
              <input class="form-check-input" type="checkbox" role="switch" [id]="perm?.name"
                [checked]="isChecked(perm?.id)" (change)="onCheckboxChange($event, perm?.name)">
            </div>
          </div>
          }
        </div>
      </div>
    </div>

    <div class="mt-4">
      <button type="button" class="btn btn-primary btn-lg" (click)="update()">Save</button>
    </div>
  </div>
</div>
