<!-- sales.component.html -->
<div class="container-fluid p-4">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Sales Summary</a>
      <ng-template ngbNavContent>
        <!-- Sales Summary Section -->
        <div class="row mt-4">
          <div class="col-md-4 mb-3" *ngFor="let summary of summaryCards">
            <div class="balance-card py-3 px-4">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <p class="balance-text mb-2">{{summary.title}}</p>
                  <h3 class="balance-amount mb-0" *ngIf="summary.title !== 'Total Sales'; else totalSales">
                    ₦{{summary.value | number}}
                  </h3>
                  <ng-template #totalSales>
                    <h3 class="balance-amount mb-0">{{summary.value | number}}</h3>
                  </ng-template>
                </div>
                <div ngbDropdown class="dropdown">
                  <button class="btn btn-link" type="button" ngbDropdownToggle>
                    <i class="fa fa-solid fa-ellipsis-vertical"></i>
                  </button>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem>Today</button>
                    <button ngbDropdownItem>This Week</button>
                    <button ngbDropdownItem>This Month</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Analytics Section -->
        <div class="row mt-4">
          <!-- Top Selling Items -->
          <div class="col-md-4">
            <div class="card">
              <div class="card-header bg-white d-flex justify-content-between align-items-center">
                <h6 class="mb-0">Top Selling Items</h6>
                <div ngbDropdown>
                  <button class="btn btn-link" type="button" ngbDropdownToggle>Today</button>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem>This Week</button>
                    <button ngbDropdownItem>This Month</button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div *ngFor="let item of topSellingItems" class="mb-3">
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <span>{{item.name}}</span>
                    <span>{{item.item_count}}</span>
                  </div>
                  <div class="progress" style="height: 6px;">
                    <div class="progress-bar bg-primary" [style.width.%]="(item.item_count / maxTopSellingValue) * 100">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Top Categories -->
          <div class="col-md-4">
            <div class="card">
              <div class="card-header bg-white d-flex justify-content-between align-items-center">
                <h6 class="mb-0">Top Categories</h6>
                <div ngbDropdown>
                  <button class="btn btn-link" type="button" ngbDropdownToggle>Today</button>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem>This Week</button>
                    <button ngbDropdownItem>This Month</button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div *ngFor="let category of topCategories" class="mb-3">
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <span>{{category.name}}</span>
                    <span>{{category.category_count}}</span>
                  </div>
                  <div class="progress" style="height: 6px;">
                    <div class="progress-bar bg-primary"
                      [style.width.%]="(category.category_count / maxTopCategoryValue) * 100">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Payment Methods -->
          <div class="col-md-4">
            <div class="card">
              <div class="card-header bg-white d-flex justify-content-between align-items-center">
                <h6 class="mb-0">Payment Methods</h6>
                <div ngbDropdown>
                  <button class="btn btn-link" type="button" ngbDropdownToggle>Today</button>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem>This Week</button>
                    <button ngbDropdownItem>This Month</button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="pie-chart-container">
                  <div class="chart-wrapper">
                    <canvas baseChart #paymentMethodsChart [data]="pieChartData" [type]="pieChartType"
                      [options]="pieChartOptions">
                    </canvas>
                  </div>
                  <div class="chart-legend mt-4">
                    <div class="d-flex flex-column gap-2">
                      <div *ngFor="let method of paymentMethods" class="d-flex align-items-center">
                        <span class="legend-dot"
                          [style.backgroundColor]="paymentMethodColors[method.payment_method]"></span>
                        <span class="ms-2">{{formatPaymentMethodName(method.payment_method)}}
                          ({{method.percentage}})</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Employee Sales Table -->
        <div class="card mt-4">
          <div class="card-header bg-white">
            <h6 class="mb-0">Employee Sales</h6>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Waiter</th>
                    <th>Total Orders Handled</th>
                    <th>Total Sales Amount (₦)</th>
                    <th>Average Order Value</th>
                    <th>Rating</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let employee of employeeSales">
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar me-2"
                          [style.backgroundImage]="employee.waiter_image ? 'url(' + employee.waiter_image + ')' : ''"
                          [style.backgroundColor]="!employee.waiter_image ? '#f2f4f7' : ''">
                          @if (!employee.waiter_image) {
                          <span class="avatar-initials">{{employee.waiter_name.charAt(0)}}</span>
                          }
                        </div>
                        {{employee.waiter_name}}
                      </div>
                    </td>
                    <td>{{employee.total_orders_handled}}</td>
                    <td>{{employee.total_sales_amount | currency:'₦'}}</td>
                    <td>{{employee.average_order_value || 0 | currency:'₦'}}</td>
                    <td>
                      @if (employee.average_customer_rating) {
                      {{employee.average_customer_rating}}
                      } @else {
                      <span class="text-muted">No ratings</span>
                      }
                    </td>
                  </tr>
                  @if (employeeSales.length === 0) {
                  <tr>
                    <td colspan="5" class="text-center py-4">
                      No employee sales data available
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
            @if (size > pageSize) {
            <div class="mt-3">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="size" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                (pageChange)="refresh($event)">
              </ngb-pagination>
            </div>
            }
          </div>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="2">
      <a ngbNavLink>Sales History</a>
      <ng-template ngbNavContent>
        <!-- Sales History Section -->
<div class="mt-4">
  <div class="row align-items-center mb-4">
    <div class="col-md-4">
      <div class="search-box">
        <input type="text" class="form-control"
               placeholder="Search"
               [(ngModel)]="searchTerm"
               (input)="onSearch()">
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex gap-3">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-secondary" ngbDropdownToggle>
            <i class="fa fa-regular fa-calendar me-2"></i>
            {{dateRange.from ? formatDisplayDate(dateRange.from) : 'Start Date'}} -
            {{dateRange.to ? formatDisplayDate(dateRange.to) : 'End Date'}}
          </button>
          <div ngbDropdownMenu class="p-3" style="min-width: 300px;">
            <div class="mb-2">
              <div class="btn-group w-100">
                <button class="btn btn-outline-secondary btn-sm" (click)="setDateRange('today')">Today</button>
                <button class="btn btn-outline-secondary btn-sm" (click)="setDateRange('week')">This Week</button>
                <button class="btn btn-outline-secondary btn-sm" (click)="setDateRange('month')">This Month</button>
              </div>
            </div>
            <div class="custom-date-picker">
              <ngb-datepicker
                #dp="ngbDatepicker"
                [displayMonths]="2"
                [dayTemplate]="t"
                [markDisabled]="markDisabled"
                (dateSelect)="onDateSelection($event)"
                [showWeekNumbers]="false">
              </ngb-datepicker>
              <ng-template #t let-date let-focused="focused">
                <span class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
            <div class="mt-2 text-end">
              <button class="btn btn-primary btn-sm" (click)="applyDateFilter()">Apply</button>
            </div>
          </div>
        </div>

        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-secondary" ngbDropdownToggle>
            <i class="fa fa-regular fa-credit-card me-2"></i>
            {{selectedPaymentMethod || 'All Payment Methods'}}
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="onPaymentMethodFilter('')">All Methods</button>
            <button ngbDropdownItem (click)="onPaymentMethodFilter('cash')">Cash</button>
            <button ngbDropdownItem (click)="onPaymentMethodFilter('pos')">Card (POS)</button>
            <button ngbDropdownItem (click)="onPaymentMethodFilter('transfer')">Transfer</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 text-end">
      <button class="btn btn-secondary" (click)="exportToExcel()">
        <i class="fa fa-regular fa-file-export me-2"></i> Export Report
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table border">
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Amount</th>
          <th>Payment Method</th>
          <th>Order Status</th>
          <th>Transaction Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredsalesHistory">
          <td>{{item.order_uid}}</td>
          <td>₦{{item.order_amount | number}}</td>
          <td>
            <span *ngFor="let method of item.payment_method" class="badge bg-light text-dark me-1">
              {{method | titlecase}}
            </span>
          </td>
          <td>
            <span [class]="'badge ' + getStatusClass(item.status)">
              {{item.status | titlecase}}
            </span>
          </td>
          <td>{{item.transaction_date | date:'medium'}}</td>
          <td>
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-link" ngbDropdownToggle>
                <i class="fa fa-solid fa-ellipsis"></i>
              </button>
              <!-- <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="invItem(content, item)">
                  <i class="fa fa-regular fa-memo me-2"></i> Inventory Items
                </button>
                <button ngbDropdownItem (click)="customerInfo(content2, item)">
                  <i class="fa fa-light fa-user me-2"></i> Customer Information
                </button>
                <button ngbDropdownItem (click)="waiterInfo(content3, item)">
                  <i class="fa fa-light fa-user me-2"></i> Waiter Information
                </button>
              </div> -->
            </div>
          </td>
        </tr>
        <tr *ngIf="filteredsalesHistory.length === 0">
          <td colspan="6" class="text-center py-4">
            No sales history to display
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  @if (historyTotal > historyPageSize) {
    <div class="mt-3">
      <ngb-pagination
        class="d-flex justify-content-end"
        [collectionSize]="historyTotal"
        [(page)]="historyPage"
        [pageSize]="historyPageSize"
        [maxSize]="5"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
        (pageChange)="refreshHistory($event)">
      </ngb-pagination>
    </div>
  }
</div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<!-- Modals -->
<ng-template #content let-modal>
  <div class="modal-body">
    <div class="d-flex align-items-center">
      <button class="btn btn-clear" (click)="modal.close()">
        <i class="fa fa-regular fa-arrow-left"></i>
      </button>
      <div class="ms-auto me-auto">
        <h3>Inventory Items</h3>
        <p>Order No: {{selectedOrder?.order_id}}</p>
      </div>
    </div>
    <div class="mt-3">
      <!-- Inventory items content -->
    </div>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-body">
    <div class="d-flex align-items-center">
      <button class="btn btn-clear" (click)="modal.close()">
        <i class="fa fa-regular fa-arrow-left"></i>
      </button>
      <div class="ms-auto me-auto">
        <h3>Customer Information</h3>
        <p>Order No: {{selectedOrder?.order_id}}</p>
      </div>
    </div>
    <div class="mt-3">
      <!-- Customer info content -->
    </div>
  </div>
</ng-template>

<ng-template #content3 let-modal>
  <div class="modal-body">
    <div class="d-flex align-items-center">
      <button class="btn btn-clear" (click)="modal.close()">
        <i class="fa fa-regular fa-arrow-left"></i>
      </button>
      <div class="ms-auto me-auto">
        <h3>Waiter Information</h3>
        <p>Order No: {{selectedOrder?.order_id}}</p>
      </div>
    </div>
    <div class="mt-3">
      <!-- Waiter info content -->
    </div>
  </div>
</ng-template>
