import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SettingsService } from '../../_service/settings.service';
@Component({
  selector: 'app-view-notification',
  standalone: true,
  imports: [],
  templateUrl: './view-notification.component.html',
  styleUrl: './view-notification.component.scss'
})
export class ViewNotificationComponent implements OnInit {
  router = inject(Router)
  service = inject(SettingsService)
  notification: any;
  id: any
  constructor(private route: ActivatedRoute) {
    this.id = route.snapshot.paramMap.get('id')
  }
  ngOnInit(): void {
    this.getNotifications()
  }

  getNotifications() {
    this.service.viewNotification(this.id).subscribe({
      next: (res: any) => {
        console.log(res)
        this.notification = res[0]?.data
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

}
