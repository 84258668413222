<div class="row mt-5 mb-5">
  <div class="col-md-6">
    <div class="form-area">
      <form [formGroup]="restockForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="" class="form-label">Inventory Item</label>
          <select name="inventory_id" formControlName="inventory_id" class="form-select form-control">
            @for (item of inventories; track $index) {
            <option [value]="item.id">{{item.name}}</option>
            }
          </select>
        </div>
        <div class="form-group">
          <label for="" class="form-label">Expiry Date</label>
          <input type="date" name="expiry_date" formControlName="expiry_date" class="form-control">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Quantity </label>
          <input type="number" name="quantity" min="1" formControlName="quantity" class="form-control">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Receipt Number </label>
          <input type="text" name="receipt_number" formControlName="receipt_number" class="form-control">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Cost Price</label>
          <input type="number" name="cost_price" min="1" formControlName="cost_price" class="form-control">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Selling Price</label>
          <input type="number" name="selling_price" min="1" formControlName="selling_price" class="form-control">
        </div>
        <div class="receipt-area d-block d-md-none">
          <div class="form-area">
            <label for="" class="form-label">Upload Receipt</label>
          </div>
          <div class="card">
            <div class="card-body py-4">

              @if(uploadProgress2()){
              <ngb-progressbar class="mb-3" [value]="uploadProgress2()" type="primary" />

              }

              <div class="d-block d-sm-flex">
                <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
                  (drop)="onFileDrop2($event)" (dragover)="onDragOver2($event)">
                  @if(showFile() ){
                  <img src="file_filled.png" alt="Image Preview" width="100" class="image-preview" />
                  }@else{
                  <img src="file.png" width="100" alt="">
                  }

                  <!-- <img src="file.png" alt=""> -->


                  <input type="file" accept=".doc, .docx, .pdf, .txt, .png,.jpg,.jpeg" (change)="onFileChange2($event)" hidden #fileInput2 />
                </div>
                @if(fileName()){
                <div class="d-block ms-3">
                  <p class="fw-bold image-upload-title">Upload Receipt</p>
                  <p class="image-upload-label">
                    <span class="image-name">{{ fileName() }} - ({{fileSize()}} KB)</span>
                  </p>
                  <div class="mt-5">
                    <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                      (click)="fileInput2.click()">Select
                      Receipt</button>
                    @if(selectedFile || imagePreview!== null ){
                    <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeFile()">Remove Receipt</button>
                    }
                  </div>

                </div>
                }
                @else{
                <div class="d-block ms-3">
                  <p class="fw-bold image-upload-title">Upload Receipt</p>
                  <p class="image-upload-label">
                    Drag and drop file or click the “select Receipt" to choose receipt to upload
                  </p>
                  <div>
                    <button class="btn btn-outline-secondary btn-lg me-3 mb-3 spec-btn"
                      (click)="fileInput2.click()">Select
                      Receipt</button>
                    @if(selectedFile || imagePreview!== null ){
                    <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeFile()">Remove Receipt</button>
                    }
                  </div>

                </div>
                }

              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <button
            class="btn btn-lg btn-primary px-5 spec-btn"
            type="submit"
            [disabled]="restockForm.invalid || isLoading"
          >
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            {{ isLoading ? 'Saving...' : 'Save Stock' }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-6 d-none d-md-block">
    <div class="receipt-area">
      <div class="form-area">
        <label for="" class="form-label">Upload Receipt</label>
      </div>
      <div class="card">
        <div class="card-body py-4">

          @if(uploadProgress2()){
          <ngb-progressbar class="mb-3" [value]="uploadProgress2()" type="primary" />

          }

          <div class="d-block d-sm-flex">
            <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
              (drop)="onFileDrop2($event)" (dragover)="onDragOver2($event)">
              @if(showFile() ){
              <img src="file_filled.png" alt="Image Preview" width="100" class="image-preview" />
              }@else{
              <img src="file.png" width="100" alt="">
              }

              <!-- <img src="file.png" alt=""> -->


              <input type="file" accept=".doc, .docx, .pdf, .txt, .png,.jpg,.jpeg" (change)="onFileChange2($event)" hidden #fileInput2 />
            </div>
            @if(fileName()){
            <div class="d-block ms-3">
              <p class="fw-bold image-upload-title">Upload Receipt</p>
              <p class="image-upload-label">
                <span class="image-name">{{ fileName() }} - ({{fileSize()}} KB)</span>
              </p>
              <div class="mt-5">
                <button class="btn btn-outline-secondary btn-lg me-3 mb-2 mb-sm-0 spec-btn"
                  (click)="fileInput2.click()">Select
                  Receipt</button>
                @if(selectedFile || imagePreview!== null ){
                <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeFile()">Remove Receipt</button>
                }
              </div>

            </div>
            }
            @else{
            <div class="d-block ms-3">
              <p class="fw-bold image-upload-title">Upload Receipt</p>
              <p class="image-upload-label">
                Drag and drop file or click the “select Receipt" to choose receipt to upload
              </p>
              <div>
                <button class="btn btn-outline-secondary btn-lg me-3 mb-3 spec-btn"
                  (click)="fileInput2.click()">Select
                  Receipt</button>
                @if(selectedFile || imagePreview!== null ){
                <button class="btn btn-outline-danger btn-lg spec-btn" (click)="removeFile()">Remove Receipt</button>
                }
              </div>

            </div>
            }

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
