<div class="container-fluid mt-5">
  <!-- Header Section -->
  <div class="d-flex justify-content-between align-items-center mb-4">
    <div class="d-flex align-items-center">
      <div class="">
        <div class="search-input-wrapper">
          <input type="text"
                 class="search-input"
                 [(ngModel)]="searchTerm"
                 (input)="onSearch()"
                 placeholder="Search categories...">
          <i class="fas fa-search search-icon"></i>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" (click)="create(content)">
      <i class="fas fa-plus me-2"></i>
      Add Category
    </button>
  </div>

  <!-- Alert Messages -->
  @if (successMessage) {
    <ngb-alert #selfClosingAlert [type]="type" (closed)="successMessage = ''">
      {{ message }}
    </ngb-alert>
  }

  <!-- Table Section -->
  <div class="card">
    <div class="table-responsive">
      <table class="table table-hover align-middle mb-0">
        <thead>
          <tr>
            <th class="ps-3">Image</th>
            <th>Name</th>
            <th>Status</th>
            <th class="text-end pe-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          @for(category of filteredCategories; track category.id) {
            <tr>
              <td class="ps-3">
                <img [src]="category.image || 'https://placehold.co/50x50'"
                     class="rounded-circle"
                     width="40"
                     height="40"
                     [alt]="category.name">
              </td>
              <td>{{ category.name }}</td>
              <td>
                <span [class]="category.disabled ? 'badge bg-danger' : 'badge bg-success'">
                  {{ category.disabled ? 'Disabled' : 'Active' }}
                </span>
              </td>
              <td class="text-end pe-3">
                <button class="btn btn-light btn-sm me-2" (click)="edit(content2, category)">
                  <i class="fas fa-edit me-1"></i>
                  Edit
                </button>
                <button class="btn btn-light btn-sm" (click)="disable(category)">
                  @if(category.disabled) {
                    <i class="fas fa-check me-1"></i>
                    Enable
                  } @else {
                    <i class="fas fa-ban me-1"></i>
                    Disable
                  }
                </button>
              </td>
            </tr>
          } @empty {
            <tr>
              <td colspan="4" class="text-center py-4">
                <img src="cook.svg" width="200" class="mb-3" alt="No data">
                <p class="text-muted">No categories found</p>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Create Category Modal -->
<ng-template #content let-modal>
  <div class="modal-body p-4">
    <div class="d-flex align-items-center mb-3">
      <button class="btn btn-clear" (click)="modal.close('close-click')">
        <i class="fa fa-solid fa-arrow-left fa-lg"></i>
      </button>
      <div class="ms-auto me-auto">
        <h3 class="mb-0">Create new category</h3>
      </div>
    </div>
    @if (successMessage) {
      <ngb-alert #selfClosingAlert [type]="type" (closed)="successMessage = ''">
        {{ message }}
      </ngb-alert>
    }
    <div class="form-area">
      <form [formGroup]="createCatForm">
        <div class="form-group">
          <label for="name" class="form-label">Name</label>
          <input type="text" name="name" id="name" formControlName="name" class="form-control" placeholder="Category Name">
        </div>
      </form>
      <div class="form-group">
        <label for="" class="form-label">Thumbnail</label>
        <div class="card">
          <div class="card-body py-4">
            @if(uploadProgress()) {
              <ngb-progressbar class="mb-3" [value]="uploadProgress()" type="primary" />
            }

            <div class="d-block d-sm-flex">
              <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
                (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
                @if(imagePreview()) {
                  <img [src]="imagePreview()" alt="Image Preview" width="100" class="image-preview" />
                } @else {
                  <img src="icons/demo-image.svg" width="100" alt="">
                }
                <input type="file" accept=".jpg, .jpeg, .png" (change)="onFileChange($event)" hidden #fileInput />
              </div>
              @if(imageName()) {
                <div class="d-block ms-3">
                  <p class="fw-bold image-upload-title">Upload Photo</p>
                  <p class="image-upload-label">
                    <span class="image-name">{{ imageName() }} - ({{fileSize()}} KB)</span>
                  </p>
                  <div>
                    <button class="btn btn-outline-secondary btn-sm me-3 mb-2 mb-sm-0" (click)="fileInput.click()">
                      Select Photo
                    </button>
                    @if(selectedFile || imagePreview() !== null) {
                      <button class="btn btn-outline-danger btn-sm" (click)="removeImage()">Remove</button>
                    }
                  </div>
                </div>
              } @else {
                <div class="d-block ms-3">
                  <p class="fw-bold image-upload-title">Upload Photo</p>
                  <p class="image-upload-label">
                    Drag and drop photo or click the "select photo" to choose photo to upload
                  </p>
                  <div>
                    <button class="btn btn-outline-secondary btn-sm me-3 mb-2 mb-sm-0" (click)="fileInput.click()">
                      Select Photo
                    </button>
                    @if(selectedFile || imagePreview() !== null) {
                      <button class="btn btn-outline-danger btn-sm" (click)="removeImage()">Remove</button>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 text-center">
      <button class="btn btn-primary" (click)="OnSubmit()">Save New Category</button>
    </div>
  </div>
</ng-template>

<!-- Edit Category Modal -->
<ng-template #content2 let-modal>
  <div class="modal-body p-4">
    <div class="d-flex align-items-center mb-3">
      <button class="btn btn-clear" (click)="modal.close('close-click')">
        <i class="fa fa-solid fa-arrow-left fa-lg"></i>
      </button>
      <div class="ms-auto me-auto">
        <h3 class="mb-0">Edit {{ data.name }}</h3>
      </div>
    </div>
    @if (successMessage) {
      <ngb-alert #selfClosingAlert [type]="type" (closed)="successMessage = ''">
        {{ message }}
      </ngb-alert>
    }
    <div class="form-area">
      <form [formGroup]="updateCatForm">
        <div class="form-group">
          <label for="name" class="form-label">Name</label>
          <input type="text" name="name" id="name" formControlName="name" class="form-control" placeholder="Category Name">
        </div>
      </form>
      <div class="form-group">
        <label for="" class="form-label">Thumbnail</label>
        <div class="card">
          <div class="card-body py-4">
            @if(uploadProgress()) {
              <ngb-progressbar class="mb-3" [value]="uploadProgress()" type="primary" />
            }

            <div class="d-block d-sm-flex">
              <div class="file-upload-wrapper" [class.success]="uploadSuccess" [class.error]="uploadError"
                (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
                @if(imagePreview() || data.image) {
                  <img [src]="imagePreview() || data.image" alt="Image Preview" width="100" class="image-preview" />
                } @else {
                  <img src="icons/demo-image.svg" width="100" alt="">
                }
                <input type="file" accept=".jpg, .jpeg, .png" (change)="onFileChange($event)" hidden #fileInput />
              </div>
              @if(imageName()) {
                <div class="d-block ms-3">
                  <p class="fw-bold image-upload-title">Upload Photo</p>
                  <p class="image-upload-label">
                    <span class="image-name">{{ imageName() }} - ({{fileSize()}} KB)</span>
                  </p>
                  <div>
                    <button class="btn btn-outline-secondary btn-sm me-3 mb-2 mb-sm-0" (click)="fileInput.click()">
                      Select Photo
                    </button>
                    @if(selectedFile || imagePreview() !== null) {
                      <button class="btn btn-outline-danger btn-sm" (click)="removeImage()">Remove</button>
                    }
                  </div>
                </div>
              } @else {
                <div class="d-block ms-3">
                  <p class="fw-bold image-upload-title">Upload Photo</p>
                  <p class="image-upload-label">
                    Drag and drop photo or click the "select photo" to choose photo to upload
                  </p>
                  <div>
                    <button class="btn btn-outline-secondary btn-sm me-3 mb-2 mb-sm-0" (click)="fileInput.click()">
                      Select Photo
                    </button>
                    @if(selectedFile || imagePreview() !== null) {
                      <button class="btn btn-outline-danger btn-sm" (click)="removeImage()">Remove Photo</button>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button class="btn btn-primary" (click)="OnEdit(data)">Update Category</button>
    </div>
  </div>
</ng-template>

