// sales.component.ts
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportingService } from '../_service/reporting.service';
import { FormsModule } from '@angular/forms';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration, ChartData, ChartType, registerables } from 'chart.js';

interface SalesSummary {
  total_sales: any;
  total_deductions: any;
  total_profit: any;
}

interface TopSellingItem {
  name: string;
  item_count: number;
}

interface TopSellingCategory {
  name: string;
  category_count: number;
  inventory: any;
}

interface PaymentMethodSummary {
  payment_method: string;
  count: number;
  percentage: string;
}

interface TopSummaryResponse {
  top_selling_items: TopSellingItem[];
  top_selling_categories: TopSellingCategory[];
  payment_methods_summary: PaymentMethodSummary[];
}

interface EmployeeSales {
  name: string;
  orders: number;
  amount: number;
  avatar?: string;
}

export interface WaiterPerformance {
  waiter_name: string;
  waiter_image: string | null;
  total_orders_handled: number;
  total_sales_amount: string | number;
  average_order_value: string | null;
  average_customer_rating: number | null;
}

export interface PaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  links: Array<{
    url: string | null;
    label: string;
    active: boolean;
  }>;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: PaginationMeta;
}
export interface SalesHistory {
  order_id: number;
  order_uid: string;
  order_amount: string;
  payment_method: string[];
  status: string;
  transaction_date: string;
}

export interface SalesHistoryParams {
  page?: number;
  start_date?: string;
  end_date?: string;
  payment_method?: string;
  waiter_id?: string;
}
@Component({
  selector: 'app-sales',
  standalone: true,
  imports: [NgbModule, RouterModule, CommonModule, FormsModule, BaseChartDirective],
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit, AfterViewInit {
  private reportingSrvs = inject(ReportingService);
  private modal = inject(NgbModal);
  active = 1;
  employeeSales: WaiterPerformance[] = [];
  page = 1;
  pageSize = 15;
  size = 0;
  salesHistory: SalesHistory[] = [];
  filteredsalesHistory: SalesHistory[] = [];
  historyPage = 1;
  historyPageSize = 15;
  historyTotal = 0;
  searchTerm: string = '';
  dateRange: { from: NgbDate | null; to: NgbDate | null } = {
    from: null,
    to: null
  };
  hoveredDate: NgbDate | null = null;
  selectedPaymentMethod: string = '';
  selectedWaiter: string = '';
  showDatePicker = false;
  // Properties
  totalSales: SalesSummary = {
    total_sales: 0,
    total_deductions: 0,
    total_profit: 0
  };

  summaryCards = [
    { title: 'Total Sales', value: 0 },
    { title: 'Total Deductions', value: 0 },
    { title: 'Total Profit', value: 0 }
  ];

  // Updated to match API response types
  topSellingItems: TopSellingItem[] = [];
  topCategories: TopSellingCategory[] = [];
  paymentMethods: PaymentMethodSummary[] = [];
  paymentMethodColors: { [key: string]: string } = {
    pos: '#F2994A',
    cash: '#53B1FD',
    transfer: '#7F56D9',
    wallet: '#7F56D9'
  };

  selectedOrder: any = null;

  // Pie Chart Configuration
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  public pieChartData: ChartData<'pie', number[], string> = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: []
    }]
  };

  public pieChartType: ChartType = 'pie';

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  // Computed properties for progress bars
  get maxTopSellingValue(): number {
    return Math.max(...this.topSellingItems.map(item => item.item_count));
  }

  get maxTopCategoryValue(): number {
    return Math.max(...this.topCategories.map(category => category.category_count));
  }

  constructor(private calendar: NgbCalendar) {
    Chart.register(...registerables);
    Chart.defaults.color = '#475467';
    Chart.defaults.font.family = 'Inter, sans-serif';

    // Initialize with current date range
    const today = this.calendar.getToday();
    this.dateRange = {
      from: this.calendar.getPrev(today, 'd', 7),
      to: today
    };
  }

  ngOnInit(): void {
    this.getSalesHistory();
    this.getTopSummary();
    this.getWaiterPerformance();
    this.getSalesSummary();
  }

  ngAfterViewInit() {
    this.updatePaymentMethodsChart();
  }
  getWaiterPerformance(page: number = 1) {
    this.reportingSrvs.getWaiterPerformance(page).subscribe({
      next: (response) => {
        this.employeeSales = response.data;
        this.size = response.meta.total;
        this.page = response.meta.current_page;
      },
      error: (error) => {
        console.error('Error fetching waiter performance:', error);
      }
    });
  }

  refresh(page: number) {
    this.getWaiterPerformance(page);
  }
  getTopSummary() {
    this.reportingSrvs.getTopSummary().subscribe({
      next: (response: any) => {
        this.topSellingItems = response.top_selling_items;
        this.topCategories = response.top_selling_categories;
        this.updatePaymentMethodsData(response.payment_methods_summary);
      },
      error: (error) => {
        console.error('Error fetching top summary:', error);
      }
    });
  }

  updatePaymentMethodsData(paymentMethods: PaymentMethodSummary[]) {
    this.pieChartData = {
      labels: paymentMethods.map(method => this.formatPaymentMethodName(method.payment_method)),
      datasets: [{
        data: paymentMethods.map(method => parseFloat(method.percentage)),
        backgroundColor: paymentMethods.map(method => this.paymentMethodColors[method.payment_method] || '#808080')
      }]
    };

    if (this.chart) {
      this.chart.chart?.update();
    }
  }
  getSalesSummary() {
    this.reportingSrvs.getSalesSummary().subscribe({
      next: (data: any) => {
        this.summaryCards = [
          { title: 'Total Sales', value: data.total_sales },
          { title: 'Total Deductions', value: parseInt(data.total_deductions) },
          { title: 'Total Profit', value: data.total_profits }
        ];
      },
      error: (error) => {
        console.error('Error fetching sales summary:', error);
      }
    });
  }
  formatPaymentMethodName(method: string): string {
    const names: { [key: string]: string } = {
      pos: 'Card (POS)',
      cash: 'Cash',
      transfer: 'Transfer',
      wallet: 'Wallet'
    };
    return names[method] || method;
  }

  updatePaymentMethodsChart() {
    // This method can stay empty now as we're handling chart updates in updatePaymentMethodsData
  }
// Date picker methods
onDateSelection(date: NgbDate) {
  if (!this.dateRange.from && !this.dateRange.to) {
    this.dateRange.from = date;
  } else if (this.dateRange.from && !this.dateRange.to && date.after(this.dateRange.from)) {
    this.dateRange.to = date;
    this.applyDateFilter();
  } else {
    this.dateRange.to = null;
    this.dateRange.from = date;
  }
}

isHovered(date: NgbDate) {
  return this.dateRange.from && !this.dateRange.to && this.hoveredDate && date.after(this.dateRange.from) && date.before(this.hoveredDate);
}

isInside(date: NgbDate) {
  return this.dateRange.from && this.dateRange.to && date.after(this.dateRange.from) && date.before(this.dateRange.to);
}

isRange(date: NgbDate) {
  return date.equals(this.dateRange.from) || (this.dateRange.to && date.equals(this.dateRange.to)) || this.isInside(date) || this.isHovered(date);
}

formatDateForApi(date: NgbDate | null): string | undefined {
  if (!date) return undefined;
  return `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
}

// Filter methods
applyDateFilter() {
  this.showDatePicker = false;
  this.getSalesHistory();
}

setDateRange(range: string) {
  const today = this.calendar.getToday();
  switch (range) {
    case 'today':
      this.dateRange = { from: today, to: today };
      break;
    case 'week':
      this.dateRange = {
        from: this.calendar.getPrev(today, 'd', 7),
        to: today
      };
      break;
    case 'month':
      this.dateRange = {
        from: this.calendar.getPrev(today, 'm', 1),
        to: today
      };
      break;
  }
  this.getSalesHistory();
}

onPaymentMethodFilter(method: string) {
  this.selectedPaymentMethod = method;
  this.getSalesHistory();
}

onWaiterFilter(waiterId: string) {
  this.selectedWaiter = waiterId;
  this.getSalesHistory();
}

getSalesHistory(page: number = 1) {
  const params: SalesHistoryParams = {
    page,
    start_date: this.formatDateForApi(this.dateRange.from),
    end_date: this.formatDateForApi(this.dateRange.to),
    payment_method: this.selectedPaymentMethod || undefined,
    waiter_id: this.selectedWaiter || undefined
  };

  this.reportingSrvs.getSalesHistory(params).subscribe({
    next: (response) => {
      this.salesHistory = response.data;
      this.filteredsalesHistory = response.data;
      this.historyTotal = response.meta.total;
      this.historyPage = response.meta.current_page;
    },
    error: (error) => {
      console.error('Error fetching sales history:', error);
    }
  });
}

refreshHistory(page: number) {
  this.getSalesHistory(page);
}

onSearch() {
  if (!this.searchTerm?.trim()) {
    this.filteredsalesHistory = [...this.salesHistory];
    return;
  }

  const searchTermLower = this.searchTerm.toLowerCase();
  this.filteredsalesHistory = this.salesHistory.filter(item =>
    item.order_uid.toLowerCase().includes(searchTermLower) ||
    (item.payment_method || []).some(method => method.toLowerCase().includes(searchTermLower)) ||
    item.status.toLowerCase().includes(searchTermLower)
  );
}

// For date display in template
formatDisplayDate(date: NgbDate | null): string {
  if (!date) return '';
  return new Date(date.year, date.month - 1, date.day).toLocaleDateString();
}

// For disabled dates (add if you want to disable certain dates)
markDisabled = (date: NgbDateStruct, current?: { year: number; month: number; }): boolean => {
  const today = this.calendar.getToday();
  return date &&
         new NgbDate(date.year, date.month, date.day)
         .after(today);
};
  updateSummaryCards() {
    this.summaryCards = [
      { title: 'Total Sales', value: this.totalSales.total_sales },
      { title: 'Total Deductions', value: this.totalSales.total_deductions },
      { title: 'Total Profit', value: this.totalSales.total_profit }
    ];
  }


  getStatusClass(status: string): string {
    const statusClasses: { [key: string]: string } = {
      completed: 'badge-success',
      accepted: 'badge-warning',
      delivered: 'badge-secondary',
      ordered: 'badge-danger',
      cancelled: 'text-bg-secondary'
    };
    return statusClasses[status.toLowerCase()] || 'text-bg-secondary';
  }

  invItem(content: any, order?: any) {
    this.selectedOrder = order;
    this.modal.open(content, { centered: true });
  }

  customerInfo(content: any, order?: any) {
    this.selectedOrder = order;
    this.modal.open(content, { centered: true });
  }

  waiterInfo(content: any, order?: any) {
    this.selectedOrder = order;
    this.modal.open(content, { centered: true });
  }

  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.filteredsalesHistory);
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Sales History': worksheet },
      SheetNames: ['Sales History']
    };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(data, `sales_history_${new Date().toISOString().split('T')[0]}.xlsx`);
  }
}
