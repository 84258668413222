<div class="mt-5">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-fill nav-underline">
    <li [ngbNavItem]="1">
      <button ngbNavLink>Waiter Sales Report</button>
      <ng-template ngbNavContent>
        <div class="row align-items-center mb-4">
          <div class="col-md-4">
            <div class="search-box">
              <input type="text" class="search-input" placeholder="Search Waiter" />
            </div>
          </div>
          <div class="col-md-8 text-end">
            <div ngbDropdown class="d-inline-block me-3">
              <button type="button" class="btn btn-secondary btn-lg" ngbDropdownToggle>
                {{ selectedDateFilter === 'custom' ? 'Custom Date' : selectedDateFilter | titlecase }}
                <i class="fa fa-solid fa-calendar ms-2"></i>
              </button>
              <div ngbDropdownMenu class="p-2">
                <button ngbDropdownItem (click)="onDateFilterChange('today')" class="p-2">Today</button>
                <button ngbDropdownItem (click)="onDateFilterChange('yesterday')" class="p-2">Yesterday</button>
                <button ngbDropdownItem (click)="onDateFilterChange('custom')" class="p-2">Custom Date</button>
              </div>
            </div>

            <!-- Custom Date Range Picker -->
            @if (showDateRangePicker) {
              <div class="card position-absolute p-3" style="z-index: 1000;">
                <div class="d-flex gap-2 align-items-center mb-2">
                  <div>
                    <label class="form-label">Start Date</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="start"
                        [(ngModel)]="dateRange.start"
                        ngbDatepicker
                        #startDate="ngbDatepicker">
                      <button class="btn btn-outline-secondary" (click)="startDate.toggle()" type="button">
                        <i class="fa fa-solid fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <label class="form-label">End Date</label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="end"
                        [(ngModel)]="dateRange.end"
                        ngbDatepicker
                        #endDate="ngbDatepicker">
                      <button class="btn btn-outline-secondary" (click)="endDate.toggle()" type="button">
                        <i class="fa fa-solid fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                  <div class="d-flex align-items-end">
                    <button
                      class="btn btn-primary"
                      (click)="onDateRangeSelected()"
                      [disabled]="!dateRange.start || !dateRange.end">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            }

            <button class="btn btn-secondary btn-lg">
              Export Report <i class="fa fa-regular fa-file-export ms-2"></i>
            </button>
          </div>
        </div>

        @if (isLoading) {
          <div class="text-center py-5">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        } @else if (error) {
          <div class="alert alert-danger">{{ error }}</div>
        } @else {
          @for (waiter of waiterSalesReport; track waiter.waiter_id) {
            <div class="card mb-4">
              <div class="card-header bg-light d-flex justify-content-between align-items-center">
                <h5 class="mb-0">{{ waiter.waiter_name }}</h5>
                <div>
                  <span class="badge bg-primary me-2">Total Items: {{ waiter.total_quantity }}</span>
                  <span class="badge bg-success">Total Amount: {{ waiter.total_amount | currency:'₦ ' }}</span>
                </div>
              </div>
              <div class="card-body">
                <table class="table align-middle">
                  <thead>
                    <tr>
                      <th class="ps-4">Item Name</th>
                      <th>Quantity Sold</th>
                      <th>Total Amount</th>
                      <th>Last Sale Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (sale of waiter.items; track sale.item_name) {
                      <tr>
                        <td class="ps-4">{{ sale.item_name }}</td>
                        <td>{{ sale.quantity_sold }}</td>
                        <td>{{ sale.total_amount | currency:'₦ ' }}</td>
                        <td>{{ sale.last_sale_time }}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
        }
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <button ngbNavLink>Inventory Summary Report</button>
      <ng-template ngbNavContent>
        <div class="row align-items-center mb-4">
          <div class="col-md-4">
            <div class="search-box">
              <input
                type="text"
                class="search-input"
                placeholder="Search Inventory"
                [(ngModel)]="inventorySearch"
                (ngModelChange)="onInventorySearch($event)" />

            </div>
          </div>
        </div>

        <!-- <div class="row mt-5">
          <div class="col-md-4 mb-4">
            <div class="card rounded-3">
              <div class="px-3 py-4">
                <p>Total No of Inventories</p>
                <h3> {{ summaryReport?.total_inventory || 0 }} </h3>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <div class="card rounded-3">
              <div class="px-3 py-4">
                <p>Total Cost Price (Value)</p>
                <h3> {{ summaryReport?.total_cost_price || 0 | currency: '₦ ' }} </h3>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <div class="card rounded-3">
              <div class="px-3 py-4">
                <p>Total Selling Price (Revenue)</p>
                <h3 class="text-success"> {{ summaryReport?.total_selling_price || 0 | currency: '₦ ' }} </h3>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <div class="card rounded-3">
              <div class="px-3 py-4">
                <p>Total Unrealized Profit</p>
                <h3 class="text-danger"> {{ summaryReport?.total_unrealized_profit || 0 | currency: '₦ ' }} </h3>
              </div>
            </div>
          </div>
        </div> -->

        <!-- New Inventory Breakdown Table -->
        <div class="table-responsive mt-3">
          <table class="table align-middle border">
            <thead>
              <tr>
                <th class="ps-4 py-3">Item</th>
                <th class="py-3">Category</th>
                <th class="py-3">Current Stock</th>
                <th class="py-3">Quantity Sold</th>
                <th class="py-3">Cost Price</th>
                <th class="py-3">Base Selling Price</th>
                <th class="py-3">Avg. Selling Price</th>
                <th class="py-3">Total Investment</th>
                <th class="py-3">Base Unrealized Profit</th>
                <th class="py-3">Avg. Unrealized Profit</th>
              </tr>
            </thead>
            <tbody>
              @if (filteredInventoryItems && filteredInventoryItems.length > 0) {
                @for (item of filteredInventoryItems; track item.id) {
                  <tr>
                    <td class="ps-4">{{ item.name }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.current_stock }}</td>
                    <td>{{ item.quantity_sold }}</td>
                    <td>₦{{ item.formatted.cost_price }}</td>
                    <td>₦{{ item.formatted.base_selling_price }}</td>
                    <td>₦{{ item.formatted.avg_price_tag_price }}</td>
                    <td>₦{{ item.formatted.total_investment }}</td>
                    <td>₦{{ item.formatted.base_unrealized_profit }}</td>
                    <td>₦{{ item.formatted.avg_price_tag_unrealized_profit }}</td>
                  </tr>
                }
              } @else {
                <tr>
                  <td colspan="10" class="text-center">No data available</td>
                </tr>
              }
            </tbody>
          </table>

          <!-- Pagination -->
          <div class="d-flex justify-content-between align-items-center mt-3">
            <div>
              <select class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()">
                <option [value]="10">10 items per page</option>
                <option [value]="25">25 items per page</option>
                <option [value]="50">50 items per page</option>
              </select>
            </div>
            <ngb-pagination
              [collectionSize]="totalItems"
              [(page)]="currentPage"
              [pageSize]="pageSize"
              (pageChange)="onPageChange($event)"
              [maxSize]="5"
              [rotate]="true"
              [boundaryLinks]="true">
            </ngb-pagination>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <button ngbNavLink>Low Stock Alert Report</button>
      <ng-template ngbNavContent>
        <div class="row align-items-center mb-4">
          <div class="col-md-4">
            <div class="search-box">
              <input
                type="text"
                class="search-input"
                placeholder="Search"
                [(ngModel)]="lowStockSearch"
                (ngModelChange)="onLowStockSearch($event)" />
            </div>
          </div>
          <div class="col-md-8">
            <div ngbDropdown class="d-inline-block ms-auto me-3" container="body">
              <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
                Filter by Date &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
                <button ngbDropdownItem class="p-2">Today</button>
                <button ngbDropdownItem class="p-2">This week</button>
                <button ngbDropdownItem class="p-2">This Month</button>
                <button ngbDropdownItem class="p-2">Custom</button>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block ms-auto btn-lg" container="body">
              <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
                Filter by Category &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
                <button ngbDropdownItem class="p-2">Today</button>
                <button ngbDropdownItem class="p-2">This week</button>
                <button ngbDropdownItem class="p-2">This Month</button>
                <button ngbDropdownItem class="p-2">Custom</button>
              </div>
            </div>
            <button class="ms-3 btn bttn-lg btn-secondary">
              Export Report &nbsp; <i class="fa fa-regular fa-file-export"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table align-middle border">
            <thead>
              <tr>
                <th class="ps-4 py-3">Item</th>
                <th class="py-3">Category</th>
                <th class="py-3">Current Stock</th>
                <th class="py-3">Quantity Sold</th>
                <th class="py-3">Reorder Level</th>
                <th class="py-3">Restock Needed</th>
                <th class="py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (item of filteredLowStockItems; track item.item) {
                <tr>
                  <td class="ps-4">{{ item.item }}</td>
                  <td>{{ item.category }}</td>
                  <td>
                    <span [class.text-danger]="item.status === 'Red'"
                          [class.text-warning]="item.status === 'Yellow'">
                      {{ item.current_stock }}
                      {{ item.status === 'Red' ? '(Red)' : '(Yellow)' }}
                    </span>
                  </td>
                  <td>{{ item.quantity_sold }}</td>
                  <td>{{ item.reorder_level }}</td>
                  <td>{{ item.restock_needed }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-outline-secondary dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                        Actions
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="javascript:void(0)">Restock</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0)">Details</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>

          <!-- Pagination -->
          <div class="d-flex justify-content-between align-items-center mt-3">
            <div>
              <select class="form-select" [(ngModel)]="lowStockPageSize" (change)="onLowStockPageSizeChange()">
                <option [value]="10">10 items per page</option>
                <option [value]="25">25 items per page</option>
                <option [value]="50">50 items per page</option>
              </select>
            </div>
            <ngb-pagination
              [collectionSize]="lowStockTotalItems"
              [(page)]="lowStockCurrentPage"
              [pageSize]="lowStockPageSize"
              (pageChange)="onLowStockPageChange($event)"
              [maxSize]="5"
              [rotate]="true"
              [boundaryLinks]="true">
            </ngb-pagination>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <button ngbNavLink>Expiration Date Report</button>
      <ng-template ngbNavContent>
        <div class="row align-items-center">
          <div class="col-md-4">
            <div class="search-box">
              <input class="search-input" type="text" placeholder="Search" />
            </div>
          </div>
          <div class="col-md-8">
            <div ngbDropdown class="d-inline-block ms-auto me-3" container="body">
              <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
                Filter by Date &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
                <button ngbDropdownItem class="p-2">Today</button>
                <button ngbDropdownItem class="p-2">This week</button>
                <button ngbDropdownItem class="p-2">This Month</button>
                <button ngbDropdownItem class="p-2">Custom</button>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block ms-auto btn-lg" container="body">
              <button type="button" class="btn btn-secondary  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
                Filter by Category &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-solid fa-bars-filter"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
                <button ngbDropdownItem class="p-2">Today</button>
                <button ngbDropdownItem class="p-2">This week</button>
                <button ngbDropdownItem class="p-2">This Month</button>
                <button ngbDropdownItem class="p-2">Custom</button>
              </div>
            </div>
            <button class="ms-3 btn bttn-lg btn-secondary">
              Export Report &nbsp; <i class="fa fa-regular fa-file-export"></i>
            </button>
          </div>
        </div>

        <div class="table-responsiv mt-5">
          <table class="table align-middle border">
            <thead>
              <tr>
                <!-- <th></th> -->
                <th class="ps-5 py-4">Item Name</th>
                <th class="py-4">Expiration Date</th>
                <th class="py-4">Current Quantity</th>
                <th class="py-4">Days Past Expiration</th>
              </tr>
            </thead>
            <tbody>
              @for (item of expirationReport; track $index) {
              <tr>
                <td class="ps-5"> {{ item.inventory_name }} </td>
                <td> {{ item?.expiration_date | date : 'DD - MMM - YYYY' }} </td>
                <td> {{ item?.current_quantity }} </td>
                <td> {{ item.days_past_expiration }} </td>
              </tr>
              }
              @empty {}

            </tbody>
          </table>
        </div>

      </ng-template>
    </li>

  </ul>
</div>

<div [ngbNavOutlet]="nav" class="mt-5"></div>
