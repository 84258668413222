import { ErrorInterceptor } from './core/error-interceptor';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from './app.routes';
import { serverErrorInterceptor } from './core/interceptor';
import { httpInterceptorProviders } from './core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [

    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes), provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: serverErrorInterceptor, multi: true }, provideAnimationsAsync(),
    // httpInterceptorProviders,
    // provideHttpClient(withInterceptors([serverErrorInterceptor]),)
    // provideHttpClient(withInterceptors([ErrorInterceptor]))
  ]

};
