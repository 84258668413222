<div class="container">
  <div class="content mt-5 ">
    <div class="icon">H</div>
    <div class="text">Hospitality Software</div>
  </div>
  <main>


    <div class="">


      <div class="formborder">
        <div class="border rounded rounded-2 p-5">
          <a [routerLink]="['/login']">
            <i class="fa fa-regular fa-arrow-left fa-xl"></i>
          </a>
          <div class="text-center my-5">
            <h5>Reset Password</h5>
            <p>A code was sent to your email address</p>
          </div>
          <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">

            <div class="form-group">
              <label for="email" class="form-label">Email Address</label>
              <input type="text" id="code" name="code" formControlName="code" class="form-control">
              @if(message){
              <span class="error">{{message}}</span>
              }
            </div>




            <div class="text-center mt-4 mb-5">
              <button class="btn btn-primary btn-block" type="submit" [disabled]="resetForm.invalid">Submit</button>
            </div>
          </form>

        </div>
      </div>
    </div>

  </main>
</div>
