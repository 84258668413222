import { Component } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-return-requests',
  standalone: true,
  imports: [],
  templateUrl: './return-requests.component.html',
  styleUrl: './return-requests.component.scss'
})
export class ReturnRequestsComponent {

  acceptReturn() {
    Swal.fire({
      title: 'Success',
      text: 'Return request for order ID 0123485762 is successful. The inventory has ben updated and waiter has been notified',
      icon: 'success',
      confirmButtonText: 'Done'
    })
  }

  rejectReturn() {
    Swal.fire({
      title: 'Request Rejected',
      text: 'Return request for order ID 0123485762 is rejected. The inventory has ben updated and waiter has been notified',
      icon: 'error',
      confirmButtonText: 'Done'
    })
  }
}
